<template>
    <div class="container-fluid">
        <div class="float-right">
            <b-button-close @click="$router.replace('/pools')">
                <h2 aria-hidden="true">&times;</h2>
            </b-button-close>
        </div>
        <div class="clearfix"></div>
        <div class="row justify-content-end mb-2">
            <div class="col-8">
                <b-button @click="fetchData">Aktualisieren</b-button>
                <b-dropdown id="dropdown-1" text="Aktion" variant="success">
                    <b-dropdown-item @click="deleteTestData()">Testdatensätze löschen</b-dropdown-item>
                    <b-dropdown-item @click="showPreviewExportModal()">Vorschau-Export</b-dropdown-item>
                    <b-dropdown-item
                        @click="$router.push({ name: 'exported_files', params: { poolKey: poolKey }})"
                        v-if="exportDefinition && 'Brandsuite\\Addressbox\\Entity\\ExportRule\\ExportDefinition\\TwigFileExportType' in exportDefinition.export_type"
                    >Exportdateien anzeigen</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item variant="danger" @click="triggerExport()">Export anstoßen</b-dropdown-item>
                </b-dropdown>
                <b-button
                    @click="deleteSelectedData()"
                    variant="danger"
                    v-show="selectedData.length > 0"
                >Ausgewählte Datensätze löschen</b-button>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <b-form-checkbox
                        name="check-completed"
                        v-model="showOnlyCompleted"
                    >Nur "Completed" anzeigen</b-form-checkbox>
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    <b-form-select v-model="testDataFilter">
                        <option value="">Testdatensätze anzeigen</option>
                        <option value="exclude">Testdatensätze ausblenden</option>
                        <option value="only">nur Testdatensätze anzeigen</option>
                    </b-form-select>
                </div>
            </div>
        </div>
        <table class="table table-striped">
            <thead>
            <th style="width:5%">
                <b-form-checkbox
                    name="check-button"
                    button
                    button-variant="link"
                    @change="toggleAllSelectedData"
                    style="margin: -.45rem -.75rem;"
                >ID</b-form-checkbox>
            </th>
            <th style="width:50%">Data</th>
            <th style="width:2.5%">Completed</th>
            <th style="width:2.5%">DO-Verified</th>
            <th style="width:2.5%">Test</th>
            <th style="width:2.5%">User</th>
            <th style="width:10%">Time Created</th>
            <th style="width:10%">Time Completed</th>
            <th style="width:10%">Time DO-Verified</th>
            </thead>
            <tbody>
            <tr class="data" v-for="data in dataSets" :class="{ 'table-danger': data['_rowVariant'] === 'danger' }">
                <td i="id">
                    <b-form-checkbox
                        v-model="selectedData"
                        :key="data.id"
                        :value="data.id"
                        name="dataSets"
                    >{{ data.id }}</b-form-checkbox>
                </td>
                <td i="data" title="Details anzeigen" @click="showModal(data.data)">{{ data.data }}</td>
                <td i="completed">{{ data.completed }}</td>
                <td i="double_optin_verified">{{ data.doubleOptinVerified }}</td>
                <td i="test">{{ data.test }}</td>
                <td i="data.user">{{ data.user }}</td>
                <td i="time_created">{{ data.createdAt }}</td>
                <td i="time_completed">{{ data.completedAt }}</td>
                <td i="time_double_optin_verified">{{ data.doubleOptinVerifiedAt }}</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="9">
                    <strong>Anzahl: {{ totalNumberOfDataSets }}</strong>
                </td>
            </tr>
            </tfoot>
        </table>

        <data-paginator
            @currentPageWasChanged="fetchData($event)"
            :items="dataSets"
            :limitPerPage="limitPerPage"
            :totalNumberOfItems="totalNumberOfDataSets"
            :reset="resetPaginator"
        ></data-paginator>

        <user-data-modal v-show="isModalVisible" @close="closeModal" :items="currentDataSet" />
        <preview-export-modal
            :showMe="isPreviewModalVisible"
            @close="isPreviewModalVisible=false"
            :poolKey="poolKey"
        />
    </div>
</template>

<script>
import Paginator from "./Paginator.vue";
import UserDataModal from "../modals/UserDataModal.vue";
import PreviewExportModal from "../modals/PreviewExportModal.vue";

export default {
    components: {
        dataPaginator: Paginator,
        userDataModal: UserDataModal,
        previewExportModal: PreviewExportModal
    },
    data() {
        return {
            limitPerPage: 15,
            showOnlyCompleted: false,
            testDataFilter: '',
            totalNumberOfDataSets: 0,
            dataSets: [],
            exportDefinition: null,
            resetPaginator: false,
            poolKey: null,
            isModalVisible: false,
            isPreviewModalVisible: false,
            currentDataSet: null,
            checkboxes: [],
            selectedData: []
        };
    },
    created() {
        this.poolKey = this.$route.params.poolKey;
        this.fetchData(1);
    },
    watch: {
        showOnlyCompleted() {
            this.fetchData(1);
        },
        testDataFilter(val) {
            if (val === "only") {
                this.showOnlyCompleted = false;
            }
            this.fetchData(1);
        }
    },
    methods: {
        updateTable(data) {
            const dataSetsArray = [];
            const checkboxesArray = [];
            for (let key in data.dataSets) {
                let dataSet = data.dataSets[key];
                if (dataSet.process === 3) {
                    dataSet["_rowVariant"] = 'danger';
                }

                dataSetsArray.push(dataSet);
                checkboxesArray.push(data.dataSets[key].id);
            }
            this.dataSets = dataSetsArray;
            this.checkboxes = checkboxesArray;
            this.totalNumberOfDataSets = data.total;
        },
        fetchData(currentPage, resetPaginator) {
            if (resetPaginator) {
                this.resetPaginator = resetPaginator;
            }

            let params = {
                limit: this.limitPerPage,
                offset: (currentPage - 1) * this.limitPerPage,
                poolKey: this.poolKey,
                onlyCompleted: Number(this.showOnlyCompleted),
                testDataFilter: this.testDataFilter
            };

            this.$http
                .get("interface/data", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/data"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.exportDefinition = data.exportDefinition;
                    this.updateTable(data);
                });
        },
        showModal(data) {
            this.currentDataSet = data;
            this.isModalVisible = true;
        },
        closeModal() {
            this.currentDataSet = {};
            this.isModalVisible = false;
        },
        toggleAllSelectedData(checked) {
            this.selectedData = checked ? this.checkboxes.slice() : [];
        },
        deleteTestData() {
            this.$bvModal
                .msgBoxConfirm(
                    "Bist du sicher, dass alle Testdatensätze dieser Kampagne gelöscht werden sollen?",
                    {
                        cancelTitle: "Abbrechen"
                    }
                )
                .then(value => {
                    if (value) {
                        this.$http
                            .delete("interface/data/" + this.poolKey + "/test", {
                                headers: {
                                    Authorization: this.createHmacHash(
                                        this.$store.state.user.adfAdminUser,
                                        "-DELETE-/addressflow/v1/interface/data/" + this.poolKey + "/test"
                                    )
                                }
                            })
                            .then(response => {
                                return response.json();
                            })
                            .then(data => {
                                if (data.success) {
                                    this.$bvModal
                                        .msgBoxOk(
                                            data.deletedDataSets + " Testdatensätze wurden gelöscht"
                                        )
                                        .then(value => {
                                            this.fetchData();
                                        });
                                }
                            });
                    }
                });
        },
        deleteSelectedData() {
            if (!this.selectedData.length) {
                return;
            }

            this.$bvModal
                .msgBoxConfirm(
                    "Bist du sicher, dass alle ausgewählten Datensätze gelöscht werden sollen?",
                    {
                        cancelTitle: "Abbrechen"
                    }
                )
                .then(value => {
                    if (value) {
                        this.$http
                            .patch(
                                "interface/data/selected",
                                { items: this.selectedData, poolKey: this.poolKey },
                                {
                                    headers: {
                                        Authorization: this.createHmacHash(
                                            this.$store.state.user.adfAdminUser,
                                            "-PATCH-/addressflow/v1/interface/data/selected"
                                        )
                                    }
                                }
                            )
                            .then(response => {
                                return response.json();
                            })
                            .then(data => {
                                if (data.success) {
                                    this.$bvModal
                                        .msgBoxOk(
                                            data.deletedDataSets + " Datensätze wurden gelöscht"
                                        )
                                        .then(value => {
                                            this.selectedData = [];
                                            this.fetchData();
                                        });
                                }
                            });
                    }
                });
        },
        showPreviewExportModal() {
            this.isPreviewModalVisible = true;
        },
        triggerExport() {
            this.$bvModal
                .msgBoxConfirm(
                    "Manueller Export für diese Kampagne wird angestoßen und anschließend die Daten aus der Datenbank gelöscht.",
                    {
                        cancelTitle: "Abbrechen"
                    }
                )
                .then(value => {
                    if (value) {
                        this.$http
                            .get("export/" + this.poolKey, {
                                headers: {
                                    Authorization: this.createHmacHash(
                                        this.$store.state.user.adfAdminUser,
                                        "-GET-/addressflow/v1/export/" + this.poolKey
                                    )
                                }
                            })
                            .then(response => {
                                return response.json();
                            })
                            .then(data => {
                                if (data.success) {
                                    this.$bvModal
                                        .msgBoxOk(
                                            "Der Export wurde angestoßen und wird in Kürze ausgeführt."
                                        )
                                        .then(value => {
                                            this.fetchData();
                                        });
                                }
                            });
                    }
                });
        }
    }
};
</script>

<style>
td[i="data"] {
    cursor: pointer;
}
</style>


