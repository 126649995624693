<template>
  <div class="container-fluid">
    <div class="my-3">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Erstellen</b-button>
    </div>

    <b-table hover :items="parties" :fields="tableFields">
      <template v-slot:cell(edit)="data">
        <router-link
          :to="{ name: 'edit_party', params: { partyTitle: data.value }}"
        >
          <a>Bearbeiten</a>
        </router-link>
      </template>
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="Mandant erstellen"
      cancel-title="Abbrechen"
      ok-title="Neuen Mandanten anlegen"
      @ok="createNewParty"
    >
      <form>
        <div class="form-group">
          <label for="client_name">Name:</label>
          <b-form-input id="client_name" v-model="newName" autofocus></b-form-input>
          <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger"><pre>{{ createErrorMsg }}</pre></b-alert>
        </div>
        <div class="form-group">
          <label for="jobflow_url">JobFlow-Url:</label>
          <b-form-input id="jobflow_url" v-model="jobflowUrl"></b-form-input>
        </div>
        <div class="form-group">
          <label for="jobflow_token">JobFlow-Api-Key:</label>
          <b-form-input id="jobflow_token" type="password" v-model="jobflowToken"></b-form-input>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableFields: [
        {
          "key": "name",
          "label": "Name"
        },
        {
          "key": "edit",
          "label": ""
        }
      ],
      parties: [],
      newName: "",
      jobflowUrl: "",
      jobflowToken: "",
      showCreateErrorMsg: false,
      createErrorMsg: ""
    };
  },
  created() {
    this.fetchData(1);
  },
  methods: {
    updateTable(data) {
      const partiesArray = [];
      if (data !== null) {
          for (let i in data.parties) {
              let party = data.parties[i];
              party['edit'] = data.parties[i].name;
              partiesArray.push(party);
          }
      }

      this.parties = partiesArray;
    },
    fetchData(currentPage, resetPaginator) {
      if (resetPaginator) {
        this.resetPaginator = resetPaginator;
      }

      this.$http
        .get("admin/party", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/admin/party"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updateTable(data);
        });
    },
    createNewParty(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.showCreateErrorMsg = false;

      if (this.newName.length < 3) {
        this.showCreateErrorMsg = true;
        this.createErrorMsg = "Name: Mindestens 3 Zeichen eingeben.";
        return false;
      }

      let createPartyParams = {
        name: this.newName
      };

      if (this.jobflowUrl && this.jobflowToken) {
        createPartyParams.job_flow_url = this.jobflowUrl;
        createPartyParams.job_flow_api_key = this.jobflowToken;
      }

      this.$http
        .post(
          "admin/party",
          createPartyParams,
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/admin/party"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.$refs["create-new-modal"].hide();
            this.fetchData(1);
          } else {
            this.showCreateErrorMsg = true;
            if (data.errors && data.errors.name) {
              this.createErrorMsg = JSON.stringify(data.errors, undefined, 2);
            }
          }
        });
    }
  }
};
</script>

