<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/uiparties')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <br /><br />
    <h1>Mandant bearbeiten</h1>
    <br />

    <validation-observer ref="form" v-slot="{ handleSubmit }">
      <b-form
        @submit.stop.prevent="handleSubmit(onSubmit)"
        class="justify-content-center"
      >
        <validation-provider
          name="Name"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group
            id="companyName-group-1"
            label="Name"
            label-for="companyName"
          >
            <b-form-input
              id="companyName"
              name="companyName"
              placeholder="Name"
              v-model="form.companyName"
              :state="getValidationState(validationContext)"
              aria-describedby="company-name-live-feedback"
              type="text"
              autofocus
            ></b-form-input>

            <b-form-invalid-feedback id="company-name-live-feedback">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Account-Name"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group
            id="accountName-group-1"
            label="Account-Name"
            label-for="accountName"
          >
            <b-form-input
              id="accountName"
              name="accountName"
              placeholder="Account-Name"
              v-model="form.accountName"
              :state="getValidationState(validationContext)"
              aria-describedby="account-name-live-feedback"
              type="text"
            ></b-form-input>

            <b-form-invalid-feedback id="account-name-live-feedback">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Owner Name"
          :rules="{ required: true, email: true }"
          v-slot="validationContext"
        >
          <b-form-group
            id="ownerName-group-1"
            label="Owner Name"
            label-for="ownerName"
          >
            <b-form-input
              id="ownerName"
              name="ownerName"
              placeholder="Owner Name"
              v-model="form.ownerName"
              :state="getValidationState(validationContext)"
              aria-describedby="owner-name-live-feedback"
              type="email"
            ></b-form-input>

            <b-form-invalid-feedback id="owner-name-live-feedback">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Status"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="status-group-1" label="Status" label-for="status">
            <b-form-select
              id="status"
              v-model="form.status"
              :options="[
                { text: 'Aktiv', value: 'Active' },
                { text: 'Inaktiv', value: 'Inactive' },
              ]"
              :state="getValidationState(validationContext)"
              aria-describedby="status-live-feedback"
            ></b-form-select>

            <b-form-invalid-feedback id="status-live-feedback">{{
              validationContext.errors[0]
            }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <div class="my-3">
          <b-button
            variant="primary"
            :disabled="saveButtonDisabled"
            type="submit"
          >
            <b-spinner small v-if="saveButtonDisabled"></b-spinner>
            Speichern
          </b-button>
          <b-button variant="light" @click="$router.replace('/uiparties')">Abbrechen</b-button>
        </div>

        <div class="my-3">
          <div class="col">
            <b-alert
              variant="success"
              :show="alertDismissCountDown"
              fade
              dismissible
              @dismissed="
                alertDismissCountDown = 0;
                saveButtonDisabled = false;
              "
              @dismiss-count-down="countDownChanged"
              >Der Mandant wurde erfolgreich gespeichert.</b-alert
            >
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tenantId: "",
      form: {
        companyName: "",
        accountName: "",
        ownerName: "",
        status: "",
      },
      saveButtonDisabled: false,
      alertDismissCountDown: 0,
    };
  },
  created() {
    this.tenantId = this.$route.params.tenantId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get(this.$authUrl + "tenant/" + this.tenantId, {
          headers: {
            Authorization: "Bearer " + this.$store.state.bearerToken.value,
          },
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.form = {
            companyName: data.companyName,
            accountName: data.accountName,
            ownerName: data.ownerName,
            status: data.status,
          };
        });
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.saveButtonDisabled = true;
        var tenant = {
          id: this.tenantId,
          companyName: this.form.companyName,
          accountName: this.form.accountName,
          ownerName: this.form.ownerName,
          tier: '',
          status: this.form.status,
        };

        this.$http
          .put(this.$authUrl + "tenant", tenant, {
            headers: {
              Authorization: "Bearer " + this.$store.state.bearerToken.value,
            },
          })
          .then((resp) => {
            this.saveButtonDisabled = false;
            this.$router.push("/uiparties");
          })
          .catch((err) => {
            this.saveButtonDisabled = false;
            console.log("Error updating party: " + err.message);
          });
      });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    },
  },
};
</script>
