<template>
  <div>
    <b-modal
      id="test_export_modal"
      :title="modalTitle"
      hide-footer
      @show="getListOfPools"
      @hidden="closeMe()"
    >
      <div v-if="step === 'select_pool'">
        <div v-for="pool in pools">
          <label>
            <input type="radio" :value="pool.key" name="pool" v-model="poolKey" />
            {{ pool.name }} ({{ pool.number_of_data_now }})
          </label>
          <br>
        </div>
        <div class="form-group">
          <b-form-checkbox v-model="completeExport" name="completeExport" value="1">Ausführlicher Export (inkl. Verschlüsselung und Übertragung)</b-form-checkbox>
        </div>
        <b-button variant="primary" @click.prevent="step='trigger_export'" v-show="poolKey">Weiter</b-button>
      </div>

      <div v-if="step==='trigger_export'">
        <p class="text-center" v-show="showSpinner">
          <b-spinner />
        </p>
      </div>

      <p v-if="fileContent && !completeExport">
        <b-form-group label="Download Optionen">
          <b-form-radio v-model="fileType" name="file-type" :value="defaultFileType">Standard</b-form-radio>
          <b-form-radio
            v-model="fileType"
            name="file-type"
            value="text/csv"
          >Als CSV herunterladen</b-form-radio>
          <b-form-radio
            v-model="fileType"
            name="file-type"
            value="text/xml"
          >Als XML herunterladen</b-form-radio>
        </b-form-group>

        <b-button variant="primary" @click.prevent="download">Datei herunterladen</b-button>
      </p>

      <div v-if="step === 'export_executed'">
        <p>Bitte prüfe den Export und klicke erst danach auf den Button "Getestet".</p>
        <b-button variant="primary" @click="$bvModal.hide('test_export_modal')">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FileSaver from "file-saver";

window.TextEncoder = window.TextDecoder = null;

export default {
  components: {
    FileSaver
  },
  props: {
    showMe: false,
    context: null,
    objectId: null,
    exportRuleId: null
  },
  data() {
    return {
      step: 'select_pool',
      poolKey: null,
      modalTitle: "Pool auswählen...",
      showSpinner: false,
      maxTestExportRetries: 50,
      testExportRetries: 0,
      completeExport: false,
      defaultFileType: "",
      fileType: "",
      fileContent: "",
      charSet: "utf-8",
      pools: []
    };
  },
  watch: {
    showMe(val) {
      if (val) {
        this.step = 'select_pool';
        this.$bvModal.show("test_export_modal");
      }
    },
    step(val) {
      switch (val) {
        case 'select_pool':
          this.poolKey = null;
          this.fileContent = "";
          this.modalTitle = "Pool auswählen...";
          this.showSpinner = false;
          break;
        case 'trigger_export':
          this.showSpinner = true;
          this.modalTitle = 'Test-Export wird ausgeführt...';
          this.triggerTestExport();
          break;
        case 'export_executed':
          this.showSpinner = false;
          this.modalTitle = 'Test-Export wurde ausgeführt.';
      }
    }
  },
  methods: {
    closeMe() {
      this.$emit("close");
    },
    getListOfPools() {
      let params = {
        limit: 10,
        showDetailedInfo: false,
        exportRule: this.exportRuleId
      };

      this.$http
        .get("interface/pool", {
          params: params,
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/interface/pool"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.pools.length) {
            this.pools = data.pools;
          } else {
            alert(
              "Keine Pools gefunden. Bitte Test-Pool anlegen und mit Daten befüllen."
            );
          }
        });
    },
    triggerTestExport() {
      let params = {};
      if (false === this.completeExport) {
        params.previewMode = 1;
      }

      this.$http
        .get(
          "workflow/" +
            this.context +
            "/" +
            this.objectId +
            "/test/" +
            this.poolKey,
          {
            params: params,
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/workflow/" +
                  this.context +
                  "/" +
                  this.objectId +
                  "/test/" +
                  this.poolKey
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success) {
            if (!this.completeExport) {
              this.recGetTestExport();
            } else {
              this.step = "export_executed";
            }
          } else {
            this.modalTitle = "Keine Daten zum Exportieren vorhanden.";
            this.showSpinner = false;
            this.fileContent = "";
          }
        });
    },
    recGetTestExport() {
      this.$http
        .get(
          "workflow/" +
            this.context +
            "/" +
            this.objectId +
            "/test/" +
            this.poolKey +
            "/file",
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/workflow/" +
                  this.context +
                  "/" +
                  this.objectId +
                  "/test/" +
                  this.poolKey +
                  "/file"
              )
            }
          }
        )
        .then(response => {
          this.testExportRetries++;
          if (
            response.status === 204 &&
            this.testExportRetries < this.maxTestExportRetries
          ) {
            this.recGetTestExport();
          } else {
            this.modalTitle = "Datei herunterladen";
            this.showSpinner = false;
            this.testExportRetries = 0;
            this.fileContent = response.body;
            this.defaultFileType = response.headers.get("content-type");
            this.charSet = this.defaultFileType.split(';charset=')[1];
            this.fileType = this.defaultFileType;
          }
        });
    },
    download() {
      let string = this.fileContent.replace(/(\\r)?\\n/g, "\r\n");
      string = string.replace(/\\t/g, "\t");
      if (this.charSet !== 'utf-8') {
        string = new TextEncoder(this.charSet, { NONSTANDARD_allowLegacyEncoding: true }).encode(string);
      }

      let blob = new Blob([string], { type: this.fileType });
      let date = new Date();
      let dateString =
        date.getFullYear().toString() +
        date.getMonth().toString() +
        date.getDate().toString() +
        date.getHours().toString() +
        date.getMinutes().toString() +
        date.getSeconds().toString();

      let fileExtension = "";
      switch (this.fileType) {
        case "text/csv":
          fileExtension = ".csv";
          break;
        case "text/xml":
          fileExtension = ".xml";
          break;
      }
      FileSaver.saveAs(
        blob,
        "TEST_" + this.poolKey + "_" + dateString + fileExtension
      );
    }
  }
};
</script>
