<template>
    <nav :class="getNavigationBarClasses">
    <router-link class="navbar-brand" to="#" >Admin Interface</router-link>
        <div v-if="isNotProductionSystem" id="environmentBanner" :class="getEnvironmentBannerColor">{{getEnvironment}}</div>
        <div v-if="isLoggedIn" class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav mr-auto">
                <router-link to="/pools" tag="li" active-class="active"><a class="nav-link">Daten-Pools</a></router-link>
                <router-link to="/poolproxys" tag="li" active-class="active"><a class="nav-link">Daten-Pool-Proxys</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/exportrules" tag="li" active-class="active"><a class="nav-link">Export-Regeln</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/finisher" tag="li" active-class="active"><a class="nav-link">Finisher</a></router-link>
                <router-link to="/emailtemplates" tag="li" active-class="active"><a class="nav-link">E-Mail Templates</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin' || $store.getters.userRole === 'AdfSystemUserImport'" to="/importer" tag="li" active-class="active"><a class="nav-link">CSV Importer</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/parties" tag="li" active-class="active"><a class="nav-link">Mandanten</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/users" tag="li" active-class="active"><a class="nav-link">Benutzer</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/uiusers" tag="li" active-class="active"><a class="nav-link">UI-Benutzer</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin'" to="/uiparties" tag="li" active-class="active"><a class="nav-link">UI-Mandanten</a></router-link>
                <router-link v-if="$store.getters.userRole === 'AdfSystemAdmin' || $store.getters.userRole === 'AdfTenantAdmin'" to="/statistics" tag="li" active-class="active"><a class="nav-link">Statistik</a></router-link>
            </ul>
            <ul class="navbar-nav">
                <li><a @click="logout()" class="nav-link" style="cursor: pointer">Ausloggen</a></li>
            </ul>
        </div>
    </nav>
</template>

<script>
    export default {
      computed: {
        isLoggedIn: function() {
          return this.$store.getters.isLoggedIn();
        },
        isNotProductionSystem: function () {
            return (process.env.NODE_ENV !== 'production');
        },
        getEnvironment: function () {
            return process.env.NODE_ENV;
        },
        getEnvironmentBannerColor: function () {
            if (this.getEnvironment === 'development') {
                return 'environmentBannerDev';
            } else {
                return 'environmentBannerStaging';
            }
        },
        getNavigationBarClasses: function () {
            //TODO as long as there is only one CognitoUserPool for RuV and Dev there are only AdfSystemRoles for the users selected, therefore the background image will just be shown for SystemAdmins
            // if (this.$store.getters.isLoggedIn() && (this.$store.getters.userRole === 'AdfSystemAdmin' || this.$store.getters.userRole === 'AdfSystemUser')) {
            if (this.$store.getters.isLoggedIn() && (this.$store.getters.userRole === 'AdfSystemAdmin')) {
                return 'navbar navbar-expand-lg navbar-dark bg-dark navBorderSystemClient';
            } else {
                return 'navbar navbar-expand-lg navbar-dark bg-dark';
            }
        }
      },
      data() {
        return {
          user: undefined,
          authState: undefined,
          unsubscribeAuth: undefined,
        };
      },
      methods: {
        logout () {
          this.$store.dispatch("logout").then(() => {
            this.$router.push("/loggedout");
          });
        }
      },
      beforeDestroy() {

      }
    };
</script>
