<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/exportrules')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>Export-Template für {{ exportRuleTitle }}</h1>
    </div>
    <workflow
      :options="workflowOptions"
      :objectState.sync="exportStructureState"
      :objectContent.sync="exportStructure"
      :objectId.sync="exportStructureId"
      :initialFetchData="fetchData"
      :exportRuleId="exportRuleId"
      :allowEdit="allowEdit"
    ></workflow>
    <div class="row">
      <pre v-show="exportStructureState !== 'edited'">{{ exportStructureTemplate }}</pre>
      <MonacoEditor
        :class="{ invisible: exportStructureState !== 'edited' }"
        height="500"
        theme="vs-dark"
        language="twig"
        :options="editorOptions"
        :value="exportStructureTemplate"
        :editorMounted="onEditorMounted"
        @change="onChange"
      ></MonacoEditor>
    </div>
  </div>
</template>

<script>
import MonacoEditor from "monaco-editor-vue";
import "monaco-editor/esm/vs/basic-languages/twig/twig.contribution";
import Workflow from "../Workflow.vue";

export default {
  data() {
    return {
      editorOptions: {},
      exportStructureState: "start",
      exportRuleId: null,
      exportRuleTitle: "",
      exportStructureId: null,
      exportStructure: null,
      exportStructureTemplate: "",
      allowEdit: false,
      workflowOptions: {
        context: "export_structure",
        alertMessage: "Die Export-Template wurde erfolgreich gespeichert.",
        hasTestExport: true
      }
    };
  },
  components: {
    MonacoEditor,
    workflow: Workflow
  },
  created() {
    this.exportRuleId = Number(this.$route.params.exportRuleId);
    this.fetchData();
  },
  watch: {
    exportStructure(val) {
      if (undefined !== val.content.template)
      this.onChange(val.content.template);
    }
  },
  methods: {
    onChange(value) {
      this.exportStructureTemplate = value;
      this.exportStructure.content.template = value;
    },
    onEditorMounted(editor, monaco) {
      let val = this.exportStructureTemplate.replace(/\\r\\n/g, "\r\n");
      val = val.replace(/\\t/g, "\t");
      editor.setValue(val);
    },
    fetchData() {
      this.$http
        .get(
          "form/export_rule/update/" + this.exportRuleId + "/export_structure",
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/form/export_rule/update/" +
                  this.exportRuleId +
                  "/export_structure"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.exportStructureId = data.exportStructureId;
          this.exportStructure = data.initialValues;
          this.exportStructureTemplate = this.exportStructure.content.template;
          this.exportRuleTitle = data.exportRuleTitle;
          this.allowEdit = data.allowEdit;
        });
    },
    onError() {
      console.log("error");
    }
  }
};
</script>

