<template>
    <div class="container-fluid">
        <div class="row my-3">
            <div class="col">
                <b-alert
                    :variant="uploadMessageType"
                    :show="showMessageUpload && alertDismissCountDownUpload"
                    fade
                    dismissible
                    @dismissed="alertDismissCountDownUpload=0;"
                    @dismiss-count-down="countDownChanged"
                >{{ messageUpload }}
                </b-alert>
            </div>
        </div>

        <div class="row my-3">
            <div class="col">
                <vue-dropzone
                    ref="dropzoneImportUpload"
                    id="dropzone"
                    :options="dropzoneOptions"
                    v-on:vdropzone-file-added="uploadFileAdded"
                ></vue-dropzone>
            </div>
            <div class="col">
                <validation-observer ref="form" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(submitForm)">
                        <validation-provider
                            name="Pool"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group id="adf-upload-group-1"
                                          label="Pool, in den die Leads importiert werden sollen" label-for="adf_pool">
                                <b-form-select
                                    id="adf_pool"
                                    v-model="form.adfPool"
                                    :options="[
                    { text: 'Bitte wählen...', value: '' }, ...adfPools,]"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="pool-live-feedback"
                                ></b-form-select>

                                <b-form-invalid-feedback id="pool-live-feedback">{{
                                        validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                        <b-button type="submit" variant="primary">
                            Dateien hochladen
                        </b-button>

                    </b-form>
                </validation-observer>
                <br/>
            </div>
        </div>
        <div class="row my-3">
            <div class="col">
                <b-alert
                    :variant="messageType"
                    :show="showMessage && alertDismissCountDown"
                    fade
                    dismissible
                    @dismissed="alertDismissCountDown=0; deleteButtonDisabled=false"
                    @dismiss-count-down="countDownChanged"
                >{{ message }}
                </b-alert>
            </div>
        </div>
        <b-table hover :items="importFiles" :fields="tableFields" custom-foot>
            <template v-slot:cell(pool_key)="data">
                {{ data.value }}
            </template>
            <template v-if="$store.getters.userRole === 'AdfSystemUser'" v-slot:cell(unique_id)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(status)="data">
                <b-button variant="default" :title="getTitleByStatus(data.item.status_message)" size="sm">
                    <b-icon-circle-fill :variant="getVariantByStatus(data.value)"></b-icon-circle-fill>
                </b-button>
            </template>
            <template v-slot:cell(import_file_name)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(s3_import_file_last_modified)="data">
                {{ data.value | formatDate }}
            </template>
            <template v-slot:cell(import_started_at)="data">
                {{ data.value | formatDate }}
            </template>
            <template v-slot:cell(import_finished_at)="data">
                {{ data.value | formatDate }}
            </template>
            <template v-slot:cell(number_of_datasets)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(number_of_imported_datasets)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(number_of_not_imported_datasets)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(unique_id_start_import)="data">
                <b-button variant="success"
                          @click="triggerImport(data.item.pool_key, data.item.unique_id)"
                          :disabled="importCantBeStarted(data.item.status) || (importStarted === data.item.unique_id)"
                >Start
                </b-button>
            </template>
            <template v-slot:cell(unique_id_get_result)="data">
                <b-button variant="secondary"
                          @click="downloadFile(data.item.pool_key, data.value, data.item.import_file_name)"
                          :disabled="importIsRunning(data.item.status)"
                >Herunterladen
                </b-button>
            </template>
            <template v-slot:cell(unique_id_delete)="data">
                <b-button variant="danger"
                          @click="deleteImportFile(data.value)"
                          :disabled="importIsRunning(data.item.status)"
                >Löschen
                </b-button>
            </template>
            <template v-slot:custom-foot>
                <tr>
                    <td colspan="12">
                        <strong>Anzahl: {{ totalNumberOfImportFiles }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>
    </div>
</template>

<script>
import {BIcon, BIconCircleFill} from "bootstrap-vue";
import FileSaver from "file-saver";
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';

export default {
    components: {
        BIcon,
        BIconCircleFill,
        vueDropzone: vue2Dropzone
    },
    data() {
        return {
            timer: '',
            importStarted: 0,
            uploadMessageType: "success",
            messageType: "success",
            uploadForm: null,
            tableFields: [
                {
                    key: "pool_key",
                    label: "Pool Key"
                },
                {
                    key: "import_file_name",
                    label: "Importdatei"
                },
                {
                    key: "s3_import_file_last_modified",
                    label: "hinzugefügt am"
                },
                {
                    key: "unique_id",
                    label: "Unique ID"
                },                {
                    key: "status",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "import_started_at",
                    label: "Start"
                },
                {
                    key: "import_finished_at",
                    label: "Ende"
                },
                {
                    key: "number_of_datasets",
                    label: "Datensätze gesamt"
                },
                {
                    key: "number_of_imported_datasets",
                    label: "Datensätze importiert"
                },
                {
                    key: "number_of_not_imported_datasets",
                    label: "Datensätze NICHT importiert"
                },
                {
                    key: "unique_id_start_import",
                    label: "Import starten",
                    class: "text-center"
                },
                {
                    key: "unique_id_get_result",
                    label: "Fehlerprotokoll",
                    class: "text-center"
                },
                {
                    key: "unique_id_delete",
                    label: "Datei löschen",
                    class: "text-center"
                }
            ],
            adfPools: [],
            form: {
                adfPool: ""
            },
            importFiles: [],
            uploadFiles: [],
            totalNumberOfImportFiles: 0,
            showMessage: false,
            message: "",
            alertDismissCountDown: 0,
            deleteButtonDisabled: false,
            alertDismissCountDownUpload: 0,
            messageUpload: "",
            showMessageUpload: false,
            dropzoneOptions: {
                url: process.env.ROOT_API + "interface/importer/upload", // File upload endpoint, not signed url endpoint
                maxFilesize: 10,
                headers: {
                    'Authorization': this.createHmacHash(
                        this.$store.state.user.adfAdminUser,
                        "-POST-/addressflow/v1/interface/upload"
                    ),
                    'Cache-Control': null,
                    'X-Requested-With': null
                },
                paramName: function (n) {
                    return "file[]";
                },
                dictDefaultMessage: "Ziehen Sie die hochzuladenden CSV-Dateien hier hinein (max. 10 MB).",
                includeStyling: false,
                // previewsContainer: false,
                thumbnailWidth: 150,
                thumbnailHeight: 100,
                uploadMultiple: true,
                parallelUploads: 20,
                autoProcessQueue: false,
                acceptedFiles: ".csv"
            }
        };
    },
    created() {
        this.fetchAdfPools();
        this.fetchData();
    },
    methods: {
        submitForm() {
            this.alertDismissCountDown = 0;
            this.showMessage = false;
            if (this.uploadFiles.length === 0) {
                this.alertDismissCountDownUpload = 7;
                this.showMessageUpload = true;
                this.uploadMessageType = "danger";
                this.messageUpload = 'Es sind noch keine CSV-Dateien zum Hochladen ausgewählt!';
                return;
            }
            this.alertDismissCountDownUpload = 1000;
            this.showMessageUpload = true;
            this.uploadMessageType = "warning";
            this.messageUpload = 'Die CSV-Dateien werden hochgeladen ....';
            let formData = new FormData();
            let fileToBig = 0;
            for (var i = 0; i < this.uploadFiles.length; i++) {
                if (this.uploadFiles[i].size < (1024 * 1024 * 10)) {
                    formData.append("files[" + i + "]", this.uploadFiles[i]);
                } else {
                    fileToBig = 1;
                    console.log('File too big! 10 MB max.');
                }
            }
            if (fileToBig === 1) {
                this.uploadFiles = [];
                this.alertDismissCountDownUpload = 7;
                this.showMessageUpload = true;
                this.uploadMessageType = "danger";
                this.messageUpload = 'Mindestens eine der hochzuladenden CSV-Dateien ist größer als 10 MB. Das Hochladen der Dateien wurde abgebrochen.';
                this.$refs.dropzoneImportUpload.removeAllFiles();
                return;
            }

            formData.append('pool_key', this.form.adfPool);
            this.$http
                .post("interface/importer/upload",
                    formData,
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-POST-/addressflow/v1/interface/importer/upload"
                            ),
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                .then(response => {
                    console.log(respons.json());
                    return response.json();
                })
                .then(data => {
                    console.log(data.success);
                    console.log(data.message);
                    this.uploadFiles = [];
                    this.alertDismissCountDownUpload = 7;
                    this.showMessageUpload = true;
                    this.uploadMessageType = "success";
                    this.messageUpload = 'Die CSV-Dateien wurden erfolgreich hochgeladen.';
                })
                .catch((err) => {
                    console.log(err);
                    console.log('WTF CORS I dont know!');
                    this.uploadFiles = [];
                    this.alertDismissCountDownUpload = 7;
                    this.showMessageUpload = true;
                    this.uploadMessageType = "success";
                    this.messageUpload = 'Die CSV-Dateien wurden erfolgreich hochgeladen.';
                    this.fetchData();
                });
            this.$refs.dropzoneImportUpload.removeAllFiles();
        },
        fetchAdfPools() {
            this.$http
                .get("interface/pool", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/pool"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    for (let i in data.pools) {
                        if (data.pools[i].import_allowed && data.pools[i].status === 1) {
                            this.adfPools.push({'text': data.pools[i].key + ' - ' + data.pools[i].name, 'value': data.pools[i].key});
                        }
                    }
                });
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        uploadFileAdded(file) {
            this.uploadFiles.push(file);
            console.log(this.uploadFiles.length);
        },
        updateTable(data) {
            const importFilesArray = [];
            let oneImportIsRunning = 0;
            for (let i in data.files) {
                let file = data.files[i];

                // injecting unique id of files table cells
                file["unique_id_delete"] = file.unique_id;
                file["unique_id_get_result"] = file.unique_id;

                console.log(file.unique_id + ' - ' + file.status);
                if (file.status === 1) {
                    oneImportIsRunning = 1;
                }
                importFilesArray.push(file);
            }
            console.log(oneImportIsRunning);
            console.log(importFilesArray.length);
            //if not at least one Import is still running stop the automatic Update
            if (oneImportIsRunning === 1) {
                console.log('Reload page.');
                setTimeout(this.fetchData, 5000);
                console.log('Page reloaded.');
            } else {
                console.log('Page NOT reloaded.');
            }
            this.importFiles = importFilesArray;
            this.totalNumberOfImportFiles = this.importFiles.length;
        },
        fetchData() {
            console.log('Data fetched!');
            this.$http
                .get("interface/importer", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/importer"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    this.updateTable(data);
                });
        },
        triggerImport(poolKey, uniqueId) {
            this.$http
                .get("interface/importer/" + poolKey + "/uid/" + uniqueId, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/importer/" + poolKey + "/uid/" + uniqueId
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.alertDismissCountDown = 7;
                    this.showMessage = true;
                    if (data.success === true) {
                        this.importStarted = uniqueId;
                        this.messageType = "success";
                        this.message = 'Import wurde erfolgreich terminiert und startet in Kürze.';
                        this.fetchData();
                    } else {
                        this.messageType = "danger";
                        this.message = 'Fehler bei der Terminierung des Imports! Prüfen Sie, ob bereits ein Import in diesen Pool läuft.';
                    }
                });
        },
        downloadFile(poolKey, uniqueId, realFilename) {
            this.$http
                .get("interface/importer/" + poolKey + "/result/" + uniqueId, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/importer/" + poolKey + "/result/" + uniqueId
                        )
                    }
                })
                .then(response => {
                    let blob = this.convertToBlob(response.data);
                    FileSaver.saveAs(blob, realFilename + '.result.csv');
                });
        },
        convertToBlob(b64Data, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        deleteImportFile(uniqueId) {
            this.deleteButtonDisabled = true;
            this.alertDismissCountDown = 100;
            this.showMessage = true;
            this.messageType = "warning";
            this.message = 'Die CSV-Datei wird gelöscht ....';

            this.$http
                .delete("interface/importer/" + uniqueId, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-DELETE-/addressflow/v1/interface/importer/" + uniqueId
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.fetchData();
                    this.alertDismissCountDown = 7;
                    if (data.success === true) {
                        this.messageType = "success";
                        this.showMessage = true;
                        this.message = 'Die CSV-Datei wurde (inkl. Fehlerprotokoll) erfolgreich gelöscht.';
                    } else {
                        this.messageType = "danger";
                        this.showMessage = true;
                        this.message = 'Fehler beim Löschen der Import-Datei!';
                    }
                });
        },
        importIsRunning(status) {
            return status === 1;
        },
        importCantBeStarted(status) {
            return status !== 0 && status !== 13;
        },
        countDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown;
        },
        getTitleByStatus(title) {
            return title;
        },
        getVariantByStatus(status) {
            let variant;
            switch (status) {
                case 0:
                    variant = 'secondary';
                    break;
                case 1:
                    variant = 'warning';
                    break;
                case 2:
                    variant = 'success';
                    break;
                case 4:
                    variant = 'dark';
                    break;
                case 5:
                    variant = 'info';
                    break;
                default:
                    variant = 'danger';
            }

            return variant;
        }
    }
}
</script>
<style>
.vue-dropzone {
    background: #E0E0E0;
}
</style>

