<template>
  <div class="container-fluid">
    <div class="my-4">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Erstellen</b-button>
    </div>

    <b-table hover :items="exportRules" :fields="tableFields">
      <template v-slot:cell(title)="data">
        <router-link :to="{ name: 'edit_export_rule', params: { exportRuleId: data.value.id }}">
          <a>{{ data.value.title }}</a>
        </router-link>
      </template>

      <template v-slot:cell(schema_definition)="data">
        <router-link v-if="!data.value.createNew"
          :to="{ name: 'edit_export_rule_schema_definition', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Bearbeiten</a>
        </router-link>

        <router-link
          v-if="data.value.createNew"
          :to="{ name: 'create_export_rule_schema_definition', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Erstellen</a> <span class="badge badge-pill badge-info">!</span>
        </router-link>
      </template>

      <template v-slot:cell(export_definition)="data">
        <router-link
          v-if="!data.value.createNew"
          :to="{ name: 'edit_export_rule_export_definition', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Bearbeiten</a>
        </router-link>
        <router-link
          v-if="data.value.createNew"
          :to="{ name: 'create_export_rule_export_definition', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Erstellen</a> <span class="badge badge-pill badge-info">!</span>
        </router-link>
      </template>

      <template v-slot:cell(export_structure)="data">
        <router-link
          v-if="!data.value.createNew"
          :to="{ name: 'edit_export_rule_export_structure', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Bearbeiten</a>
        </router-link>

        <router-link
          v-if="data.value.createNew"
          :to="{ name: 'create_export_rule_export_structure', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Erstellen</a> <span class="badge badge-pill badge-info">!</span>
        </router-link>
      </template>

      <template v-slot:cell(export_schedule)="data">
        <router-link
          v-if="!data.value.createNew"
          :to="{ name: 'edit_export_rule_export_schedule', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Bearbeiten</a>
        </router-link>

        <router-link
          v-if="data.value.createNew"
          :to="{ name: 'create_export_rule_export_schedule', params: { exportRuleId: data.value.exportRuleId }}"
        >
          <a>Erstellen</a> <span class="badge badge-pill badge-info">!</span>
        </router-link>
      </template>
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="Titel eingeben"
      cancel-title="Abbrechen"
      ok-title="Neue Export-Regel erstellen"
      @ok="createNewExportRule"
    >
      <b-form-input v-model="newTitle" autofocus></b-form-input>
      <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">{{ createErrorMsg }}</b-alert>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableFields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "title",
          label: "Titel"
        },
        {
          key: "autoExport",
          label: "Auto-Export"
        },
        {
          key: "schema_definition",
          label: "Schema-Definition"
        },
        {
          key: "export_definition",
          label: "Export-Definition"
        },
        {
          key: "export_structure",
          label: "Export-Template"
        },
        {
          key: "export_schedule",
          label: "Export-Zeiten"
        }
      ],
      exportRules: [],
      newTitle: "",
      showCreateErrorMsg: false,
      createErrorMsg: ""
    };
  },
  created() {
    this.fetchData(1);
  },
  methods: {
    updateTable(data) {
      const exportRulesArray = [];
      for (let i in data.export_rules) {
        let exportRule = data.export_rules[i];

        // injecting pool key to table cells
        exportRule["title"] = { title: exportRule.title, id: exportRule.id };
        exportRule["schema_definition"] = {
          exportRuleId: exportRule.id,
          createNew: exportRule.schemaDefinition === null,
          schemaDefinition: exportRule.schemaDefinition
        };
        exportRule["export_definition"] = {
          exportRuleId: exportRule.id,
          createNew: exportRule.exportDefinitions.length === 0
        };
        exportRule["export_structure"] = {
          exportRuleId: exportRule.id,
          createNew: exportRule.exportStructure === null
        };
        exportRule["export_schedule"] = {
          exportRuleId: exportRule.id,
          createNew: exportRule.exportSchedule === null
        };

        exportRulesArray.push(exportRule);
      }
      this.exportRules = exportRulesArray;
    },
    fetchData(currentPage, resetPaginator, showDetailedInfo) {
      if (resetPaginator) {
        this.resetPaginator = resetPaginator;
      }

      this.$http
        .get("interface/export_rule", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/interface/export_rule"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updateTable(data);
        });
    },
    createNewExportRule(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.showCreateErrorMsg = false;

      if (this.newTitle.length < 3) {
        this.showCreateErrorMsg = true;
        this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
        return false;
      }

      this.$http
        .post(
          "export_rule",
          {
            title: this.newTitle,
            party: this.$store.state.user.party
          },
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/export_rule"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.$refs["create-new-modal"].hide();
            this.fetchData(1);
          } else {
            this.showCreateErrorMsg = true;
            if (data.errors && data.errors.title) {
              this.createErrorMsg = "Ungültiger Titel. Nur Snake-Case erlaubt.";
            }
            if (data.alreadyExists) {
              this.createErrorMsg = "Eine Export-Regel mit diesem Titel existiert bereits.";
            }
          }
        });
    }
  }
};
</script>

