<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/parties')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>{{ partyTitle }}</h1>
    </div>

    <div class="row">
      <label>Export-Regeln</label>
      <multiselect
        v-model="selectedExportRules"
        track-by="id"
        label="title"
        select-label="Auswählen"
        placeholder="Export-Regel hinzufügen"
        :options="exportRules"
        :multiple="true"
        :taggable="true"
      ></multiselect>
    </div>

    <div class="row my-3">
      <label>Finisher</label>
      <multiselect
        v-model="selectedFinishers"
        track-by="id"
        label="name"
        select-label="Auswählen"
        placeholder="Finisher hinzufügen"
        :options="finishers"
        :multiple="true"
        :taggable="true"
      ></multiselect>
    </div>

    <div class="row my-3">
      <b-button variant="primary" @click="save()" :disabled="saveButtonDisabled">Speichern</b-button>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >Der Mandant wurde erfolgreich gespeichert.</b-alert>
      </div>
    </div>

    <div class="row my-3">
      <gpg-keys :partyTitle="partyTitle" />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import GpgKeys from "./GpgKeys.vue";

export default {
  components: {
    Multiselect,
    GpgKeys
  },
  data() {
    return {
      partyTitle: this.$route.params.partyTitle,
      updatePartyObj: null,
      exportRules: [],
      selectedExportRules: [],
      finishers: [],
      selectedFinishers: [],
      saveButtonDisabled: false,
      alertDismissCountDown: 0
    };
  },
  watch: {
    selectedExportRules(val) {
      this.updatePartyObj.export_rules = [];
      for (let i in val) {
        this.updatePartyObj.export_rules.push(val[i].title);
      }
    },
    selectedFinishers(val) {
      this.updatePartyObj.finishers = [];
      for (let i in val) {
        this.updatePartyObj.finishers.push(val[i].id);
      }
    }
  },
  created() {
    this.fetchPartyForm();
    this.fetchExportRules();
    this.fetchFinishers();
  },
  methods: {
    fetchPartyForm() {
      this.$http
        .get("form/party/update/" + this.partyTitle, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/party/update/" + this.partyTitle
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updatePartyObj = data.initialValues;
          this.selectedExportRules = this.updatePartyObj.export_rules;
          this.selectedFinishers = this.updatePartyObj.finishers;
        });
    },
    updateExportRules(data) {
      const exportRulesArray = [];
      for (let i in data) {
        let exportRule = {
          id: i,
          title: data[i]
        };
        exportRulesArray.push(exportRule);
      }
      this.exportRules = exportRulesArray;
    },
    fetchExportRules() {
      this.$http
        .get("export_rule?general=1", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/export_rule"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updateExportRules(data);
        });
    },
    fetchFinishers() {
      this.$http
        .get("finisher", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/finisher"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.finishers = data.finishers;
        });
    },
    save() {
      this.saveButtonDisabled = true;
      this.$http
        .put("admin/party/" + this.partyTitle, this.updatePartyObj, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-PUT-/addressflow/v1/admin/party/" + this.partyTitle
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.alertDismissCountDown = 2;
          }
          this.saveButtonDisabled = false;
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
