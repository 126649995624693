<template>
    <div>
        <div class="row">
            Status:
            <span v-show="state === 'start'" class="badge badge-info">Start</span>
            <span v-show="state === 'edited'" class="badge badge-info">In Bearbeitung</span>
            <span v-show="state === 'review'" class="badge badge-info">In Überprüfung</span>
            <span v-show="state === 'tested'" class="badge badge-info">Test abgeschlossen</span>
            <span v-show="state === 'published'" class="badge badge-info">Wurde veröffentlicht</span>
        </div>
        <div class="row my-3">
            <b-button
                class="mr-2"
                v-show="state === 'start'"
                variant="primary"
                @click="startEditing()"
                :disabled="!allowEdit"
            >Bearbeitung starten</b-button>
            <b-button
                class="mr-2"
                variant="primary"
                @click="save()"
                v-show="state === 'edited'"
                :disabled="saveButtonDisabled"
            >Speichern</b-button>
            <b-button
                class="mr-2"
                variant="success"
                @click="goToReviewState()"
                v-show="state === 'edited'"
                :disabled="objectEdited"
            >Weiter</b-button>
            <b-button
                class="mr-2"
                variant="primary"
                @click="showTestExportModal()"
                v-show="state === 'review' && options.hasTestExport"
            >Test-Export ausführen</b-button>
            <b-button
                class="mr-2"
                variant="success"
                @click="goToTestedState()"
                v-show="state === 'review'"
                :disabled="testedButtonDisabled"
            >Getestet</b-button>
            <b-button
                class="mr-2"
                variant="success"
                @click="publish()"
                v-show="state === 'tested'"
            >Veröffentlichen</b-button>
            <b-button
                variant="danger"
                @click="reject()"
                v-show="state !== 'start' && state !== 'published'"
            >Änderungen verwerfen</b-button>
        </div>
        <div class="row my-3">
            <div class="col">
                <b-alert :show="!allowEdit">Globale Exportregeln können nicht bearbeitet werden</b-alert>
                <b-alert
                    variant="success"
                    :show="alertDismissCountDown"
                    fade
                    dismissible
                    @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
                    @dismiss-count-down="countDownChanged"
                >Die Schema-Definition wurde erfolgreich gespeichert.</b-alert>

                <b-alert
                    variant="danger"
                    :show="saveError"
                    fade
                    dismissible
                    @dismissed="saveButtonDisabled=false"
                >
                    <h5>Fehler beim Speichern</h5>
                    <br>
                    <b>Message:</b> {{ validationErrorMessage }}
                    <br>
                    <b>Errors:</b>
                    <ul v-show="jsonErrors" v-for="error in jsonErrors">
                        <li>{{ error }}</li>
                    </ul>
                </b-alert>
            </div>
        </div>

        <test-export-modal
            :showMe="isTestModalVisible"
            @close="isTestModalVisible=false; testedButtonDisabled=false"
            :objectId="id"
            :context="options.context"
            :exportRuleId="exportRuleId"
        />
    </div>
</template>

<script>
import TestExportModal from "../modals/TestExportModal.vue";

export default {
    components: {
        testExportModal: TestExportModal
    },
    props: {
        options: Object,
        objectState: String,
        objectContent: Object,
        objectId: Number,
        exportRuleId: Number,
        initialFetchData: Function,
        allowEdit: Boolean
    },
    data() {
        return {
            id: this.objectId,
            state: this.objectState,
            urlPath: "workflow/" + this.options.context + "/",
            objectEdited: false,
            saveButtonDisabled: false,
            alertDismissCountDown: 0,
            isTestModalVisible: false,
            testedButtonDisabled: true,
            saveError: false,
            jsonErrors: null,
            validationErrorMessage: ''
        };
    },
    watch: {
        state(val) {
            this.$emit("update:objectState", this.state);

            if (this.state === "edited") {
                window.addEventListener("keydown", this.keyboardSave);
            } else {
                window.removeEventListener("keydown", this.keyboardSave);
            }
        },
        id(val) {
            this.$emit("update:objectId", this.id);
        },
        objectContent(val) {
            this.objectEdited = true;
        }
    },
    created() {
        if (this.options.context === 'schema_definition') {
            this.testedButtonDisabled = false;
        }
    },
    destroyed() {
        window.removeEventListener("keydown", this.keyboardSave);
    },
    methods: {
        startEditing() {
            this.state = "edited";
            this.requestWorkingCopy();
        },
        requestWorkingCopy() {
            this.$http
                .get(this.urlPath + this.objectId + "/request_working_copy", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/" +
                            this.urlPath +
                            this.objectId +
                            "/request_working_copy"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.state = data.currentPlace;
                    this.$emit("update:objectContent", data.content);
                    this.id = data.id;
                });
        },
        reject() {
            this.$http
                .put(
                    this.urlPath + this.id + "/reject",
                    { schemaDefinition: this.schemaDefinition },
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-PUT-/addressflow/v1/" + this.urlPath + this.id + "/reject"
                            )
                        }
                    }
                )
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.state = "start";
                        this.initialFetchData();
                    }
                });
        },
        keyboardSave(e) {
            if (
                (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
                e.keyCode == 83
            ) {
                e.preventDefault();
                this.save();
            }
        },
        save() {
            this.saveButtonDisabled = true;
            this.saveError = false;
            this.jsonErrors = null;
            this.validationErrorMessage = '';

            this.$http
                .put(
                    this.urlPath + this.id + "/edit_working_copy",
                    this.objectContent,
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-PUT-/addressflow/v1/" + this.urlPath + this.id + "/edit_working_copy"
                            )
                        }
                    }
                )
                .then(response => {
                    return response.json();
                }, response => {
                    return response.json();
                })
                .then(data => {
                    this.saveButtonDisabled = false;
                    if (data.success === true) {
                        this.objectEdited = false;
                        this.alertDismissCountDown = 2;
                    } else {
                        this.saveError = true;
                        if (data.message) {
                            this.validationErrorMessage = data.message;
                        }
                        if (data.errors) {
                            this.jsonErrors = data.errors;
                        }
                    }
                });
        },
        goToReviewState() {
            this.$http
                .put(
                    this.urlPath + this.id + "/to_review",
                    {},
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-PUT-/addressflow/v1/" + this.urlPath + this.id + "/to_review"
                            )
                        }
                    }
                )
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.state = "review";
                    }
                });
        },
        showTestExportModal() {
            this.isTestModalVisible = true;
        },
        goToTestedState() {
            this.$http
                .put(
                    this.urlPath + this.id + "/to_tested",
                    {},
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-PUT-/addressflow/v1/" + this.urlPath + this.id + "/to_tested"
                            )
                        }
                    }
                )
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (data.success === true) {
                        this.state = "tested";
                    }
                });
        },
        publish() {
            this.$bvModal
                .msgBoxConfirm(
                    "Beim Veröffentlichen werden alle Änderungen in den Live-Betrieb übernommen. Bist du sicher?",
                    {
                        cancelTitle: "Abbrechen"
                    }
                )
                .then(value => {
                    if (value) {
                        this.$http
                            .post(
                                this.urlPath + this.id + "/publish",
                                {},
                                {
                                    headers: {
                                        Authorization: this.createHmacHash(
                                            this.$store.state.user.adfAdminUser,
                                            "-POST-/addressflow/v1/" + this.urlPath + this.id + "/publish"
                                        )
                                    }
                                }
                            )
                            .then(response => {
                                return response.json();
                            })
                            .then(data => {
                                if (data.success === true) {
                                    this.state = "published";
                                }
                            });
                    }
                });
        },
        countDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown;
        }
    }
};
</script>
