<template>
  <div>
    <h2>GPG-Keys</h2>

    <div class="my-3">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Importieren</b-button>
    </div>

    <b-table hover :items="gpgKeys" :fields="tableFields" v-show="gpgKeys.length">
      <template v-slot:cell(expires_at)="data">{{ data.item.expires_at | formatDate }}</template>

      <template v-slot:cell(show)="data">
        <b-button @click="activeRecipient=data.item.recipient" v-b-modal.modal-show>anzeigen</b-button>
      </template>

      <template v-slot:cell(delete)="data">
        <b-button
          @click="activeRecipient=data.item.recipient;showConfirmDeleteModal()"
          variant="danger"
        >löschen</b-button>
      </template>
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="GPG-Key importieren"
      cancel-title="Abbrechen"
      ok-title="Importieren"
      @ok="importGpgKey"
    >
      <div class="form-group">
        <b-form-input
          id="new-recipient"
          v-model="newRecipient"
          :state="newRecipientState"
          placeholder="Recipient"
          autofocus
          trim
        ></b-form-input>
        <b-form-invalid-feedback id="new-recipient-feedback">{{ recipientErrorMsg }}</b-form-invalid-feedback>
      </div>
      <div class="form-group">
        <b-form-textarea
          id="gpg-key-textarea"
          :state="newGpgKeyState"
          v-model="newGpgKey"
          placeholder="Gpg-Key einfügen"
          rows="10"
        ></b-form-textarea>
        <b-form-invalid-feedback id="gpg-key-textarea-feedback">{{ gpgKeyErrorMsg }}</b-form-invalid-feedback>
      </div>

      <b-alert class="mt-2" v-model="showImportErrorMsg" variant="danger"><pre>{{ importErrorMsg }}</pre></b-alert>
    </b-modal>

    <b-modal id="modal-show" ok-only @show="fetchGpgKey">
      <template v-slot:modal-title>Gpg-Key ({{ activeRecipient }})</template>
      <pre class="my-4">{{ showGpgKey }}</pre>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    partyTitle: String
  },
  data() {
    return {
      gpgKeys: [],
      activeRecipient: "",
      showGpgKey: "",
      showImportErrorMsg: false,
      importErrorMsg: "",
      recipientErrorMsg: "",
      gpgKeyErrorMsg: "",
      tableFields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "recipient",
          label: "Recipient"
        },
        {
          key: "expires_at",
          label: "läuft ab am"
        },
        {
          key: "show",
          label: ""
        },
        {
          key: "delete",
          label: ""
        }
      ],
      newRecipient: "",
      newGpgKey: ""
    };
  },
  computed: {
    newRecipientState() {
      return this.newRecipient.length > 3;
    },
    newGpgKeyState() {
      return this.newGpgKey.length !== 0;
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    showConfirmDeleteModal() {
      const h = this.$createElement;
      const messageNode = h("p", { class: ["alert", "alert-warning"] }, [
        "Wenn du den Key löschst und dieser noch in einer Export-Regel verwendet wird, kann es zu schwerwiegenden Export-Fehlern kommen!"
      ]);

      this.$bvModal
        .msgBoxConfirm(messageNode, {
          title: "Gpg-Key für (" + this.activeRecipient + ") wirklich löschen?",
          okTitle: "Löschen",
          okVariant: "danger",
          cancelTitle: "Abbrechen"
        })
        .then(value => {
          this.deleteGpgKey();
        });
    },
    fetchData() {
      this.$http
        .get("admin/party/" + this.partyTitle + "/listgpgkeys", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/admin/party/" + this.partyTitle + "/listgpgkeys"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.gpgKeys = data.keys;
          }
        });
    },
    fetchGpgKey() {
      this.showGpgKey = "";
      this.$http
        .get("admin/party/" + this.partyTitle + "/showgpgkey", {
          params: {
            recipient: this.activeRecipient
          },
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/admin/party/" + this.partyTitle + "/showgpgkey"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.showGpgKey = data.key.public_key;
          }
        });
    },
    deleteGpgKey() {
      this.$http
        .delete("admin/party/" + this.partyTitle + "/deletegpgkey", {
          body: {
            recipient: this.activeRecipient
          },
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-DELETE-/addressflow/v1/admin/party/" + this.partyTitle + "/deletegpgkey"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.fetchData();
        });
    },
    importGpgKey(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.showImportErrorMsg = false;

      if (!this.newRecipientState) {
        this.recipientErrorMsg = "Recipient: Mindestens 3 Zeichen eingeben.";
        return false;
      }

      if (!this.newGpgKeyState) {
        this.gpgKeyErrorMsg = "Gpg-Key: Bitte einfügen.";
        return false;
      }

      this.$http
        .post(
          "admin/party/" + this.partyTitle + "/importgpgkey",
          {
            recipient: this.newRecipient,
            public_key: this.newGpgKey
          },
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/admin/party/" + this.partyTitle + "/importgpgkey",
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.$refs["create-new-modal"].hide();
            this.newRecipient = "";
            this.newGpgKey = "";
            this.fetchData();
          } else {
            if (data.errors) {
              this.showImportErrorMsg = true;
              this.importErrorMsg = JSON.stringify(data.errors, undefined, 2);
            }
          }
        });
    }
  }
};
</script>
