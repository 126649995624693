<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/exportrules')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>Export-Zeiten erstellen für {{ exportRuleTitle }}</h1>
    </div>

    <div class="row my-3">
      <b-link class="btn ml-2" variant="outline-info" @click="openCronTabGuru()">Cron Guru öffnen</b-link>
    </div>
    <div class="row">
      <v-jsoneditor v-model="exportSchedule" :plus="false" height="220px" @error="onError"></v-jsoneditor>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; $router.replace('/exportrules')"
          @dismiss-count-down="countDownChanged"
        >{{ exportScheduleSuccessMessage }}</b-alert>

        <b-alert
          variant="danger"
          :show="saveError"
          fade
          dismissible
          @dismissed="saveButtonDisabled=false"
        >
          Fehler beim Speichern:
          <br />
          {{ exportScheduleErrorMessage }}
          <p v-show="jsonErrors">{{ JSON.stringify(jsonErrors, undefined, 2) }}</p>
        </b-alert>
      </div>
    </div>

    <div class="row my-3">
      <b-button
        variant="primary"
        @click="saveExportSchedule()"
        :disabled="saveButtonDisabled"
      >Speichern</b-button>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  data() {
    return {
      exportRuleId: null,
      exportRuleTitle: "",
      exportSchedule: null,
      alertDismissCountDown: 0,
      saveButtonDisabled: false,
      saveError: false,
      jsonErrors: null,
      exportScheduleSuccessMessage: "",
      exportScheduleErrorMessage: "",
      cronTabGuruHash: ""
    };
  },
  components: {
    VJsoneditor
  },
  mounted() {
    window.addEventListener("keydown", this.keyboardSave);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyboardSave);
  },
  created() {
    this.exportRuleId = Number(this.$route.params.exportRuleId);
    this.fetchExportSchedule();
  },
  watch: {
    exportSchedule(val) {
      this.cronTabGuruHash = val
        ? this.createCronTabGuruHash(val.cron_expression)
        : "";
    }
  },
  methods: {
    fetchExportSchedule() {
      this.$http
        .get(
          "form/export_rule/create/" + this.exportRuleId + "/export_schedule",
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/form/export_rule/create/" +
                  this.exportRuleId +
                  "/export_schedule"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (!data.success) {
            alert("error");
          }

          this.exportSchedule = data.initialValues;
          this.exportRuleId = data.exportScheduleId;
          this.exportRuleTitle = data.exportRuleTitle;
        });
    },
    onError() {
      console.log("error");
    },
    saveExportSchedule() {
      this.saveButtonDisabled = true;
      this.saveError = false;
      this.jsonErrors = null;
      this.exportScheduleErrorMessage = "";

      this.createOrUpdateExportSchedule();
    },
    createOrUpdateExportSchedule() {
      this.$http
        .post(
          "export_rule/" + this.exportRuleTitle + "/exportschedule",
          this.exportSchedule,
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/export_rule/" +
                  this.exportRuleTitle +
                  "/exportschedule"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportScheduleSuccessMessage =
              "Export-Zeiten erfolgreich gespeichert.";
            this.alertDismissCountDown = 2;
          } else {
            this.saveButtonDisabled = false;
            this.saveError = true;
            if (data.message) {
              this.exportScheduleErrorMessage = data.message;
            }
            if (data.errors) {
              this.jsonErrors = data.errors;
            }
          }
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.createOrUpdateExportSchedule();
      }
    },
    createCronTabGuruHash(cronExp) {
      return (
        "#" +
        cronExp.minutes +
        "_" +
        cronExp.hours +
        "_" +
        cronExp.days +
        "_" +
        cronExp.months +
        "_" +
        cronExp.weekdays
      );
    },
    openCronTabGuru() {
      window.open("https://crontab.guru/" + this.cronTabGuruHash, "_blank");
    }
  }
};
</script>
