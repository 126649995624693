<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
        <h3>Sie haben sich erfolgreich angemeldet</h3>
        <p>Bitte ändern Sie Ihr Passwort</p>

        <validation-observer ref="form" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(onSubmit)"
            class="justify-content-center"
          >
            <validation-provider
              name="Altes Passwort"
              :rules="{ required: true, min: 8 }"
              v-slot="validationContext"
            >
              <b-form-group
                id="old-password-group-1"
                label="Altes Passwort"
                label-for="oldPassword"
              >
                <b-form-input
                  id="oldPassword"
                  name="oldPassword"
                  v-model="form.oldPassword"
                  :state="getValidationState(validationContext)"
                  aria-describedby="old-password-live-feedback"
                  :type="showPasswords ? 'text' : 'password'"
                  autofocus
                ></b-form-input>

                <b-form-invalid-feedback id="old-password-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Neues Passwort"
              :rules="{ passwordchange: true, required: true, min: 8 }"
              v-slot="validationContext"
            >
              <b-form-group
                id="new-password-group-1"
                label="Neues Passwort"
                label-for="newPassword"
              >
                <b-form-input
                  id="newPassword"
                  name="newPassword"
                  v-model="form.newPassword"
                  :state="getValidationState(validationContext)"
                  aria-describedby="new-password-live-feedback"
                  :type="showPasswords ? 'text' : 'password'"
                  @input="hideAuthError"
                ></b-form-input>

                <b-form-invalid-feedback id="new-password-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Passwort bestätigen"
              :rules="{ required: true, min: 8, password: '@Neues Passwort' }"
              v-slot="validationContext"
            >
              <b-form-group
                id="confirm-password-group-1"
                label="Passwort bestätigen"
                label-for="confirmPassword"
              >
                <b-form-input
                  id="confirmPassword"
                  name="confirmPassword"
                  v-model="form.confirmPassword"
                  :state="getValidationState(validationContext)"
                  aria-describedby="confirm-password-live-feedback"
                  :type="showPasswords ? 'text' : 'password'"
                ></b-form-input>

                <b-form-invalid-feedback id="confirm-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <b-form-checkbox v-model="showPasswords" value="1">
                Passwörter anzeigen
              </b-form-checkbox>
            </b-form-group>

            <b-alert :show="isAuthError()" variant="danger">{{
              $store.getters.authError
            }}</b-alert>

            <b-button type="submit" variant="primary" :disabled="loading">
              <b-spinner small v-if="loading"></b-spinner>
              Bestätigen
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
  </div>
</template>

<script>
import { store } from "../store/store";
import { extend } from "vee-validate";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Die Passwörter stimmen nicht überein",
});

export default {
  name: "Confirm",
  data() {
    return {
      error: false,
      loading: false,
      form: {
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      },
      showPasswords: false,
    };
  },
  created() {
    this.hideAuthError();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    onSubmit() {
      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.loading = true;
        var user = {
          userName: this.$store.getters.user.userName,
          password: this.form.oldPassword,
          newPassword: this.form.newPassword,
        };

        store
          .dispatch("login", user)
          .then((response) => {
            this.loading = false;
            this.$router.push("/pools");
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
      });
    },
    hideAuthError() {
      this.$store.commit("setAuthError", "");
    },
    isAuthError() {
      return this.$store.getters.authError ? true : false;
    },
  },
};
</script>
