<template>
    <div style="margin: 10px">
        <div>
            <b-alert show variant="secondary">
                <h4 class="alert-heading">DOI-Rate für Pool: {{ poolConfigData.name }}</h4>
                <hr>
                <div class="col-md-12 ">
                    <b-form inline>
                        <search-bar-quota @search="updateData"></search-bar-quota>
                        <div v-if="isLoading">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                    </b-form>

                </div>
            </b-alert>
        </div>

        <div class="chart-container row" style="position: relative;">
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px;">
                <b-alert show variant="secondary"><strong>DOI-Rate (Eingangsdatum)</strong></b-alert>
                <line-chart v-if="chartDoiQuotaLoaded" :chartdata="chartDoiQuotaCollection" :options="chartDoiQuotaCollectionOptions" ></line-chart>
            </div>
        </div>
        <div class="chart-container row" style="position: relative;">
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Eingegangene Leads (Eingangsdatum)</strong></b-alert>
                <line-chart v-if="chartExportedDayLoaded" :chartdata="chartExportedDayDataCollection" :options="chartExportedDayCollectionOptions"></line-chart>
            </div>
        </div>
    </div>
</template>

<script>
    import LineChart from './Charts/LineChart';
    import searchBarQuota from "./SearchBarQuota.vue";

    export default {
        components: {
            searchBarQuota,
            LineChart
        },
        computed: {
            isDevSystem: function () {
                return (process.env.NODE_ENV === 'development');
            },
        },
        data () {
            return {
                poolKey: null,
                poolConfigData: null,
                chartDoiQuotaLoaded: false,
                chartDoiQuotaCollection: null,
                chartDoiQuotaCollectionOptions: null,
                chartExportedDayLoaded: false,
                chartExportedDayDataCollection: null,
                chartExportedDayCollectionOptions: null,
                isLoading: false,
                dateFrom: '',
                dateTo: ''
            }
        },
        created() {
            this.poolKey = this.$route.params.poolKey;
            this.fetchPoolConfigData();
            this.updateData();
        },
        methods: {
            fetchPoolConfigData() {
                const poolKey = this.poolKey;

                this.$http
                    .get("pool/" + poolKey, {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-GET-/addressflow/v1/pool/" + poolKey
                            )
                        }
                    })
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        this.poolConfigData = data;
                    });
            },
            setDates(reset) {
                // TODO same code as in SearchBarQuota
                this.today = new Date();
                if (this.$store.state.quotaFilterDateFrom === '' || reset) {
                    this.today.setMonth(this.today.getMonth() - 1);
                    this.dateFrom = this.today.toISOString().slice(0,10);
                }
                if (this.$store.state.quotaFilterDateTo === '' || reset) {
                    this.dateTo = new Date().toISOString().slice(0,10)
                }
            },
            updateData() {
                this.isLoading = true;
                this.chartDoiQuotaLoaded = false;
                this.chartExportedDayLoaded = false;
                this.dateFrom = this.$store.state.quotaFilterDateFrom;
                this.dateTo = this.$store.state.quotaFilterDateTo;
                this.setDates(false)
                try {
                    this.fetchBarExportedDayChartData();
                    this.fetchBarDoiQuotaChartData();
                } catch (e) {
                    console.log(e)
                }
                this.isLoading = false;
            },
            fetchData(dataPath, callback) {
                this.$http.get(this.$dashboardUrl + dataPath)
                    .then(response => {
                        return response.json();
                    })
                    .then(data => {
                        if (callback) callback(data);
                    });
            },
            fetchBarDoiQuotaChartData() {
                const party = this.$store.state.user.party;
                const poolKey = this.poolKey;

                const dataPath = `adxmetric/doirate/${party}/line/${poolKey}/${this.dateFrom}/${this.dateTo}?replace[${poolKey}]=Prozent`;
                this.fetchData(dataPath, (data) => {
                    this.chartDoiQuotaCollection = data;
                    this.chartDoiQuotaLoaded = true;
                    this.chartDoiQuotaCollectionOptions = data.options;
                })
            },
            fetchBarExportedDayChartData() {
                const party = this.$store.state.user.party;
                const poolKey = this.poolKey;

                const dataPath = `adxmetric/leadscreated/${party}/line/${poolKey}/${this.dateFrom}/${this.dateTo}?replace[${poolKey}]=Anzahl`;
                this.fetchData(dataPath, (data) => {
                    this.chartExportedDayDataCollection = data;
                    this.chartExportedDayLoaded = true;
                    this.chartExportedDayCollectionOptions = data.options;
                })
            }
        }
    }
</script>

<style>
    .single-chart {
        max-width: 1200px;
        max-height: 500px;
    }
</style>
