<template>
    <div>
        <div class="row my-4 filters border-danger">
            <div class="col-md-12">
                <form class="form-inline float-left">
                    <div class="mx-3">
                        <button class="btn btn-dark" @click="resetFilter">Filter zurücksetzen</button>
                    </div>

                    <div class="form-group mx-3">
                        <label class="pr-2" for="dateFrom">Eingangsdatum der Leads von</label>
                        <b-form-datepicker id="dateFrom" v-model="filter.dateFrom" @input="changeStartDate"></b-form-datepicker>
                    </div>

                    <div class="form-group mx-3">
                        <label class="pr-2" for="dateTo">bis</label>
                        <b-form-datepicker id="dateTo" v-model="filter.dateTo" @input="changeEndDate"></b-form-datepicker>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            filter: {
                dateFrom: this.$store.state.poolStatsFilterDateFrom,
                dateTo: this.$store.state.poolStatsFilterDateFrom,
            }
        }
    },
    mounted() {
        this.resetFilter();
    },
    methods: {
        setDates(reset) {
            this.today = new Date();
            if (this.$store.state.poolStatsFilterDateFrom === '' || reset) {
                this.today.setMonth(this.today.getMonth() - 1);
                this.filter.dateFrom = this.today.toISOString().slice(0,10);
            }
            if (this.$store.state.poolStatsFilterDateTo === '' || reset) {
                this.filter.dateTo = new Date().toISOString().slice(0,10)
            }
        },
        changeStartDate() {
            this.$store.commit('setLogPoolStatsFilterDateFrom', this.filter.dateFrom);
            this.$emit('search', 1, true);
        },
        changeEndDate() {
            this.$store.commit('setLogPoolStatsFilterDateTo', this.filter.dateTo);
            this.$emit('search', 1, true);
        },
        resetFilter() {
            this.$store.dispatch('reset');
            this.setDates(true);
            this.changeStartDate();
            this.changeEndDate();

            this.$emit('search', 1, true);
        },
    }
}
</script>
