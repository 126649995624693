import Home from './components/Home.vue';
import AuthenticationRequired from './components/AuthenticationRequired.vue';
import Pools from './components/Pools.vue';
import PoolStats from './components/PoolStats.vue';
import PoolQuotas from './components/PoolQuotas.vue';
import PoolExports from './components/PoolExports.vue';
import PoolSettings from './components/PoolSettings.vue';
import ExportSettings from './components/ExportSettings.vue';
import ExportedFiles from './components/ExportedFiles.vue';
import PoolProxys from './components/PoolProxys.vue';
import PoolProxySettings from './components/PoolProxy/PoolProxySettings.vue';
import ExportRules from './components/ExportRule/ExportRules.vue';
import EditExportRule from './components/ExportRule/EditExportRule.vue';
import EditSchemaDefinition from './components/ExportRule/EditSchemaDefinition.vue';
import CreateSchemaDefinition from './components/ExportRule/CreateSchemaDefinition.vue';
import CreateExportDefinition from './components/ExportRule/CreateExportDefinition.vue';
import CreateExportStructure from './components/ExportRule/CreateExportStructure.vue';
import CreateExportSchedule from './components/ExportRule/CreateExportSchedule.vue';
import EditExportDefinition from './components/ExportRule/EditExportDefinition.vue';
import EditExportStructure from './components/ExportRule/EditExportStructure.vue';
import EditExportSchedule from './components/ExportRule/EditExportSchedule.vue';
import Finisher from './components/Finisher/Finisher.vue';
import CreateFinisher from './components/Finisher/CreateFinisher.vue';
import EditFinisher from './components/Finisher/EditFinisher.vue';

import EmailTemplates from "./components/EmailTemplates";
import CreateEmailTemplate from "./components/EmailTemplate/CreateEmailTemplate";
import EditEmailTemplate from "./components/EmailTemplate/EditEmailTemplate";

import DataFlow from './components/DataFlow.vue';

import Importer from './components/Importer/Importer';

import Parties from './components/Party/Parties.vue';
import EditParty from './components/Party/EditParty.vue';

import Users from './components/User/Users.vue';
import UiUsers from './components/User/UiUsers.vue';
import UiUserEdit from './components/User/UiUserEdit.vue';

import UiParties from './components/Party/UiParties.vue';
import UiPartyEdit from './components/Party/UiPartyEdit.vue';

import Statistics from './components/Statistics.vue';

import Login from './components/Login.vue';
import Confirm from './components/Confirm.vue';
import ForgotPassword from "./components/ForgotPassword";
import LoggedOut from './components/LoggedOut.vue';

import Data from './components/Data.vue';

export const routes = [
    { path: '/authrequired', component: AuthenticationRequired },
    { path: '', component: Home, meta: { requiresAuth: true } },
    { path: '/pools', component: Pools, meta: { requiresAuth: true } },
    { path: '/pool/:poolKey', name: 'pool_config', component: PoolSettings, meta: { requiresAuth: true } },
    { path: '/pool/:poolKey/settings', name: 'pool_settings', component: PoolSettings, meta: { requiresAuth: true } },
    { path: '/pool/:poolKey/exportsettings', name: 'export_settings', component: ExportSettings, meta: { requiresAuth: true } },
    { path: '/pool/:poolKey/stats', name: 'pool_stats', component: PoolStats, meta: { requiresAuth: true } },
    { path: '/pool/:poolKey/quotas', name: 'pool_quotas', component: PoolQuotas, meta: { requiresAuth: true } },
    { path: '/pool/:poolId/exports', name: 'pool_exports', component: PoolExports, meta: { requiresAuth: true } },
    { path: '/poolproxys', component: PoolProxys, meta: { requiresAuth: true } },
    { path: '/poolproxy/:poolProxyKey/settings', name: 'pool_proxy_settings', component: PoolProxySettings, meta: { requiresAuth: true } },
    { path: '/exportrules', component: ExportRules, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId', name: 'edit_export_rule', component: EditExportRule, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/schemadefinition-create', name: 'create_export_rule_schema_definition', component: CreateSchemaDefinition, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportdefinition-create', name: 'create_export_rule_export_definition', component: CreateExportDefinition, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportstructure-create', name: 'create_export_rule_export_structure', component: CreateExportStructure, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportschedule-create', name: 'create_export_rule_export_schedule', component: CreateExportSchedule, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/schemadefinition', name: 'edit_export_rule_schema_definition', component: EditSchemaDefinition, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportdefinition', name: 'edit_export_rule_export_definition', component: EditExportDefinition, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportstructure', name: 'edit_export_rule_export_structure', component: EditExportStructure, meta: { requiresAuth: true } },
    { path: '/exportrules/:exportRuleId/exportschedule', name: 'edit_export_rule_export_schedule', component: EditExportSchedule, meta: { requiresAuth: true } },

    { path: '/finisher', component: Finisher, meta: { requiresAuth: true } },
    { path: '/finisher/new', name: 'create_finisher', component: CreateFinisher, meta: { requiresAuth: true } },
    { path: '/finisher/:finisherId', name: 'edit_finisher', component: EditFinisher, meta: { requiresAuth: true } },

    { path: '/emailtemplates', component: EmailTemplates, meta: { requiresAuth: true } },
    { path: '/emailtemplate/new', component: CreateEmailTemplate, meta: { requiresAuth: true } },
    { path: '/emailtemplate/:templateKey', name: "edit_email_template", component: EditEmailTemplate, meta: { requiresAuth: true } },

    { path: '/importer', component: Importer, meta: { requiresAuth: true } },

    { path: '/parties', component: Parties, meta: { requiresAuth: true } },
    { path: '/parties/:partyTitle', name: 'edit_party', component: EditParty, meta: { requiresAuth: true } },

    { path: '/users', component: Users, meta: { requiresAuth: true } },
    { path: '/uiusers', component: UiUsers, meta: { requiresAuth: true } },
    { path: '/uiusers/edit/:userName', name: 'uiuser_edit', component: UiUserEdit, meta: { requiresAuth: true } },
    { path: '/uiparties', component: UiParties, meta: { requiresAuth: true } },
    { path: '/uiparties/edit/:tenantId', name: 'uiparty_edit', component: UiPartyEdit, meta: { requiresAuth: true } },

    { path: '/statistics', component: Statistics, meta: { requiresAuth: true } },
    { path: '/data/:poolKey', name: 'data', component: Data, meta: { requiresAuth: true } },
    { path: '/data/:poolKey/files', name: 'exported_files', component: ExportedFiles, meta: { requiresAuth: true } },
    { path: '/login', component: Login },
    { path: '/confirm', component: Confirm },
    { path: '/forgotPassword', component: ForgotPassword},
    { path: '/loggedout', component: LoggedOut },

    { path: '/poolproxy/:poolProxyKey/dataflow', name: 'pool_proxy_dataflow', component: DataFlow, meta: { requiresAuth: true } },

];
