<template>
    <div class="container">
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
                <h4>Melden Sie sich bei Ihrem Konto an</h4>

                <validation-observer ref="form" v-slot="{ handleSubmit }">
                    <b-form
                        @submit.stop.prevent="handleSubmit(login)"
                        class="justify-content-center"
                    >
                        <validation-provider
                            name="Benutzername"
                            :rules="{ required: true, email: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                id="userName-group-1"
                                label="Benutzername"
                                label-for="userName"
                            >
                                <b-form-input
                                    id="userName"
                                    name="userName"
                                    placeholder="Benutzername"
                                    v-model="form.userName"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="user-name-live-feedback"
                                    type="text"
                                    autofocus
                                ></b-form-input>

                                <b-form-invalid-feedback id="user-name-live-feedback">{{
                                        validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="Passwort"
                            :rules="{ passwordlogin: true, required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                id="password-group-1"
                                label="Passwort"
                                label-for="password"
                            >
                                <b-form-input
                                    id="password"
                                    name="password"
                                    placeholder="Passwort"
                                    v-model="form.password"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="password-live-feedback"
                                    type="password"
                                ></b-form-input>
                                <b-form-invalid-feedback id="password-live-feedback">{{
                                        validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <b-alert :show="isAuthError()" variant="danger">{{
                                $store.getters.authError
                            }}
                        </b-alert>

                        <b-button type="submit" variant="primary" :disabled="loading">
                            <b-spinner v-if="loading" small></b-spinner>
                            Anmelden
                        </b-button>

                        <div
                            class="error"
                            v-if="error"
                            v-bind:data-test="auth.signIn.signInError"
                        >
                            {{ error }}
                        </div>
                    </b-form>
                </validation-observer>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
                <div class="text-right">
                    <b-link to="/forgotPassword">Passwort vergessen</b-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {store} from "../store/store";

export default {
    name: "Login",
    data() {
        return {
            error: false,
            loading: false,
            form: {
                userName: "",
                password: "",
            },
        };
    },
    created() {
        this.$store.commit("setAuthError", "");
    },
    methods: {
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        login() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }

                this.loading = true;
                store
                    .dispatch("login", {
                        userName: this.form.userName,
                        password: this.form.password,
                    })
                    .then((response) => {
                        this.loading = false;
                        if (response.newPasswordRequired) {
                            this.$router.push("/confirm");
                        } else {
                            this.$router.push("/pools");
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        console.log(err)
                        // Work around:
                        // Our messages from API are plain Strings,
                        // errors from Vue are objects.
                        // For Vue error Objects, we want to print a user-friendly message
                        if (typeof err == "object") {
                            this.$store.commit("setAuthError", "Anmeldung zur Zeit nicht möglich. Bitte versuchen Sie es erneut.");
                        }
                    });
            });
        },
        isAuthError() {
            return !!this.$store.getters.authError;
        },
    },
};
</script>
