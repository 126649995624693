'use strict';

import CryptoJS from "crypto-js/hmac-sha256";
import axios from "axios";

axios.defaults.baseURL = process.env.ROOT_API;

export function createHmacHash (user, unhashed) {
    let timestamp = Math.floor(Date.now() / 1000)
    unhashed = timestamp + unhashed
    let hash = CryptoJS(unhashed, user.adfAdminUserToken)

    return 'hmac ' + user.adfAdminUser + ':' + hash + ':' + timestamp;
}

export function fetchAdfUser(username, authUser, callback) {
    axios.get(`admin/user/${username}`, {
        headers: {
            Authorization: createHmacHash(
                authUser,
                `-GET-/addressflow/v1/admin/user/${username}`
            )
        }
    })
    .then(function (response) {
        if (callback)
            callback(response.data);
    })
    .catch(function (error) {
        console.log(error);
    });
}
