import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import CryptoJS from 'crypto-js/hmac-sha256'
import Vue from 'vue'
import VueResource from 'vue-resource'
import VueRouter from 'vue-router'
import App from './App.vue'
import { routes } from './routes'
import { testForStrongPassword } from './helpers/password.js'

import { store } from './store/store'
import moment from 'moment'

import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import * as rules from "vee-validate/dist/rules";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("de", de);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.use(BootstrapVue);
Vue.use(VueResource);
Vue.use(VueRouter);

Vue.mixin({
    methods: {
        createHmacHash: function (username, unhashed) {
            let timestamp = Math.floor(Date.now() / 1000)
            unhashed = timestamp + unhashed
            let hash = CryptoJS(unhashed, this.$store.state.user.adfAdminUserToken)

            return 'hmac ' + username + ':' + hash + ':' + timestamp;
        }
    }
})

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD.MM.YYYY HH:mm')
    }
})

Vue.filter('formatNumber', function(value) {
    if (value > 0) {
        return Intl.NumberFormat('de-DE').format(value);
    } else {
        return value;
    }
})

extend("passwordlogin", {
    message:
        "{_field_} erfüllt nicht alle Bedingungen.",
    validate: value => {
        return testForStrongPassword(value)
    }
});

extend("passwordchange", {
    message:
        "{_field_} muss folgende Kriterien erfüllen: mindestens 8 Zeichen lang sein sowie mindestens 1 Großbuchstaben, 1 Kleinbuchstaben, 1 Sonderzeichen und 1 Zahl enthalten.",
    validate: value => {
        return testForStrongPassword(value)}
});

//Vue.http.options.credentials = true;
Vue.http.options.root = process.env.ROOT_API;
Vue.prototype.$authUrl = process.env.ROOT_AUTH;
Vue.prototype.$dashboardUrl = process.env.ROOT_DASHBOARD;
Vue.prototype.$msgFlowUrl = process.env.ROOT_MSGFLOW;
Vue.prototype.$msgFlowToken = process.env.ROOT_MSGFLOW_TOKEN;

// importing these so webpack will put them into the dist folder when building
import EncodingIndexes from "text-encoding/lib/encoding-indexes.js";
import Encoding from "text-encoding/lib/encoding.js";

import { Raphael } from "raphael/raphael.min.js";

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn()) {
            next();
            return;
        } else {
            router.push('/login');
            return;
        }

        next('authrequired');
    } else {
        next();
    }
})

new Vue({
    el: '#app',
    router,
    store,
    render: h => h(App)
})
