<template>
    <div class="container-fluid">
        <div class="my-4">
            <b-button variant="primary" @click="$router.replace('/emailtemplate/new')">Erstellen</b-button>
        </div>

        <b-table hover :items="emailTemplates" :fields="tableFields" custom-foot>
            <template v-slot:cell(edit)="data">
                <b-button-group>
                    <router-link :to="{ name: 'edit_email_template', params: { templateKey: data.item.template_key}}">
                        <b-icon-pencil font-scale="2"></b-icon-pencil>
                    </router-link>
                </b-button-group>
            </template>

            <template v-slot:custom-foot>
                <tr>
                    <td colspan="4">
                        <strong>Anzahl: {{ totalNumberOfEmailTemplates }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>

        <pool-proxy-paginator
            @currentPageWasChanged="fetchData($event)"
            :items="emailTemplates"
            :totalNumberOfItems="totalNumberOfEmailTemplates"
            :limitPerPage="limitPerPage"
            :reset="resetPaginator"
        ></pool-proxy-paginator>

        <b-modal
            id="create-new-modal"
            ref="create-new-modal"
            title="Initialen Daten-Pool erzeugen"
            cancel-title="Abbrechen"
            ok-title="Neuen Pool-Proxy erstellen"
            @ok="createNewPoolProxy"
        >
            <b-form-group label="Pool-Name">
                <b-form-input v-model="newTitle" @input="showCreateErrorMsg=false"></b-form-input>
                <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">{{ createErrorMsg }}</b-alert>
            </b-form-group>
        </b-modal>
    </div>
</template>

<script>
import { BIcon, BIconPlus, BIconPencil, BIconDiagram2, BIconBraces } from "bootstrap-vue";
import Paginator from "./Paginator.vue";
import SearchBar from "./SearchBar.vue";
import FileSaver from "file-saver";
export default {
    components: {
        BIcon,
        BIconPlus,
        BIconPencil,
        BIconDiagram2,
        BIconBraces,
        FileSaver,
        poolProxyPaginator: Paginator,
        searchBar: SearchBar,
    },
    data() {
        return {
            tableFields: [
                {
                    key: "template_key",
                    label: "Template-Key",
                },
                {
                    key: "name",
                    label: "Name",
                },
                {
                    key: "created_at",
                    label: "Erstellungsdatum"
                },
                {
                    key: "edit",
                    label: "",
                },
            ],
            limitPerPage: 15,
            emailTemplates: [],
            totalNumberOfEmailTemplates: 0,
            resetPaginator: false,
            newTitle: "",
            showCreateErrorMsg: false,
            createErrorMsg: "",
            activeEmailTemplate: null,
        };
    },
    created() {
        this.fetchData(1);
    },
    methods: {
        updateTable(data) {
            const emailTemplatesArray = [];
            /*for (let i in data) {
                let emailTemplate = data[i];

                emailTemplatesArray.push(emailTemplate);
            }*/
            this.emailTemplates = data.templates;
            // console.log(this.emailTemplates);
            this.totalNumberOfEmailTemplates = data.total;
        },
        fetchData(currentPage, resetPaginator) {
            if (resetPaginator) {
                this.resetPaginator = resetPaginator;
            }

            let params = {
                limit: this.limitPerPage,
                offset: (currentPage - 1) * this.limitPerPage,
            };

            let searchValue = this.$store.state.searchValue;
            if (searchValue) {
                params.searchValue = searchValue;
            }

            this.$http
                .get(this.$msgFlowUrl + "templates", {
                    params: params,
                    headers: {
                        Authorization: this.$msgFlowToken
                    },
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.updateTable(data);
                });
        },
        createNewPoolProxy(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.showCreateErrorMsg = false;

            if (!this.selectedExportRule) {
                this.showExportRuleErrorMsg = true;
                return false;
            }

            if (this.newTitle.length < 3) {
                this.showCreateErrorMsg = true;
                this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
                return false;
            }

            this.$http
                .post(
                    "proxy",
                    {
                        pools: [
                            {
                                name: this.newTitle,
                                export_rule: this.selectedExportRule,
                            },
                        ],
                    },
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-POST-/addressflow/v1/proxy"
                            ),
                        },
                    }
                )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        this.$refs["create-new-modal"].hide();
                        this.fetchData(1);
                    } else {
                        this.showCreateErrorMsg = true;
                        if (data.errors && data.errors.name) {
                            this.createErrorMsg = "Ungültiger Name.";
                        }
                    }
                });
        },
        addPool(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.showCreateErrorMsg = false;

            if (!this.selectedExportRule) {
                this.showExportRuleErrorMsg = true;
                return false;
            }

            if (this.newTitle.length < 3) {
                this.showCreateErrorMsg = true;
                this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
                return false;
            }

            this.$http
                .post(
                    "proxy/" + this.activePoolProxy + "/addnewpool",
                    {
                        name: this.newTitle,
                        export_rule: this.selectedExportRule,
                    },
                    {
                        headers: {
                            Authorization: this.createHmacHash(
                                this.$store.state.user.adfAdminUser,
                                "-POST-/addressflow/v1/proxy/" + this.activePoolProxy + "/addnewpool"
                            ),
                        },
                    }
                )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    if (data.success === true) {
                        this.$refs["add-pool-modal"].hide();
                        this.fetchData(1);
                    } else {
                        this.showCreateErrorMsg = true;
                        if (data.errors && data.errors.name) {
                            this.createErrorMsg = "Ungültiger Name.";
                        }
                    }
                });
        },
        downloadSwagger(poolProxyKey) {
            this.$http
                .get(`proxy/${poolProxyKey}/swagger`, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            `-GET-/addressflow/v1/proxy/${poolProxyKey}/swagger`
                        )
                    }
                })
                .then(response => {
                    let blob = new Blob([JSON.stringify(response.data, undefined, 2)], {
                        type: 'application/json'
                    });
                    FileSaver.saveAs(blob, 'adf_user_data_'+poolProxyKey+'.json');
                });
        }
    },
};
</script>

