<template>
  <b-modal
    id="edit-modal"
    size="lg"

    @show="showFinisherSettingsModal"
    @hide="hideFinisherSettingsModal"
    @hidden="closeMe()"
    okTitle="Speichern"
    cancelTitle="Abbrechen"
    @ok="handleSaveClick"
  >
    <template v-slot:modal-title>Finisher-Settings</template>

    <b-form-select v-model="finisherSettingsToEdit.chain_parent" :options="chainParentOptions" class="my-3"></b-form-select>

    <v-jsoneditor
      :value="finisherSettingsToEdit"
      :plus="false"
      height="500px"
      @input="handleChange"
      :options="jsonEditorOptions"
    ></v-jsoneditor>

    <b-alert class="mt-3" variant="danger" :show="finisherSettingsToEditHasErrors" fade>
      Fehler beim Speichern.
      <pre id="finisher_settings_errors_json">{{ JSON.stringify(finisherSettingsToEditErrors, undefined, 2) }}</pre>
    </b-alert>
  </b-modal>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  components: {
    VJsoneditor
  },
  props: {
    finisherSettingsId: 0,
    showMe: false,
    poolProxyKey: '',
    allFinisherSettings: Array
  },
  watch: {
    showMe(val) {
      if (true === val) {
        this.$bvModal.show('edit-modal')
      }
    }
  },
  data() {
    return {
      chainParentOptions: [],
      selectedChainParent: null,
      finisherSettingsToEditHasErrors: false,
      finisherSettingsToEditErrors: [],
      finisherSettingsToEdit: {},
      tmpFinisherSettingsToEdit: {},
      flattenedChainChildrenIds: [],
      jsonEditorOptions: {
        mode: 'code',
        mainMenuBar: false
      }
    }
  },
  methods: {
    setChainParentOptions() {
      let chainParentOptions = [{
        value: null,
        text: 'Parent auswählen ...'
      }];
      for (let i in this.allFinisherSettings) {
        if (this.allFinisherSettings[i].id !== this.finisherSettingsId && !this.flattenedChainChildrenIds.includes(this.allFinisherSettings[i].id)) {
          chainParentOptions.push({
            value: this.allFinisherSettings[i].id,
            text: this.allFinisherSettings[i].id
          });
        }
      }
      this.chainParentOptions = chainParentOptions;
    },
    showFinisherSettingsModal() {
      window.addEventListener("keydown", this.keyboardSave);
      this.fetchFinisherSettings(this.finisherSettingsId);
    },
    hideFinisherSettingsModal() {
      window.removeEventListener("keydown", this.keyboardSave);
    },
    closeMe() {
      this.$emit("close");
    },
    handleSaveClick(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.saveFinisherSettings();
    },
    fetchFinisherSettings(finisherSettingsId) {
      this.$http
        .get("form/finisher_settings/update/" + finisherSettingsId, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/finisher_settings/update/" + finisherSettingsId
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.initialValues) {
            this.finisherSettingsToEdit = data.initialValues;
            this.tmpFinisherSettingsToEdit = this.finisherSettingsToEdit;
            this.flattenedChainChildrenIds = data.flattenedChainChildrenIds;
            this.setChainParentOptions();
          }
        });
    },
    onError() {
      console.log("error");
    },
    handleChange(value) {
      this.tmpFinisherSettingsToEdit = value;
    },
    keyboardSave(e) {
      this.finisherSettingsToEditHasErrors = false;
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.saveFinisherSettings();
      }
    },
    saveFinisherSettings() {
      this.updateFinisherSettings();
    },
    updateFinisherSettings() {
      this.finisherSettingsToEdit = this.tmpFinisherSettingsToEdit;
      let updateBody = this.finisherSettingsToEdit;
      updateBody.pool_proxy = this.poolProxyKey;
      this.$http
        .put(
          "finisher_settings/" + this.finisherSettingsId,
          updateBody,
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-PUT-/addressflow/v1/finisher_settings/" +
                  this.finisherSettingsId
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.$bvModal.hide("edit-modal");
            this.$emit('updated');
          } else {
            if (data.errors) {
              this.finisherSettingsToEditHasErrors = true;
              this.finisherSettingsToEditErrors = data.errors;
            } else {
              alert("System error");
            }
          }
        });
    }
  }
};
</script>
