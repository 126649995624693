<template>
    <div>
        <div style="margin: 10px">
            <b-alert show variant="secondary">
                <h4 class="alert-heading">Willkommen in der Administration!</h4>
                <hr>
                <p class="mb-0">
                    Time period: past 8 weeks
                </p>
            </b-alert>
        </div>

        <div class="chart-container row" style="margin: 10px; position: relative;">
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Created by weekday (of date created)</strong></b-alert>
                <line-chart v-if="chartLoaded" :chartdata="chartDataCollection" :options="chartDataOptions"></line-chart>
            </div>
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px;">
                <b-alert show variant="secondary"><strong>Email domains (extract of max 10)</strong></b-alert>
                <doughnut-chart v-if="chartEmailLoaded" :chartdata="chartEmailDataCollection" :options="chartEmailDataOptions"></doughnut-chart>
            </div>
        </div>
        <div class="chart-container row" style="margin: 10px; position: relative;">
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Created by date</strong></b-alert>
                <bar-chart v-if="chartCreatedDateLoaded" :chartdata="chartCreatedDateData" :options="chartCreatedDateOptions"></bar-chart>
            </div>
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Created by pool</strong></b-alert>
                <doughnut-chart v-if="chartPoolsLoaded" :chartdata="chartPoolsData" :options="chartPoolsOptions"></doughnut-chart>
            </div>
        </div>
    </div>
</template>

<script>
import LineChart from './Charts/LineChart';
import PieChart from './Charts/PieChart';
import CommitChart from "./Charts/CommitChart";
import DoughnutChart from "./Charts/DoughnutChart";
import BarChart from "./Charts/BarChart";
import Vue from 'vue';

export default {
    components: {
        BarChart,
        LineChart,
        PieChart,
        CommitChart,
        DoughnutChart
    },
    data () {
        return {
            chartLoaded: false,
            chartEmailLoaded: false,
            chartCreatedDateLoaded: false,
            chartPoolsLoaded: false,

            chartDataCollection: null,
            chartEmailDataCollection: null,
            chartCreatedDateData: null,
            chartPoolsData: null,

            chartCreatedDateOptions: null,
            chartPoolsOptions: null,
            chartEmailDataOptions: null,
            chartDataOptions: null,
        }
    },
    mounted () {
        this.chartLoaded = false;
        this.chartEmailLoaded = false;
        this.chartCreatedDateLoaded = false;
        this.chartPoolsLoaded = false;

        try {
            this.fetchLineChartData();
            this.fetchEmailPieChart();
            this.fetchCreatedDatePieChart();
            this.fetchPoolsPieChart();
        } catch (e) {
            console.log(e)
        }
    },
    created() {
        this.timePeriod = 'last8weeks';
    },
    methods: {
        fetchData(dataPath, callback) {
            this.$http.get(this.$dashboardUrl + dataPath)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (callback) callback(data);
                });
        },
        fetchLineChartData() {
            const party = this.$store.state.user.party;
            const pools = ['j1uLhj3f', 'fQD4uucR'];

            const poolIds = pools.join('+');

            //return `adxmetric/${party}/line/created_weekday/pool_id/${poolIds}`;
            // const dataPath = (process.env.NODE_ENV === 'development') ? 'data.json' : `adxmetric/${party}/line/created_weekday`;
            this.fetchData(`adxmetric/${party}/line/created_weekday/created_date/${this.timePeriod}`, (data) => {
                this.chartDataCollection = data;
                this.chartLoaded = true;
                this.chartDataOptions = data.options;
            })
        },
        fetchEmailPieChart() {
            const party = this.$store.state.user.party;

            this.fetchData(`adxmetric/${party}/doughnut/email/created_date/${this.timePeriod}`, (data) => {
                this.chartEmailDataCollection = data;
                this.chartEmailLoaded = true;
                this.chartEmailDataOptions = data.options;

            })
        },
        fetchCreatedDatePieChart() {
            const party = this.$store.state.user.party;

            this.fetchData(`adxmetric/${party}/bar/created_date/created_date/${this.timePeriod}`, (data) => {
                this.chartCreatedDateData = data;
                this.chartCreatedDateLoaded = true;
                this.chartCreatedDateOptions = data.options;
            })
        },
        fetchPoolsPieChart() {
            const party = this.$store.state.user.party;

            this.fetchData(`adxmetric/${party}/doughnut/pool/created_date/${this.timePeriod}`, (data) => {
                this.chartPoolsData = data;
                this.chartPoolsLoaded = true;
                this.chartPoolsOptions = data.options;

            })
        }
    }
}
</script>

<style>

.single-chart {
    max-width: 1200px;
    max-height: 550px;
}
</style>
