<template>
  <b-modal
    id="create-new-modal"
    size="lg"
    @show="showCreateFinisherSettingsModal()"
    @hide="hideCreateFinisherSettingsModal()"
    okTitle="Speichern"
    cancelTitle="Abbrechen"
    @ok="handleCreateClick"
  >
    <template v-slot:modal-title>Finisher-Settings</template>

    <b-form-select v-model="selectedFinisher" :options="finisherOptions" class="my-3"></b-form-select>

    <b-form-select v-if="selectedFinisher" v-model="finisherSettingsToCreate.chain_parent" :options="chainParentOptions" class="my-3"></b-form-select>

    <v-jsoneditor
      :value="finisherSettingsToCreate"
      :plus="false"
      height="500px"
      @input="handleCreateNewChange"
      :options="jsonEditorOptions"
    ></v-jsoneditor>

    <b-alert class="mt-3" variant="danger" :show="finisherSettingsToCreateHasErrors" fade>
      Fehler beim Speichern.
      <pre id="finisher_settings_errors_json">{{ JSON.stringify(finisherSettingsToCreateErrors, undefined, 2) }}</pre>
    </b-alert>
  </b-modal>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  components: {
    VJsoneditor
  },
  props: {
    poolProxyKey: '',
    allFinisherSettings: Array
  },
  watch: {
    selectedFinisher: function (val) {
      this.fetchCreateFinisherSettings();
    }
  },
  data() {
    return {
      chainParentOptions: [],
      finisherSettingsToCreateHasErrors: false,
      finisherSettingsToCreateErrors: [],
      finisherSettingsToCreate: {},
      tmpFinisherSettingsToCreate: {},
      saveButtonDisabled: true,
      finisherOptions: [],
      selectedFinisher: null,
      jsonEditorOptions: {
        mode: 'code',
        mainMenuBar: false
      }
    };
  },
  methods: {
    setChainParentOptions() {
      let chainParentOptions = [{
        value: null,
        text: 'Parent auswählen ...'
      }];
      for (let i in this.allFinisherSettings) {
        if (this.allFinisherSettings[i].id !== this.finisherSettingsId) {
          chainParentOptions.push({
            value: this.allFinisherSettings[i].id,
            text: this.allFinisherSettings[i].id
          });
        }
      }
      this.chainParentOptions = chainParentOptions;
    },
    showCreateFinisherSettingsModal() {
      window.addEventListener("keydown", this.keyboardCreate);
      this.fetchFinisherOptions();
    },
    hideCreateFinisherSettingsModal() {
      window.removeEventListener("keydown", this.keyboardCreate);
    },
    handleCreateClick(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (true === this.saveButtonDisabled) {
        return;
      }

      this.createNewFinisherSettings();
    },
    fetchFinisherOptions() {
      this.$http
        .get("finisher/party/" + this.$store.state.user.party, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/finisher/party/" + this.$store.state.user.party
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.finishers) {
            let finisherOptions = [{
              value: null,
              text: 'Finisher auswählen ...'
            }];
            for (let i in data.finishers) {
              finisherOptions.push({
                value: data.finishers[i].name,
                text: data.finishers[i].name
              });
            }
            this.finisherOptions = finisherOptions;
          }
        });
    },
    fetchCreateFinisherSettings() {
      this.$http
        .get("form/finisher_settings/create/" + this.selectedFinisher, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/finisher_settings/create/" + this.selectedFinisher
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.initialValues) {
            this.finisherSettingsToCreate = data.initialValues;
            this.tmpFinisherSettingsToCreate = this.finisherSettingsToCreate;
            this.saveButtonDisabled = false;
            this.setChainParentOptions();
          }
        });
    },
    handleCreateNewChange(value) {
      this.tmpFinisherSettingsToCreate = value;
    },
    keyboardCreate(e) {
      this.finisherSettingsToCreateHasErrors = false;
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.createNewFinisherSettings();
      }
    },
    createNewFinisherSettings() {
      this.addFinisherSettingsToPoolProxy()
    },
    addFinisherSettingsToPoolProxy() {
      this.finisherSettingsToCreate = this.tmpFinisherSettingsToCreate;
      this.$http
        .post("proxy/" + this.poolProxyKey + "/addfinisher/" + this.selectedFinisher, this.finisherSettingsToCreate, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-POST-/addressflow/v1/proxy/" + this.poolProxyKey + "/addfinisher/" + this.selectedFinisher
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.$bvModal.hide('create-new-modal');
            this.$emit('added');
          } else {
            if (data.errors) {
              this.finisherSettingsToCreateHasErrors = true;
              this.finisherSettingsToCreateErrors = data.errors;
            } else {
              alert('System error');
            }
          }
        });
    },
  }
};
</script>
