<template>
  <div>
    <div class="container-fluid">
      <div class="float-right">
        <b-button-close @click="$router.replace('/poolproxys')">
          <h2 aria-hidden="true">&times;</h2>
        </b-button-close>
      </div>
      <div class="clearfix"></div>
      <div class="row justify-content-end mb-2">
        <div class="chart" id="data_flow_chart" style="width:1550px; height: 768px"></div>
      </div>
    </div>

    <div class="invisible">
      <b-icon-circle-fill variant="success" id="status_1"></b-icon-circle-fill>
      <b-icon-circle-fill variant="warning" id="status_2"></b-icon-circle-fill>
      <b-icon-circle-fill variant="dark" id="status_3"></b-icon-circle-fill>
      <b-icon-circle-fill variant="danger" id="status_4"></b-icon-circle-fill>
      <b-icon-circle-fill variant="secondary" id="status_0"></b-icon-circle-fill>
    </div>
  </div>
</template>

<script>
import { BIcon, BIconCircleFill } from "bootstrap-vue";
import { Treant } from "treant-js/Treant.js";
import "treant-js/Treant.css";
import css from "../assets/style.css";

export default {
  components: {
    BIcon,
    BIconCircleFill,
  },
  data() {
    return {
      poolProxyKey: this.$route.params.poolProxyKey,
      nodeStructure: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get(`interface/poolproxy/${this.poolProxyKey}/diagram`, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              `-GET-/addressflow/v1/interface/poolproxy/${this.poolProxyKey}/diagram`
            ),
          },
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.nodeStructure = data;
          this.renderDiagram();
        });
    },
    renderDiagram() {
      const simple_chart_config = {
        chart: {
          container: "#data_flow_chart",
          rootOrientation: "WEST",
        },

        nodeStructure: this.nodeStructure,
      };

      new Treant(simple_chart_config);
    },
  },
};
</script>
