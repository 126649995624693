<template>
    <div style="margin: 10px;">
        <b-alert show variant="info">
            Information about the individual exports is only displayed if the extended export logging is activated.
            You can activate the extended export logging via the pool configuration.
        </b-alert>
        <div class="row align-items-center">
            <ul>
                <li>
                    <strong>Key:</strong>
                    {{ pool.key }}
                </li>
                <li>
                    <strong>Name:</strong>
                    {{ pool.name }}
                </li>
                <li>
                    <strong>Export-Regel:</strong>
                    {{ pool.export_rule }}
                </li>
            </ul>
        </div>
        <div class="col-md-12 ">
            <b-form inline>
                <search-bar-exports @search="fetchData"></search-bar-exports>
                <div class="form-group mx-3">
                    <b-dropdown id="dropdown-1" text="Aktion" variant="success">
                        <b-dropdown-item @click="exportData()">Als Excel exportieren</b-dropdown-item>
                    </b-dropdown>
                </div>
                <div v-if="isLoading">
                    <b-spinner label="Loading..."></b-spinner>
                </div>
            </b-form>

        </div>

        <b-table hover :items="exportLogs" :fields="tableFields" custom-foot>
            <template v-slot:cell(id)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(exportedAt)="data">
                {{ data.value | formatDate }}
            </template>
            <template v-slot:cell(statusCode)="data">
                <b-button variant="default" :title="getTitleByStatus(data.value)" size="sm">
                    <b-icon-circle-fill :variant="getVariantByStatus(data.value)"></b-icon-circle-fill>
                </b-button>
            </template>
            <template v-slot:cell(resultStatusCode)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(resultStatusText)="data">
                {{ data.value }}
            </template>
            <template v-slot:cell(payload)="data">
                {{ data.value }}
            </template>
            <template v-slot:custom-foot>
                <tr>
                    <td colspan="11">
                        <strong>Anzahl: {{ totalNumberOfExports }}</strong>
                    </td>
                </tr>
            </template>

        </b-table>
        <export-logs-paginator
            @currentPageWasChanged="fetchData($event)"
            :items="exportLogs"
            :limitPerPage="limitPerPage"
            :totalNumberOfItems="totalNumberOfExports"
            :reset="resetPaginator"
        ></export-logs-paginator>
    </div>
</template>

<script>
import {BIcon, BIconCircleFill} from "bootstrap-vue";
import Paginator from "./Paginator";
import SearchBarExports from "./SearchBarExports";
import FileSaver from "file-saver";

export default {
    components: {
        BIcon,
        BIconCircleFill,
        exportLogsPaginator: Paginator,
        searchBarExports: SearchBarExports
    },
    data() {
        return {
            poolId: null,
            pool: {},
            exportRuleTitle: '',
            tableFields: [
                {
                    key: "id",
                    label: "ID"
                },
                {
                    key: "exportedAt",
                    label: "Datum",
                },
                {
                    key: "statusCode",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "resultStatusCode",
                    label: "Status Code",
                    class: "text-center"
                },
                {
                    key: "resultStatusText",
                    label: "Antwort API"
                },
                {
                    key: "payload",
                    label: "Payload"
                },
            ],
            details: true,
            limitPerPage: 15,
            totalNumberOfExports: 0,
            exportLogs: [],
            resetPaginator: false,
            isLoading: false
        };
    },
    created() {
        this.poolId = this.$route.params.poolId;
        this.fetchPoolData();
        this.fetchData(1);
    },
    methods: {
        updateTable(data) {
            const exportsArray = [];
            for (let i in data.exportLogs) {
                let oneExport = data.exportLogs[i];
                oneExport["statusCode"] = oneExport['resultStatusCode'];
                exportsArray.push(oneExport);
            }
            this.exportLogs = exportsArray;
            this.totalNumberOfExports = data.total;
            this.isLoading = false;
        },
        fetchPoolData() {
            this.$http
                .get("pool/id/" + this.poolId, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/pool/id/" + this.poolId
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.pool = data;
                });
        },
        fetchData(currentPage, resetPaginator) {
            this.isLoading = true;
            if (resetPaginator) {
                this.resetPaginator = resetPaginator;
            }

            let params = {
                limit: this.limitPerPage,
                offset: (currentPage - 1) * this.limitPerPage,
                pool: this.poolId
            };

            let exportFilterDateFrom = this.$store.state.exportFilterDateFrom;
            if (exportFilterDateFrom) {
                params.fromDate = exportFilterDateFrom;
            }
            let exportFilterDateTo = this.$store.state.exportFilterDateTo;
            if (exportFilterDateTo) {
                params.toDate = exportFilterDateTo;
            }

            this.$http
                .get("interface/exportextendedlog", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/exportextendedlog"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.updateTable(data);
                });
        },
        exportData() {
            this.isLoading = true;
            let params = {
                pool: this.poolId
            };

            let exportFilterDateFrom = this.$store.state.exportFilterDateFrom;
            if (exportFilterDateFrom) {
                params.fromDate = exportFilterDateFrom;
            }
            let exportFilterDateTo = this.$store.state.exportFilterDateTo;
            if (exportFilterDateTo) {
                params.toDate = exportFilterDateTo;
            }

            const today = new Date();
            let filename = 'export_extended_logs_' + today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + '.xlsx';
            this.$http
                .get("interface/exportextendedlog/downloadexcel", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/exportextendedlog/downloadexcel"
                        )
                    }
                })
                .then(response => {
                    let blob = this.convertToBlob(response.data);
                    FileSaver.saveAs(blob, filename);
                    this.isLoading = false;
                });
        },
        convertToBlob(b64Data, contentType = "", sliceSize = 512) {
            const byteCharacters = atob(b64Data);
            const byteArrays = [];

            for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                const slice = byteCharacters.slice(offset, offset + sliceSize);

                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }

            const blob = new Blob(byteArrays, {type: contentType});
            return blob;
        },
        getTitleByStatus(status) {
            let title;
            switch (status) {
                case 200:
                    title = 'Der Export war erfolgreich.';
                    break;
                default:
                    title = 'Beim Export der Daten ist ein Fehler aufgetreten.';
            }

            return title;
        },
        getVariantByStatus(status) {
            let variant;
            switch (status) {
                case 200:
                    variant = 'success';
                    break;
                default:
                    variant = 'danger';
            }

            return variant;
        }
    }
}
</script>

<style>
/* override .small so the b-calendar layout does not break */
.small {
    margin: auto;
}

/* fix for inline display of the b-calendar */
.b-calendar .form-control {
    width: 100%;
}
</style>
