'use strict';

export function testForStrongPassword(password) {
    let strong_password;
    let special_chars_class = "\\^$*.\\[\\]{}()?\\-\"!@#%&\\/\\\\,><':;|_~`+="
    if (process.env.NODE_ENV != 'production') {
        strong_password = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
        );
    } else {
        strong_password = new RegExp(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*["+special_chars_class+"])[A-Za-z\\d"+special_chars_class+"]{8,}$"
        );
    }

    return strong_password.test(password); //Test the regex. Test function returns a true or false value.
}