<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/finisher')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center">
      <ul>
        <li>
          <strong>Name:</strong>
          {{ finisher.name }}
        </li>
      </ul>
    </div>
    <div class="row">
      <v-jsoneditor v-model="finisher" :plus="false" height="500px" @error="onError" :options="{mode: 'code'}"></v-jsoneditor>
    </div>
    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >Die Finisher-Einstellungen wurden erfolgreich gespeichert.</b-alert>
      </div>
    </div>
    <div class="row my-3">
      <b-button variant="primary" @click="save()" :disabled="saveButtonDisabled">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  data() {
    return {
      finisherId: null,
      finisher: {},
      alertDismissCountDown: 0,
      saveButtonDisabled: false
    };
  },
  components: {
    VJsoneditor
  },
  mounted() {
    window.addEventListener("keydown", this.keyboardSave);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyboardSave);
  },
  created() {
    this.finisherId = this.$route.params.finisherId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get("form/finisher/update/" + this.finisherId, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/finisher/update/" + this.finisherId
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.finisher = data.initialValues;
        });
    },
    onError() {
      console.log("error");
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.save();
      }
    },
    save() {
      this.saveButtonDisabled = true;
      this.$http
        .put("finisher/" + this.finisherId, this.finisher, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-PUT-/addressflow/v1/finisher/" + this.finisherId
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.alertDismissCountDown = 2;
          }
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>

