<template>
  <div class="container-fluid">
    <div class="my-3">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Erstellen</b-button>
    </div>

    <b-table hover :items="users" :fields="tableFields">
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="Neuen Benutzer anlegen"
      hide-footer
      cancel-title="Abbrechen"
      ok-title="Neuen Benutzer anlegen"
      @ok="createNewUser"
      @close="showForm=true; saveResult=''; fetchData()"
      @hidden="showForm=true; saveResult=''; fetchData()"
    >
      <div v-show="showForm">
        <div class="form-group">
          <label>Name:</label>
          <b-form-input v-model="newName" autofocus></b-form-input>
        </div>
        <div class="form-group" v-show="showPasswordField">
          <label>Passwort:</label>
          <b-form-input v-model="newPassword"></b-form-input>
        </div>
        <div class="form-group">
          <label>Rolle:</label>
          <b-form-select v-model="newRole" :options="['ROLE_ADMIN', 'ROLE_USER', 'ROLE_STAT']"></b-form-select>
        </div>
        <div class="form-group">
          <label>Mandant:</label>
          <b-form-select v-model="selectedParty" :options="parties"></b-form-select>
        </div>

        <b-button variant="primary" @click="createNewUser">Neuen Benutzer anlegen</b-button>
        <b-button @click="$bvModal.hide('create-new-modal')">Abbrechen</b-button>

        <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger"><pre>{{ createErrorMsg }}</pre></b-alert>
      </div>

      <div v-if="!showForm">
        <b-alert class="mt-2" style="display:inline-block; word-wrap:break-word;width:100%" v-model="saveResult" variant="info">{{ saveResult }}</b-alert>
        <b-button class="mt-3" @click="$bvModal.hide('create-new-modal')">Ok</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableFields: [
        {
          "key": "username",
          "label": "Name"
        },
        {
          "key": "party",
          "label": "Mandant"
        }
      ],
      users: [],
      parties: [],
      newName: "",
      newRole: "",
      newPassword: "",
      showPasswordField: false,
      selectedParty: "",
      showCreateErrorMsg: false,
      createErrorMsg: "",
      showForm: true,
      saveResult: ''
    };
  },
  watch: {
    newRole(val) {
      this.newPassword = '';
      this.showPasswordField = false;
      if (val !== 'ROLE_ADMIN') {
        this.showPasswordField = true;
      }
    }
  },
  created() {
    this.fetchData(1);
    this.fetchParties();
  },
  methods: {
    updateTable(data) {
      const usersArray = [];
      if (data !== null) {
          for (let i in data.users) {
              let user = data.users[i];
              user['edit'] = data.users[i].username;
              usersArray.push(user);
          }
      }

      this.users = usersArray;
    },
    fetchData(currentPage, resetPaginator) {
      this.$http
        .get("admin/user", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/admin/user"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updateTable(data);
        });
    },
    fetchParties() {
      this.$http
        .get("admin/party", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/admin/party"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          const partiesArray = [];
          if (data !== null) {
              for (let i in data.parties) {
                  let option = {
                      value: data.parties[i].name,
                      text: data.parties[i].name
                  };
                  partiesArray.push(option)
              }
          }
          this.parties = partiesArray;
        });
    },
    createNewUser(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.showCreateErrorMsg = false;

      if (this.newName.length < 3) {
        this.showCreateErrorMsg = true;
        this.createErrorMsg = "Mindestens 3 Zeichen eingeben.";
        return false;
      }
      if (this.newRole.length === 0 || this.selectedParty.length === 0) {
        this.showCreateErrorMsg = true;
        this.createErrorMsg = "Bitte gib eine Rolle und einen Mandanten an.";
        return false;
      }
      if (this.newRole !== 'ROLE_ADMIN' && this.newPassword.length !== 8) {
        this.showCreateErrorMsg = true;
        this.createErrorMsg = "Bitte gib ein Passwort (8 Zeichen) an.";
        return false;
      }

      let params = {
        username: this.newName,
        party: this.selectedParty,
        roles: [this.newRole]
      }
      if (this.newPassword.length) {
        params.password = this.newPassword;
      }

      this.$http
        .post(
          "admin/user",
          params,
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/admin/user"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.showForm = false;
            if (data.hmac_private_key) {
              this.saveResult = 'Hmac Key: ' + data.hmac_private_key;
            } else {
              this.$bvModal.hide('create-new-modal')
            }

          } else {
            this.showCreateErrorMsg = true;
            if (data.errors) {
              this.createErrorMsg = JSON.stringify(data.errors, undefined, 2);
            }
          }
        });
    }
  }
};
</script>

