<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/exportrules')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>Schema-Definition erstellen für {{ exportRuleTitle }}</h1>
    </div>
    <div class="row">
      <v-jsoneditor v-model="schemaDefinition" :plus="false" height="500px" :options="{mode: 'code'}"></v-jsoneditor>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; $router.replace('/exportrules')"
          @dismiss-count-down="countDownChanged"
        >{{ schemaDefinitionSuccessMessage }}</b-alert>

        <b-alert
          variant="danger"
          :show="saveError"
          fade
          dismissible
          @dismissed="saveButtonDisabled=false"
        >
          Fehler beim Speichern:
          <br />
          {{ schemaDefinitionErrorMessage }}
        </b-alert>
      </div>
    </div>

    <div class="row my-3">
      <b-button
        variant="primary"
        @click="createSchemaDefinition()"
        :disabled="saveButtonDisabled"
      >Speichern</b-button>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  data() {
    return {
      exportRuleId: null,
      exportRuleTitle: "",
      schemaDefinition: {},
      schemaDefinitionSuccessMessage: "",
      schemaDefinitionErrorMessage: "",
      saveButtonDisabled: false,
      alertDismissCountDown: 0,
      saveError: false
    };
  },
  components: {
    VJsoneditor
  },
  mounted() {
    window.addEventListener("keydown", this.keyboardSave);
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyboardSave);
  },
  created() {
    this.exportRuleId = Number(this.$route.params.exportRuleId);
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get(
          "form/export_rule/create/" + this.exportRuleId + "/schema_definition",
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/form/export_rule/create/" +
                  this.exportRuleId +
                  "/schema_definition"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.schemaDefinition = data.initialValues;
          this.exportRuleTitle = data.exportRuleTitle;
        });
    },
    createSchemaDefinition() {
      this.saveError = false;
      this.saveButtonDisabled = true;

      this.$http
        .post(
          "export_rule/" + this.exportRuleTitle + "/schema",
          this.schemaDefinition,
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/export_rule/" + this.exportRuleTitle + "/schema"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(
          data => {
            if (data.success === true) {
              this.schemaDefinitionSuccessMessage =
                "Schema-Definition erfolgreich gespeichert.";
              this.alertDismissCountDown = 2;
            } else {
              this.saveError = true;
              if (data.message) {
                this.schemaDefinitionErrorMessage = data.message;
              }
              this.saveButtonDisabled = false;
            }
          },
          response => this.onError(response.body)
        );
    },
    onError(result) {
      this.saveError = true;
      this.schemaDefinitionErrorMessage = 'Unerwarteter Fehler';
      this.saveButtonDisabled = false;
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.createSchemaDefinition();
      }
    },
  }
};
</script>

