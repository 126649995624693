<template>
    <div>
        <div class="row my-4 filters border-danger">
            <div class="col-md-12">
                <form class="form-inline float-left">
                    <div class="mx-3">
                        <button class="btn btn-dark" @click="resetFilter">Filter löschen</button>
                    </div>

                    <div class="form-group mx-3">
                        <label class="pr-2" for="dateFrom">Eingangsdatum der Leads von</label>
                        <b-form-datepicker id="dateFrom" v-model="filter.dateFrom" @input="changeStartDate"></b-form-datepicker>
                    </div>

                    <div class="form-group mx-3">
                        <label class="pr-2" for="dateTo">bis</label>
                        <b-form-datepicker id="dateTo" v-model="filter.dateTo" @input="changeEndDate"></b-form-datepicker>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            filter: {
                dateFrom: this.$store.state.exportFilterDateFrom,
                dateTo: this.$store.state.exportFilterDateTo,
            }
        }
    },
    methods: {
        changeStartDate() {
            this.$store.commit('setLogExportsFilterDateFrom', this.filter.dateFrom);
            this.$emit('search', 1, true);
        },
        changeEndDate() {
            this.$store.commit('setLogExportsFilterDateTo', this.filter.dateTo);
            this.$emit('search', 1, true);
        },
        resetFilter() {
            this.$store.dispatch('reset');

            this.filter.dateFrom = this.$store.state.exportFilterDateFrom;
            this.filter.dateTo = this.$store.state.exportFilterDateTo;

            this.$emit('search', 1, true);
        },
    }
}
</script>
