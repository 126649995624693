<template>
  <div class="container">
    <h1>Statistik</h1>
    <button
      @click="downloadList"
      class="btn btn-primary btn-dark"
    >Liste aller aktiven Daten-Pools herunterladen</button>
    <br />
    <br />

    <hr />
    <br />
    <h2>Daten-Pool finden (Export-Dateinamen eingeben)</h2>
    <form class="form-inline">
      <input type="text" name="searchValue" v-model="searchValue" />&nbsp;&nbsp;
      <button @click.prevent="findPool" class="btn btn-dark">Suche</button>
    </form>

    <table v-show="exportedFiles.length > 0" class="table table-striped">
      <thead>
        <th>Daten-Pool-Id</th>
        <th>Daten-Pool-Key</th>
        <th>Pool Name</th>
        <th>Anzahl Datensätze</th>
        <th>Dateiname</th>
      </thead>
      <tbody>
        <tr v-for="exportedFile in exportedFiles">
          <td>{{ exportedFile.poolId }}</td>
          <td>{{ exportedFile.poolKey }}</td>
          <td>{{ exportedFile.poolName }}</td>
          <td>{{ exportedFile.numberOfDates }}</td>
          <td>{{ exportedFile.filename }}</td>
        </tr>
      </tbody>
    </table>

    <div
      class="alert alert-dark"
      role="alert"
      v-show="exportedFiles.length >= 10"
    >Mehr als 10 Ergebnisse gefunden! Bitte konkretisieren Sie Ihre Suchanfrage.</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchValue: "",
      exportedFiles: []
    };
  },
  methods: {
    downloadList() {
      this.$http
        .get("interface/pool/downloadActivePools", {
          responseType: "arraybuffer",
          headers: {
              'Authorization': this.createHmacHash(this.$store.state.user.adfAdminUser, '-GET-/addressflow/v1/interface/pool/downloadActivePools')
          }
        })
        .then(response => {
          let blob = new Blob([response.body], {
            type: response.headers.get("content-type")
          });
          const data = window.URL.createObjectURL(blob)
          let link = document.createElement("a");
          link.href = data;
          let today = new Date();
          link.download = "aktive_pools_" + today.getFullYear() + '_' + (today.getMonth()+1) + '_' + today.getDate() + ".csv";
          document.body.appendChild(link);
          link.click();
        });
    },
    findPool() {
      if (this.searchValue === "") {
        this.exportedFiles = [];
        return;
      }

      if (this.searchValue.length < 5) {
        alert("Bitte mindestest 5 Zeichen eingeben.");
        return;
      }

      this.$http
        .get("interface/pool/findByExportFilename", {
          params: { filename: this.searchValue },
          headers: {
              'Authorization': this.createHmacHash(this.$store.state.user.adfAdminUser, '-GET-/addressflow/v1/interface/pool/findByExportFilename')
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.exportedFiles = data.exportedFiles;
        });
    }
  }
};
</script>

