<template>
  <div class="container-fluid">
    <div class="my-4">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Erstellen</b-button>
    </div>

    <b-table hover :items="finisher" :fields="tableFields">
      <template v-slot:cell(name)="data">
        <router-link :to="{ name: 'edit_finisher', params: { finisherId: data.item.id }}">
          <a>{{ data.item.name }}</a>
        </router-link>
      </template>
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="Titel eingeben"
      cancel-title="Abbrechen"
      ok-title="Neuen Finisher erstellen"
      @ok="createNewFinisher"
    >
      <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">{{ createErrorMsg }}</b-alert>

      <div class="form-group">
        <label>Finisher-Typ:
          <b-form-select v-model="newFinisherType" :options="finisherTypes"></b-form-select>
        </label>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableFields: [
        {
          key: "id",
          label: "ID"
        },
        {
          key: "name",
          label: "Name"
        }
      ],
      finisher: [],
      newFinisherType: 'Brandsuite\\Addressbox\\Entity\\Finisher\\HttpCallFinisher',
      finisherTypes: {
        'Brandsuite\\Addressbox\\Entity\\Finisher\\HttpCallFinisher': 'Http-Call-Finisher',
        'Brandsuite\\Addressbox\\Entity\\Finisher\\SqsFinisher': 'SQS-Finisher'
      },
      showCreateErrorMsg: false,
      createErrorMsg: ""
    };
  },
  created() {
    this.fetchData(1);
  },
  methods: {
    updateTable(data) {
      const finisherArray = [];
      for (let i in data.finishers) {
        let finisher = data.finishers[i];

        finisher["id"] = finisher.id;
        finisher["name"] = finisher.name;

        finisherArray.push(finisher);
      }
      this.finisher = finisherArray;
    },
    fetchData(currentPage, resetPaginator, showDetailedInfo) {
      if (resetPaginator) {
        this.resetPaginator = resetPaginator;
      }

      this.$http
        .get("finisher", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/finisher"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.updateTable(data);
        });
    },
    createNewFinisher(bvModalEvt) {
      this.$router.push({ name: 'create_finisher', query: { finisher: this.newFinisherType } });
    }
  }
};
</script>

