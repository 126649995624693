<template>
  <div class="container-fluid">
    <div class="my-3">
      <b-button variant="primary" @click="$bvModal.show('create-new-modal')"
            >Erstellen
            </b-button>
    </div>

    <b-table hover :items="parties" :fields="tableFields">
      <template v-slot:cell(edit)="data">
        <b-button-group>
          <router-link
            :to="{ name: 'uiparty_edit', params: { tenantId: data.value.id } }"
          >
            <b-icon-pencil font-scale="1.5"></b-icon-pencil>
          </router-link>
          <a href="#" @click.prevent="showDeleteModal(data.value)">
                        <b-icon-trash font-scale="1.5"></b-icon-trash>
          </a>
        </b-button-group>
      </template>
    </b-table>

    <b-modal
      id="create-new-modal"
      ref="create-new-modal"
      title="Mandant erstellen"
      cancel-title="Abbrechen"
      ok-title="Neuen Mandanten anlegen"
      @ok.prevent="submitForm"
      @close="
        showForm = true;
        saveResult = '';
      "
      @hidden="
        showForm = true;
        saveResult = '';
      "
    >
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="ok()" :disabled="createLoading">
          <b-spinner small v-if="createLoading"></b-spinner>
          Neuen Mandanten anlegen
        </b-button>
        <b-button variant="light" @click="cancel()"> Abbrechen </b-button>
      </template>

      <div v-show="showForm">
        <validation-observer ref="form" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(submitForm)"
            class="justify-content-center"
          >
            <validation-provider
              name="Name"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group
                id="companyName-group-1"
                label="Name"
                label-for="companyName"
              >
                <b-form-input
                  id="companyName"
                  name="companyName"
                  placeholder="Name"
                  v-model="form.companyName"
                  :state="getValidationState(validationContext)"
                  aria-describedby="company-name-live-feedback"
                  type="text"
                  autofocus
                ></b-form-input>

                <b-form-invalid-feedback id="company-name-live-feedback">{{
                  validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Vorname"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group
                id="firstName-group-1"
                label="Vorname"
                label-for="firstName"
              >
                <b-form-input
                  id="firstName"
                  name="firstName"
                  placeholder="Vorname"
                  v-model="form.firstName"
                  :state="getValidationState(validationContext)"
                  aria-describedby="first-name-live-feedback"
                  type="text"
                ></b-form-input>

                <b-form-invalid-feedback id="first-name-live-feedback">{{
                  validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="Nachname"
              :rules="{ required: true }"
              v-slot="validationContext"
            >
              <b-form-group
                id="lastName-group-1"
                label="Nachname"
                label-for="lastName"
              >
                <b-form-input
                  id="lastName"
                  name="lastName"
                  placeholder="Nachname"
                  v-model="form.lastName"
                  :state="getValidationState(validationContext)"
                  aria-describedby="last-name-live-feedback"
                  type="text"
                ></b-form-input>

                <b-form-invalid-feedback id="last-name-live-feedback">{{
                  validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              name="E-Mail"
              :rules="{ required: true, email: true }"
              v-slot="validationContext"
            >
              <b-form-group id="email-group-1" label="E-Mail" label-for="email">
                <b-form-input
                  id="email"
                  name="email"
                  placeholder="E-Mail"
                  v-model="form.email"
                  :state="getValidationState(validationContext)"
                  aria-describedby="email-live-feedback"
                  type="email"
                ></b-form-input>

                <b-form-invalid-feedback id="email-live-feedback">{{
                  validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="AddressFlow User"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group id="adf-user-group-1" label="AddressFlow User" label-for="adf_user">
                                <b-form-select
                                    id="adf_user"
                                    v-model="form.adfUser"
                                    :options="[
                    { text: 'Bitte wählen...', value: '' }, ...adfUsers,
                  ]"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="adfuser-live-feedback"
                                ></b-form-select>

                                <b-form-invalid-feedback id="adfuser-live-feedback">{{
                                        validationContext.errors[0]
                                    }}
                                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <div class="my-3">
              <div class="col">
                <b-alert
                  variant="danger"
                  :show="showCreateErrorMsg"
                                >{{ createErrorMsg }}
                                </b-alert
                >
              </div>
            </div>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BIcon, BIconPencil, BIconTrash } from "bootstrap-vue";
import {fetchAdfUser} from "../../helpers/api";

export default {
  components: {
    BIcon,
    BIconPencil,
    BIconTrash,
  },
  data() {
    return {
      tableFields: [
        {
          key: "name",
          label: "Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "edit",
          label: "",
        },
      ],
      parties: [],
            adfUsers: [],
      form: {
        firstName: "",
        lastName: "",
        email: "",
        companyName: "",
        tier: "",
        adfUser: ""
      },
      showCreateErrorMsg: false,
      createLoading: false,
      createErrorMsg: "",
      showForm: true,
      saveResult: "",
    };
  },
  created() {
    this.fetchData(1);
        this.fetchAdfUsers();
  },
  methods: {
    updateTable(data) {
      let partiesArray = [];
      for (let i in data) {
        let party = {
          name: data[i].companyName,
          edit: { id: data[i].id, name: data[i].companyName },
          status: data[i].status === "Active" ? "Aktiv" : "Inaktiv",
        };
        partiesArray.push(party);
      }

      this.parties = partiesArray;
    },
    fetchData(currentPage, resetPaginator) {
      if (resetPaginator) {
        this.resetPaginator = resetPaginator;
      }

      this.$http
        .get(this.$authUrl + "tenants", {
          headers: {
            Authorization: "Bearer " + this.$store.state.bearerToken.value,
          },
        })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.updateTable(data);
        });
    },
        fetchAdfUsers() {
            this.$http
                .get("admin/user", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/admin/user"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    for (let i in data.users) {
                        this.adfUsers.push({'text': data.users[i].username, 'value': data.users[i].id});
                    }
                });
        },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    submitForm() {
        fetchAdfUser(this.form.adfUser, this.$store.state.user, this.createNewParty);
    },
    createNewParty(adfUser) {
      this.showCreateErrorMsg = false;

      this.$refs.form.validate().then((success) => {
        if (!success) {
          return;
        }

        this.createLoading = true;
        var tenant = {
          id: "",
          companyName: this.form.companyName,
          accountName: this.form.companyName,
          ownerName: this.form.email,
          tier: "Free Tier",
          email: this.form.email,
          userName: this.form.email,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          adf_admin_user: adfUser.username,
          adf_admin_user_auth: adfUser.hmac_private_key
        };

        this.$http
          .post(this.$authUrl + "reg", tenant, {
              headers: {
                  Authorization: "Bearer " + this.$store.state.bearerToken.value,
              },
          })
          .then((response) => {
            this.createLoading = false;
            this.form = {
              firstName: "",
              lastName: "",
              email: "",
              companyName: "",
              tier: "",
            };

            this.fetchData();
            this.$bvModal.hide("create-new-modal");
          })
          .catch((response) => {
            this.createLoading = false;
            this.showCreateErrorMsg = true;
            console.log(response);
            this.createErrorMsg = response.message
              ? response.message
              : "Mandant existiert bereits";
          });
      });
    },
    showDeleteModal(tenant) {
      this.$bvModal
        .msgBoxConfirm(
          `Möchtest du den Mandanten "${tenant.name}" wirklich löschen?`,
          {
            cancelTitle: "Abbrechen",
          }
        )
        .then((value) => {
          if (value) {
            this.$http
              .delete(this.$authUrl + "tenant/" + tenant.id, {
                headers: {
                  Authorization: "Bearer " + this.$store.state.bearerToken.value,
                },
              })
              .then((response) => {
                this.fetchData();
              })
              .catch((response) => {
                alert(
                  "Mandant konnte nicht gelöscht werden.\n\n" + response.message
                );
              });
          }
        });
    },
  },
};
</script>

