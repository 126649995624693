<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/poolproxys')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row mt-3"></div>

    <b-tabs content-class="mt-3">
      <b-tab title="Finisher" active>
        <finisher :pool-proxy-key="poolProxyKey"></finisher>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";
import Finisher from "./Finisher.vue";

export default {
  data() {
    return {
      poolProxyKey: null
    };
  },
  components: {
    VJsoneditor,
    Finisher
  },
  created() {
    this.poolProxyKey = this.$route.params.poolProxyKey;
  }
};
</script>
