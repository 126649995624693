<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/exportrules')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>Export-Definition für {{ exportRuleTitle }}</h1>
    </div>
    <workflow
      :options="workflowOptions"
      :objectState.sync="exportDefinitionState"
      :objectContent.sync="exportDefinition"
      :objectId.sync="exportDefinitionId"
      :initialFetchData="fetchData"
      :exportRuleId="exportRuleId"
      :allowEdit="allowEdit"
    ></workflow>
    <div class="row">
      <pre v-show="exportDefinitionState !== 'edited'">{{ JSON.stringify(exportDefinition, undefined, 2) }}</pre>
      <v-jsoneditor
        v-show="exportDefinitionState === 'edited'"
        v-model="exportDefinition"
        :plus="false"
        height="500px"
        @error="onError"
        :options="{mode: 'code'}"
      ></v-jsoneditor>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";
import Workflow from "../Workflow.vue";

export default {
  data() {
    return {
      exportDefinitionState: "start",
      exportRuleId: null,
      exportRuleTitle: "",
      exportDefinitionId: null,
      exportDefinition: {},
      allowEdit: false,
      workflowOptions: {
        context: "export_definition",
        alertMessage: "Die Export-Definition wurde erfolgreich gespeichert.",
        hasTestExport: true
      }
    };
  },
  components: {
    VJsoneditor,
    workflow: Workflow
  },
  created() {
    this.exportRuleId = Number(this.$route.params.exportRuleId);
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get(
          "form/export_rule/update/" + this.exportRuleId + "/export_definition",
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-GET-/addressflow/v1/form/export_rule/update/" +
                  this.exportRuleId +
                  "/export_definition"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.exportDefinitionId = data.exportDefinitionId;
          this.exportDefinition = data.initialValues;
          this.exportRuleTitle = data.exportRuleTitle;
          this.allowEdit = data.allowEdit;
        });
    },
    onError() {
      console.log('error');
    }
  }
};
</script>

