<template>
  <div>
    <div class="row my-3">
      <b-button variant="info" v-b-modal.default_export_schedule>Standard Export-Zeiten anzeigen</b-button>
      <b-link class="btn ml-2" variant="outline-info" @click="openCronTabGuru()">Cron Guru öffnen</b-link>
    </div>
    <div class="row">
      <b-form-checkbox
        id="customExportSchedule"
        v-model="customExportSchedule"
        @change="overrideExportSchedule"
      >Standard-Exportzeiten überschreiben</b-form-checkbox>
    </div>
    <div v-if="customExportSchedule">
      <div class="row">
        <v-jsoneditor
          v-model="exportSchedule"
          :plus="false"
          height="220px"
          @error="onError"
        ></v-jsoneditor>
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >{{ exportScheduleSuccessMessage }}</b-alert>
      </div>
    </div>

    <div class="row my-3">
      <b-button
        variant="primary"
        @click="saveExportSchedule()"
        :disabled="saveButtonDisabled"
      >Speichern</b-button>
    </div>

    <b-modal id="default_export_schedule" title="Standard Export-Zeiten">
      <pre id="export_schedule_json">{{ JSON.stringify(scheduleFromExportRule, undefined, 2) }}</pre>
    </b-modal>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  props: ["poolKey"],
  data() {
    return {
      exportSchedule: null,
      scheduleFromExportRule: null,
      customExportSchedule: false,
      alertDismissCountDown: 0,
      saveButtonDisabled: false,
      exportScheduleSuccessMessage: "",
      cronTabGuruHash: ""
    };
  },
  components: {
    VJsoneditor
  },
  created() {
    this.fetchExportSchedule();
  },
  watch: {
    exportSchedule(val) {
      this.cronTabGuruHash = val
        ? this.createCronTabGuruHash(val.cron_expression)
        : "";
    }
  },
  methods: {
    fetchExportSchedule() {
      this.$http
        .get("form/pool/exportschedule/" + this.poolKey, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/pool/exportschedule/" + this.poolKey
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (!data.success) {
            alert("error");
          }

          this.scheduleFromExportRule = data.exportRuleExportSchedule;

          if (data.exportSchedule !== null) {
            this.exportSchedule = data.exportSchedule;
            this.customExportSchedule = true;
          }
        });
    },
    onError() {
      console.log("error");
    },
    overrideExportSchedule(checked) {
      if (true === checked) {
        if (null === this.exportSchedule) {
          this.exportSchedule = this.scheduleFromExportRule;
        }
      } else {
        this.customExportSchedule = false;
      }
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.saveExportSchedule();
      }
    },
    saveExportSchedule() {
      this.saveButtonDisabled = true;

      if (true === this.customExportSchedule) {
        this.createOrUpdateExportSchedule();
      } else {
        this.removeExportScheduleFromPool();
      }
    },
    createOrUpdateExportSchedule() {
      this.$http
        .post("pool/" + this.poolKey + "/exportschedule", this.exportSchedule, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-POST-/addressflow/v1/pool/" + this.poolKey + "/exportschedule"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportScheduleSuccessMessage =
              "Export-Zeiten erfolgreich gespeichert.";
            this.alertDismissCountDown = 2;
          }
        });
    },
    removeExportScheduleFromPool() {
      this.$http
        .delete("pool/" + this.poolKey + "/exportschedule", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-DELETE-/addressflow/v1/pool/" + this.poolKey + "/exportschedule"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportScheduleSuccessMessage =
              "Export-Zeiten auf Standard zurück gesetzt.";
            this.alertDismissCountDown = 2;
          }
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    },
    createCronTabGuruHash(cronExp) {
      return (
        "#" +
        cronExp.minutes +
        "_" +
        cronExp.hours +
        "_" +
        cronExp.days +
        "_" +
        cronExp.months +
        "_" +
        cronExp.weekdays
      );
    },
    openCronTabGuru() {
      window.open("https://crontab.guru/" + this.cronTabGuruHash, "_blank");
    }
  }
};
</script>
