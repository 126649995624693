<template>
    <div>
        <div class="row my-4 filters">
            <div class="col-md-12">
                <form class="form-inline float-left" v-if="showFilter">

                    <div class="mx-3">
                        <button class="btn btn-dark" @click="resetFilter">Filter löschen</button>
                    </div>

                    <div class="form-group form-check mx-3" v-show="false">
                        <input type="checkbox" class="form-check-input" id="show_info" @change="changeShowInfo" :checked="showInfo">
                        <label class="form-check-label" for="show_info">Infos einblenden</label>
                    </div>


                    <div class="form-group mx-3">
                        <label class="pr-2" for="filter_status">Status:</label>
                        <select class="form-control" id="filter_status" v-model="filter.status.value" @change="changeStatusFilter">
                            <option
                                v-for="(name, value) in filter.status.options"
                                :value="value"
                                >{{ name }}</option>
                        </select>
                    </div>

                    <div class="form-group mx-3">
                        <label class="pr-2" for="filter_export_rule">Export-Regel:</label>
                        <select class="form-control" id="filter_export_rule" v-model="filter.exportRule.value" @change="changeExportRuleFilter">
                            <option value="" :selected="filter.exportRule.value == ''">Alle</option>
                            <option
                                v-for="(name, value) in filter.exportRule.options"
                                :value="value"
                                >{{ name }}</option>
                        </select>
                    </div>
                </form>
                <form class="form-inline float-right">
                    <div class="form-group mx-3">
                        <input
                            type="text"
                            class="form-control"
                            placeholder="Suche..."
                            v-model="searchValue"
                        >
                        <button @click.prevent="submit" class="btn btn-primary btn-dark">Suche</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
            showInfo: Boolean,
            showFilter: Boolean
    },
    data () {
        return {
            searchValue: this.$store.state.searchValue,
            filter: {
                status: {
                    value: this.$store.state.status,
                    options: {
                        0: 'inaktiv',
                        1: 'online',
                        2: 'abgelaufen',
                        3: 'offline',
                        4: 'gelöscht',
                        '': 'Alle'
                    }
                },
                exportRule: {
                    value: this.$store.state.exportRule,
                    options: []
                }
            }
        }
    },
    created () {
      if (this.showFilter) {
        this.fetchExportRules();
      }
    },
    methods: {
        submit() {
            this.$store.commit('setSearchValue', this.searchValue);
            this.$emit('search', 1, true);
        },
        fetchExportRules() {
            this.$http.get('export_rule', {
                    headers: {
                        'Authorization': this.createHmacHash(this.$store.state.user.adfAdminUser, '-GET-/addressflow/v1/export_rule')
                    }
                })
                .then(response => {
                    return response.json()
                })
                .then(data => {
                    this.filter.exportRule.options = data;
                });
        },
        changeShowInfo($event) {
            this.$emit('search', this.$store.state.currentPage, false, $event.target.checked);
        },
        changeStatusFilter() {
            this.$store.commit('setStatusFilter', this.filter.status.value);
            this.$emit('search', 1, true);
        },
        changeExportRuleFilter() {
            this.$store.commit('setExportRuleFilter', this.filter.exportRule.value);
            this.$emit('search', 1, true);
        },
        resetFilter() {
            this.$store.dispatch('reset');

            this.searchValue = '';
            this.filter.status.value = this.$store.state.status;
            this.filter.exportRule.value = this.$store.state.exportRule;

            this.$emit('search', 1, true);
        },
    }
}
</script>
