<template>
    <div>
        <div>Logged out successfully</div>
    </div>
</template>

<script>
    export default {
        created() {
            setTimeout(() => {
                this.$router.push('/login');
            }, 3000)
        }
    }
</script>
