<template>
  <div>
    <div class="row my-3">
      <div class="col-12">
        <b-button variant="primary" @click="$bvModal.show('create-new-modal')">Finisher hinzufügen</b-button>
      </div>
    </div>

    <b-table hover :items="finisherSettings" :fields="tableFields" show-empty>
      <template v-slot:empty>
        <p>Keiner Finisher vorhanden</p>
      </template>

      <template v-slot:cell(finisher_name)="data">
          {{ data.item.finisher_name }}
      </template>

      <template v-slot:cell(parent)="data">
        <span v-if="data.item.chain_parent">{{data.item.chain_parent.id}}</span>
      </template>

      <template v-slot:cell(edit)="data">
        <b-button-group>
          <b-button
            variant="link"
            @click="showEditModalFunc(data.value)"
          >
            <b-icon-pencil font-scale="1.5"></b-icon-pencil>
          </b-button>
          <b-button variant="link" @click="showDeleteModal(data.value)">
            <b-icon-trash font-scale="1.5"></b-icon-trash>
          </b-button>
        </b-button-group>
      </template>
    </b-table>

    <add-finisher-settings-modal
      :pool-proxy-key="poolProxyKey"
      :all-finisher-settings="finisherSettings"
      @added="fetchFinishers"
    ></add-finisher-settings-modal>

    <edit-finisher-settings-modal
      :finisher-settings-id="finisherSettingsIdToEdit"
      :showMe="isEditModalVisible"
      :pool-proxy-key="poolProxyKey"
      :all-finisher-settings="finisherSettings"
      @updated="fetchFinishers"
      @close="isEditModalVisible=false"
      ></edit-finisher-settings-modal>
  </div>
</template>

<script>
import { BIcon, BIconPencil, BIconTrash } from "bootstrap-vue";
import EditFinisherSettingsModal from "./EditFinisherSettingsModal.vue";
import AddFinisherSettingsModal from "./AddFinisherSettingsModal.vue";

export default {
  props: ["poolProxyKey"],
  data() {
    return {
      tableFields: [
        "id",
        {
          key: "finisher_name",
          label: "Finisher-Name"
        },
        "parent",
        {
          key: "edit",
          label: ""
        }
      ],
      finisherSettings: [],
      finisherSettingsIdToEdit: 0,
      isEditModalVisible: false
    };
  },
  components: {
    BIcon,
    BIconPencil,
    BIconTrash,
    EditFinisherSettingsModal,
    AddFinisherSettingsModal
  },
  created() {
    this.fetchFinishers();
  },
  methods: {
    fetchFinishers() {
      this.$http
        .get("proxy/" + this.poolProxyKey + "/finishers", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/proxy/" + this.poolProxyKey + "/finishers"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          let finisherSettingsArray = [];
          for (let i in data) {
            let finisherSettings = data[i];
            finisherSettings["edit"] = finisherSettings["id"];
            finisherSettingsArray.push(finisherSettings);
          }

          this.finisherSettings = finisherSettingsArray;
        });
    },

    showEditModalFunc(id) {
      this.finisherSettingsIdToEdit = id;
      this.isEditModalVisible = true;
    },

    showDeleteModal(id) {
      this.$bvModal
        .msgBoxConfirm("Möchtest du den Finisher wirklich löschen?", {
          cancelTitle: "Abbrechen"
        })
        .then(value => {
          if (value) {
            this.$http
              .delete(
                "proxy/" + this.poolProxyKey + "/removefinishersettings/" + id,
                {
                  headers: {
                    Authorization: this.createHmacHash(
                      this.$store.state.user.adfAdminUser,
                      "-DELETE-/addressflow/v1/proxy/" + this.poolProxyKey + "/removefinishersettings/" + id
                    )
                  }
                }
              )
              .then(response => {
                return response.json();
              })
              .then(data => {
                if (data.success === true) {
                  this.fetchFinishers();
                } else {
                  alert('Finisher konnte nicht gelöscht werden.');
                }
              });
          }
        });
    },

    removeExportDefinitionFromPool() {
      this.$http
        .delete("pool/" + this.poolKey + "/exportdefinition", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-DELETE-/addressflow/v1/pool/" + this.poolKey + "/exportdefinition"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportDefinitionSuccessMessage =
              "Export-Konfiguration auf Standard zurück gesetzt.";
            this.alertDismissCountDown = 2;
          }
        });
    }
  }
};
</script>
