<template>
    <div class="container-fluid">
        <div class="my-3">
            <b-button variant="primary" @click="$bvModal.show('create-new-modal')"
            >Erstellen</b-button
            >
        </div>

        <b-table hover :items="users" :fields="tableFields">
            <template v-slot:cell(dateCreated)="data">{{
                    data.item.dateCreated | formatDate
                }}</template>

            <template v-slot:cell(edit)="data">
                <b-button-group>
                    <router-link
                        :to="{ name: 'uiuser_edit', params: { userName: data.value } }"
                    >
                        <b-icon-pencil font-scale="1.5"></b-icon-pencil>
                    </router-link>
                    <a href="#" @click="showDeleteModal(data.value)"
                    ><b-icon-trash font-scale="1.5"></b-icon-trash
                    ></a>
                </b-button-group>
            </template>
        </b-table>

        <b-modal
            id="create-new-modal"
            ref="create-new-modal"
            title="Neuen Benutzer anlegen"
            hide-footer
            cancel-title="Abbrechen"
            ok-title="Neuen Benutzer anlegen"
            @ok="submitForm"
            @close="
        showForm = true;
        saveResult = '';
        fetchData();
      "
            @hidden="
        showForm = true;
        saveResult = '';
        fetchData();
      "
        >
            <template #modal-footer="{ ok, cancel }">
                <b-button variant="primary" @click="ok()" :disabled="createLoading">
                    <b-spinner small v-if="createLoading"></b-spinner>
                    Neuen Benutzer anlegen
                </b-button>
                <b-button variant="light" @click="cancel()"> Abbrechen </b-button>
            </template>

            <div v-show="showForm">
                <validation-observer ref="form" v-slot="{ handleSubmit }">
                    <b-form @submit.stop.prevent="handleSubmit(submitForm)">
                        <validation-provider
                            name="Benutzername"
                            :rules="{ required: true, email: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                id="userName-group-1"
                                label="Benutzername"
                                label-for="userName"
                            >
                                <b-form-input
                                    id="userName"
                                    name="userName"
                                    placeholder="Benutzername"
                                    v-model="form.userName"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="user-name-live-feedback"
                                    type="email"
                                    autofocus
                                ></b-form-input>

                                <b-form-invalid-feedback id="user-name-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="Vorname"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                id="firstName-group-1"
                                label="Vorname"
                                label-for="firstName"
                            >
                                <b-form-input
                                    id="firstName"
                                    name="firstName"
                                    placeholder="Vorname"
                                    v-model="form.firstName"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="first-name-live-feedback"
                                    type="text"
                                ></b-form-input>

                                <b-form-invalid-feedback id="first-name-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="Nachname"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group
                                id="lastName-group-1"
                                label="Nachname"
                                label-for="lastName"
                            >
                                <b-form-input
                                    id="lastName"
                                    name="lastName"
                                    placeholder="Nachname"
                                    v-model="form.lastName"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="last-name-live-feedback"
                                    type="text"
                                ></b-form-input>

                                <b-form-invalid-feedback id="last-name-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="Rolle"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group id="role-group-1" label="Rolle" label-for="role">
                                <b-form-select
                                    id="role"
                                    v-model="form.role"
                                    :options="uiRoles"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="role-live-feedback"
                                ></b-form-select>

                                <b-form-invalid-feedback id="role-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <validation-provider
                            name="AddressFlow User"
                            :rules="{ required: true }"
                            v-slot="validationContext"
                        >
                            <b-form-group id="adf-user-group-1" label="AddressFlow User" label-for="adf_user">
                                <b-form-select
                                    id="adf_user"
                                    v-model="form.adfUser"
                                    :options="[
                    { text: 'Bitte wählen...', value: '' }, ...adfUsers,
                  ]"
                                    :state="getValidationState(validationContext)"
                                    aria-describedby="role-live-feedback"
                                ></b-form-select>

                                <b-form-invalid-feedback id="role-live-feedback">{{
                                        validationContext.errors[0]
                                    }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>

                        <b-button type="submit" variant="primary" :disabled="createLoading">
                            <b-spinner small v-if="createLoading"></b-spinner>
                            Neuen Benutzer anlegen
                        </b-button>
                        <b-button @click="$bvModal.hide('create-new-modal')"
                        >Abbrechen</b-button
                        >

                        <b-alert class="mt-2" v-model="showCreateErrorMsg" variant="danger">
                            <pre>{{ createErrorMsg }}</pre>
                        </b-alert>
                    </b-form>
                </validation-observer>
            </div>

            <div v-if="!showForm">
                <b-alert
                    class="mt-2"
                    style="display: inline-block; word-wrap: break-word; width: 100%"
                    v-model="saveResult"
                    variant="info"
                >{{ saveResult }}</b-alert
                >
                <b-button class="mt-3" @click="$bvModal.hide('create-new-modal')"
                >Ok</b-button
                >
            </div>
        </b-modal>
    </div>
</template>

<script>
import { BIcon, BIconPencil, BIconTrash } from "bootstrap-vue";
import { fetchAdfUser } from '../../helpers/api';

export default {
    components: {
        BIcon,
        BIconPencil,
        BIconTrash,
    },
    data() {
        return {
            tableFields: [
                {
                    key: "firstName",
                    label: "Vorname",
                },
                {
                    key: "lastName",
                    label: "Nachname",
                },
                {
                    key: "role",
                    label: "Rolle",
                },
                {
                    key: "confirmedStatus",
                    label: "Aktiv",
                },
                {
                    key: "dateCreated",
                    label: "Erstellt",
                },
                {
                    key: "edit",
                    label: "",
                },
            ],
            users: [],
            adfUsers: [],
            form: {
                userName: "",
                firstName: "",
                lastName: "",
                role: "",
                adfUser: ""
            },
            uiRoles: [],
            createLoading: false,
            showCreateErrorMsg: false,
            createErrorMsg: "",
            showForm: true,
            saveResult: "",
        };
    },
    created() {
        this.fetchData(1);
        this.fetchAdfUsers();
        this.fetchUiRoles();
    },
    methods: {
        updateTable(data) {
            const usersArray = [];
            for (let i in data) {
                let user = data[i];
                user["confirmedStatus"] =
                    user["confirmedStatus"] === "CONFIRMED" ? "Aktiv" : "Inaktiv";
                user["edit"] = data[i].userName;
                usersArray.push(user);
            }

            this.users = usersArray;
        },
        fetchUiRoles() {
            // like this it is backwards compatible with RuV and Dev - there we just have one SYS-Cognito-Pool
            // and no separation between system and tenant user and therefore only user with system roles
            // for RSD and all other following customers it is like this:
            // SYSTEM-Pool-User only have SystemRoles
            // TENANT-Pool-User only have TenantRoles
            if ((this.$store.getters.userRole === 'AdfTenantAdmin') || (this.$store.getters.userRole === 'AdfTenantUser')) {
                this.uiRoles = [
                    { text: 'Bitte wählen...', value: '' },
                    { text: 'AdfTenantAdmin', value: 'AdfTenantAdmin' },
                    { text: 'AdfTenantUser', value: 'AdfTenantUser' },
                ];
            } else {
                this.uiRoles = [
                    { text: 'Bitte wählen...', value: '' },
                    { text: 'AdfSystemAdmin', value: 'AdfSystemAdmin' },
                    { text: 'AdfSystemUser', value: 'AdfSystemUser' },
                    { text: 'AdfSystemUserImport', value: 'AdfSystemUserImport' },
                ];
            }
        },
        fetchData(currentPage, resetPaginator) {
            this.$http
                .get(this.$authUrl + "users", {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.bearerToken.value,
                    },
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.updateTable(data);
                });
        },
        fetchAdfUsers() {
            this.$http
                .get("admin/user", {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/admin/user"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    data.users
                        .filter(user => user.hmac_private_key )
                        .forEach(user => {
                            this.adfUsers.push({'text': user.username, 'value': user.id});
                        });
                });
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        submitForm() {
            fetchAdfUser(this.form.adfUser, this.$store.state.user, this.createNewUser);
        },
        createNewUser(adfUser) {
            this.showCreateErrorMsg = false;

            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }

                this.createLoading = true;
                var user = {
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,
                    userName: this.form.userName,
                    role: this.form.role,
                    adf_admin_user: adfUser.username,
                    adf_admin_user_auth: adfUser.hmac_private_key
                };

                this.$http
                    .post(this.$authUrl + "user", user, {
                        headers: {
                            Authorization: "Bearer " + this.$store.state.bearerToken.value,
                        },
                    })
                    .then((response) => {
                        this.createLoading = false;
                        this.newFirstName = "";
                        this.newLastName = "";
                        this.newEmail = "";
                        this.newRole = "";

                        this.$bvModal.hide("create-new-modal");
                    })
                    .catch((response) => {
                        this.createLoading = false;
                        this.showCreateErrorMsg = true;
                        console.log(response);
                        this.createErrorMsg = response.message
                            ? response.message
                            : "Benutzer existiert bereits";
                    });
            });
        },
        showDeleteModal(userName) {
            this.$bvModal
                .msgBoxConfirm(
                    `Möchtest du den Benutzer "${userName}" wirklich löschen?`,
                    {
                        cancelTitle: "Abbrechen",
                    }
                )
                .then((value) => {
                    if (value) {
                        this.$http
                            .delete(this.$authUrl + "user/" + userName, {
                                headers: {
                                    Authorization: "Bearer " + this.$store.state.bearerToken.value,
                                },
                            })
                            .then((response) => {
                                for (let i in this.users) {
                                    if (this.users[i]["userName"] === userName) {
                                        this.users.splice(i, 1);
                                    }
                                }
                            })
                            .catch((response) => {
                                alert(
                                    "Benutzer konnte nicht gelöscht werden.\n\n" +
                                    response.message
                                );
                            });
                    }
                });
        },
    },
};
</script>

