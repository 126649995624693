<template>
  <div class="container-fluid">
    <div class="float-right">
      <b-button-close @click="$router.replace('/data/' + poolKey)">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="clearfix"></div>
    <div class="row justify-content-begin mb-2">
      <div class="col-12">
        <b-button @click="fetchData">Aktualisieren</b-button>
      </div>
    </div>

    <table class="table table-striped">
      <thead>
        <th style="width:5%">ID</th>
        <th style="width:10%">Datensätze</th>
        <th style="width:15%">Dateiname</th>
        <th style="width:15%">Übertragungsweg</th>
        <th style="width:15%">Datum</th>
        <th style="width:10%">Download</th>
      </thead>
      <tbody>
        <tr class="file" v-for="file in exportedFiles">
          <td i="id">{{ file.id }}</td>
          <td i="no_data">{{ file.numberOfDates }}</td>
          <td i="filename">{{ file.filename }}</td>
          <td i="delivery_type">{{ file.deliveryType }}</td>
          <td i="created_at">{{ file.createdAt }}</td>
          <td i="download">
            <b-link
              v-if="file.hasS3Reference"
              @click="downloadFile(file.id, file.filename)"
            >Download</b-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import FileSaver from "file-saver";

export default {
  components: {
    FileSaver
  },
  data() {
    return {
      poolKey: null,
      exportedFiles: []
    };
  },
  created() {
    this.poolKey = this.$route.params.poolKey;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get("interface/pool/" + this.poolKey + "/files", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/interface/pool/" + this.poolKey + "/files"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.exportedFiles = data.files;
        });
    },
    downloadFile(fileId, filename) {
      this.$http
        .get("interface/pool/file/" + fileId + "/download", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/interface/pool/file/" + fileId + "/download"
            )
          }
        })
        .then(response => {
          let blob = this.convertToBlob(response.data);
          FileSaver.saveAs(blob, filename);
        });
    },
    convertToBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    }
  }
};
</script>

