<template>
    <div class="container-fluid">
        <search-bar @search="fetchData" :show-info="details" :show-filter="true"></search-bar>

        <b-table hover :items="pools" :fields="tableFields" custom-foot>
            <template v-slot:cell(key)="data">
                <router-link :to="{ name: 'pool_settings', params: { poolKey: data.value }}">
                    <a>{{ data.value }}</a>
                </router-link>
            </template>
            <template v-slot:cell(status)="data">
                <b-button variant="default" :title="getTitleByStatus(data.value)" size="sm">
                    <b-icon-circle-fill :variant="getVariantByStatus(data.value)"></b-icon-circle-fill>
                </b-button>
            </template>
            <template v-slot:cell(pool_proxy)="data">
                {{ data.value.key }}
            </template>
            <template v-slot:cell(number_of_data_now)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(number_of_data_total)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(number_of_exported_data)="data">
                {{ data.value | formatNumber }}
            </template>
            <template v-slot:cell(optin_quota)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_quotas', params: { poolKey: data.item.pool_key }}">
                    <a>{{ data.value }}</a>
                </router-link>
            </template>

            <template v-if="$store.getters.userRole === 'AdfSystemAdmin'" v-slot:cell(export_settings)="data">
                <router-link :to="{ name: 'export_settings', params: { poolKey: data.value }}">
                    <a>Öffnen</a>
                </router-link>
            </template>

            <template v-slot:cell(show_data)="data">
                <router-link :to="{ name: 'data', params: { poolKey: data.value }}">
                    <a>anzeigen</a>
                </router-link>
            </template>
            <template v-slot:cell(show_exports)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_exports', params: { poolId: data.value }}">
                    <a>anzeigen</a>
                </router-link>
            </template>
            <template v-slot:cell(stats)="data">
                <router-link
                    :disabled="(data.value === 0)"
                    :class="(data.value === 0) ? 'disabled' : ''"
                    :event="(data.value === 0) ? '' : 'click'"
                    :to="{ name: 'pool_stats', params: { poolKey: data.value }}">
                    <a>Stats</a>
                </router-link>
            </template>

            <template v-slot:custom-foot>
                <tr>
                    <td colspan="11">
                        <strong>Anzahl: {{ totalNumberOfPools }}</strong>
                    </td>
                </tr>
            </template>
        </b-table>

        <pool-paginator
            @currentPageWasChanged="fetchData($event)"
            :items="pools"
            :limitPerPage="limitPerPage"
            :totalNumberOfItems="totalNumberOfPools"
            :reset="resetPaginator"
        ></pool-paginator>
    </div>
</template>

<script>
import {BIcon, BIconCircleFill} from "bootstrap-vue";
import Paginator from "./Paginator.vue";
import SearchBar from "./SearchBar.vue";

export default {
    components: {
        BIcon,
        BIconCircleFill,
        poolPaginator: Paginator,
        searchBar: SearchBar
    },
    data() {
        return {
            tableFields: [
                {
                    key: "id",
                    label: "ID"
                },
                {
                    key: "status",
                    label: "Status",
                    class: "text-center"
                },
                {
                    key: "pool_proxy",
                    label: "Pool Proxy"
                },
                {
                    key: "key",
                    label: "Key"
                },
                {
                    key: "export_rule",
                    label: "Export-Regel"
                },
                {
                    key: "party",
                    label: "Ersteller"
                },
                {
                    key: "name",
                    label: "Titel"
                },
                {
                    key: "number_of_data_now",
                    label: "Datensätze aktuell",
                    class: "text-right"
                },
                {
                    key: "number_of_data_total",
                    label: "Datensätze insgesamt",
                    class: "text-right"
                },
                {
                    key: "number_of_exported_data",
                    label: "Datensätze exportiert",
                    class: "text-right"
                },
                {
                    key: "optin_quota",
                    label: "Optin-Quote",
                    class: "text-right"
                },
                {
                    key: "auto_export",
                    label: "Auto-Export"
                },
                {
                    key: "import_allowed",
                    label: "CSV Importer"
                },
                {
                    key: "export_settings",
                    label: "Export-Einstellungen"
                },
                {
                    key: "show_data",
                    label: "Daten"
                },
                {
                    key: "show_exports",
                    label: "Exporte"
                },
                {
                    key: "stats",
                    label: "Insights"
                }
            ],
            details: true,
            limitPerPage: 15,
            totalNumberOfPools: 0,
            pools: [],
            resetPaginator: false
        };
    },
    created() {
        this.fetchData(1);
    },
    methods: {
        updateTable(data) {
            const poolsArray = [];
            for (let i in data.pools) {
                let pool = data.pools[i];

                if (pool.locked) {
                    pool["_rowVariant"] = 'danger';
                }

                // injecting pool key to table cells
                pool["export_settings"] = pool.key;
                pool["show_data"] = pool.key;
                pool["pool_key"] = pool.key;

                let optinQuota = 0;
                if (pool["number_of_data_total"] > 0) {
                    optinQuota = pool["number_of_exported_data"] / pool["number_of_data_total"];
                }
                pool["optin_quota"] = (optinQuota * 100).toFixed(2) + "%";

                if (pool.extended_export_logging.enabled) {
                    pool["show_exports"] = pool.id;
                } else {
                    pool["show_exports"] = 0;
                }
                if (pool.statistics_existent) {
                    pool["stats"] = pool.key;
                }

                if (!pool.statistics_existent || pool.import_allowed) {
                    pool["stats"] = 0;
                    pool["optin_quota"] = '-'
                    pool["number_of_data_total"] = '-'
                    pool["number_of_exported_data"] = '-'
                }

                poolsArray.push(pool);
            }
            this.pools = poolsArray;
            this.totalNumberOfPools = data.total;
        },
        fetchData(currentPage, resetPaginator, showDetailedInfo) {
            if (resetPaginator) {
                this.resetPaginator = resetPaginator;
            }

            let params = {
                limit: this.limitPerPage,
                offset: (currentPage - 1) * this.limitPerPage
            };

            let searchValue = this.$store.state.searchValue;
            if (searchValue) {
                params.searchValue = searchValue;
            }

            let status = this.$store.state.status;
            if (status) {
                params.status = status;
            }

            let exportRule = this.$store.state.exportRule;
            console.log('state.exportRule');
            console.log(this.$store.state.exportRule);
            if (exportRule) {
                params.exportRule = exportRule;
            }

            let party = this.$store.state.party;
            if (party) {
                params.party = party;
            }

            /*if (true === showDetailedInfo) {
              params.showDetailedInfo = true;
              this.details = true;
            } else {
              this.details = false;
            }*/
            this.details = true;
            params.showDetailedInfo = true;

            this.$http
                .get("interface/pool", {
                    params: params,
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/interface/pool"
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    this.updateTable(data);
                });
        },
        getTitleByStatus(status) {
            let title;
            switch (status) {
                case 1:
                    title = 'online';
                    break;
                case 2:
                    title = 'abgelaufen';
                    break;
                case 3:
                    title = 'deaktiviert';
                    break;
                case 4:
                    title = 'gelöscht';
                    break;
                default:
                    title = 'inaktiv';
            }

            return title;
        },
        getVariantByStatus(status) {
            let variant;
            switch (status) {
                case 1:
                    variant = 'success';
                    break;
                case 2:
                    variant = 'warning';
                    break;
                case 3:
                    variant = 'dark';
                    break;
                case 4:
                    variant = 'danger';
                    break;
                default:
                    variant = 'secondary';
            }

            return variant;
        }
    }
};
</script>
<style>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>
