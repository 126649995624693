<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/pools')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row mt-3"></div>

    <b-tabs content-class="mt-3">
      <b-tab title="Export-Zeiten" active>
        <div class="row mt-2">
          <h1>Export-Zeiten</h1>
        </div>

        <export-schedule :pool-key="poolKey"></export-schedule>
      </b-tab>
      <b-tab title="Export-Konfiguration">
        <div class="row mt-5">
          <h1>Export-Konfiguration</h1>
        </div>

        <export-definition :pool-key="poolKey"></export-definition>
      </b-tab>
      <b-tab title="Export-Template">
        <div class="row mt-5">
          <h1>Export-Template</h1>
        </div>

        <export-structure :pool-key="poolKey"></export-structure>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";
import ExportSchedule from "./ExportSettings/ExportSchedule.vue";
import ExportDefinition from "./ExportSettings/ExportDefinition.vue";
import ExportStructure from "./ExportSettings/ExportStructure.vue";

export default {
  data() {
    return {
      poolKey: null,
      page: null,
      pageSelectOptions: [
        { value: null, text: "--Bitte wählen" },
        { value: "export_schedule", text: "Export-Zeiten" },
        { value: "export_definition", text: "Export-Konfiguration" },
        { value: "export_structure", text: "Export-Template" }
      ]
    };
  },
  components: {
    VJsoneditor,
    ExportSchedule,
    ExportDefinition,
    ExportStructure
  },
  created() {
    this.poolKey = this.$route.params.poolKey;
  }
};
</script>
