<template>
    <div style="margin: 10px">
        <div>
            <b-alert show variant="secondary">
                <h4 class="alert-heading">Statistiken für Pool: {{ poolConfigData.name }}</h4>
                <hr>
                <div class="col-md-12 ">
                    <b-form inline>
                        <search-bar-pool-stats @search="updateData"></search-bar-pool-stats>
                        <div v-if="isLoading">
                            <b-spinner label="Loading..."></b-spinner>
                        </div>
                    </b-form>

                </div>
            </b-alert>
        </div>
        <div class="chart-container row" style="position: relative;">
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Leads gesamt / abgeschlossen (Eingangsdatum) </strong></b-alert>
                <line-chart v-if="chartCompletedDayLoaded" :chartdata="chartCompletedDayDataCollection"
                            :options="chartCompletedDayDataOptions"></line-chart>
            </div>
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Leads abgeschlossen / offen (Kalenderwoche)</strong>
                </b-alert>
                <bar-chart v-if="chartCompletedCalendarWeeksLoaded"
                           :chartdata="chartCompletedCalendarWeeksDataCollection"
                           :options="chartCompletedCalendarWeeksDataOptions"></bar-chart>
            </div>
            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">
                <b-alert show variant="secondary"><strong>Leads abgeschlossen / offen (Monat)</strong></b-alert>
                <bar-chart v-if="chartCompletedMonthLoaded" :chartdata="chartCompletedMonthDataCollection"
                           :options="chartCompletedMonthDataOptions"></bar-chart>
            </div>
        </div>
<!--        <div class="chart-container row" style="position: relative;">-->
<!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
<!--                <b-alert show variant="secondary"><strong>Leads gesamt (Eingangsdatum)</strong></b-alert>-->
<!--                <line-chart v-if="chartCreatedDayLoaded" :chartdata="chartCreatedDayDataCollection"-->
<!--                            :options="chartCreatedDayDataOptions"></line-chart>-->
<!--            </div>-->
<!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
<!--                <b-alert show variant="secondary"><strong>Leads gesamt (Kalenderwoche)</strong></b-alert>-->
<!--                <bar-chart v-if="chartCreatedCalendarWeeksLoaded" :chartdata="chartCreatedCalendarWeeksDataCollection"-->
<!--                           :options="chartCreatedCalendarWeeksDataOptions"></bar-chart>-->
<!--            </div>-->
<!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
<!--                <b-alert show variant="secondary"><strong>Leads gesamt (Monat)</strong></b-alert>-->
<!--                <bar-chart v-if="chartCreatedMonthLoaded" :chartdata="chartCreatedMonthDataCollection"-->
<!--                           :options="chartCreatedMonthDataOptions"></bar-chart>-->
<!--            </div>-->
<!--        </div>-->
        <!--        <div class="chart-container row" style="position: relative;">-->
        <!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
        <!--                <b-alert show variant="secondary"><strong>Bounce rate by dispatch date</strong></b-alert>-->
        <!--                <line-chart v-if="chartCreatedDayBouncedLoaded" :chartdata="chartCreatedDayBouncedDataCollection" :options="chartCreatedDayBouncedDataOptions"></line-chart>-->
        <!--            </div>-->
        <!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
        <!--                <b-alert show variant="secondary"><strong>Open rate by dispatch date</strong></b-alert>-->
        <!--                <line-chart v-if="chartCreatedDayOpenedLoaded" :chartdata="chartCreatedDayOpenedDataCollection"></line-chart>-->
        <!--            </div>-->
        <!--            <div class="single-chart col shadow p-3 mb-5 bg-white rounded" style="margin: 10px">-->
        <!--                <b-alert show variant="secondary"><strong>Click rate by dispatch date</strong></b-alert>-->
        <!--                <line-chart v-if="chartCreatedDayClickedLoaded" :chartdata="chartCreatedDayClickedDataCollection" :options="chartCreatedDayClickedDataOptions"></line-chart>-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script>
import LineChart from './Charts/LineChart';
import PieChart from './Charts/PieChart';
import BarChart from './Charts/BarChart';
import searchBarPoolStats from "./SearchBarPoolStats.vue";

export default {
    components: {
        searchBarPoolStats,
        LineChart,
        PieChart,
        BarChart
    },
    computed: {
        isDevSystem: function () {
            return (process.env.NODE_ENV === 'development');
        },
    },
    data() {
        return {
            isLoading: false,
            dateFrom: '',
            dateTo: '',
            poolKey: null,
            poolConfigData: null,
            chartCompletedDayLoaded: false,
            chartCompletedCalendarWeeksLoaded: false,
            chartCompletedMonthLoaded: false,
            chartCreatedDayLoaded: null,
            chartCreatedCalendarWeeksLoaded: null,
            chartCreatedMonthLoaded: null,
            chartCreatedDayBouncedLoaded: null,
            chartCreatedDayOpenedLoaded: null,
            chartCreatedDayClickedLoaded: null,

            chartCompletedDayDataCollection: null,
            chartCompletedCalendarWeeksDataCollection: null,
            chartCompletedMonthDataCollection: null,
            chartCreatedDayDataCollection: false,
            chartCreatedCalendarWeeksDataCollection: false,
            chartCreatedMonthDataCollection: false,
            chartCreatedDayBouncedDataCollection: false,
            chartCreatedDayOpenedDataCollection: false,
            chartCreatedDayClickedDataCollection: false,

            chartCompletedDayDataOptions: null,
            chartCreatedCalendarWeeksDataOptions: null,
            chartCreatedMonthDataOptions: null,
            chartCreatedDayDataOptions: null,
            chartCompletedCalendarWeeksDataOptions: null,
            chartCompletedMonthDataOptions: null,
            chartCreatedDayBouncedDataOptions: null,
            chartCreatedDayOpenedDataOptions: null,
            chartCreatedDayClickedDataOptions: null,
        }
    },
    mounted() {
        this.chartCompletedDayLoaded = false;
        this.chartCompletedCalendarWeeksLoaded = false;
        this.chartCompletedMonthLoaded = false;
        this.chartCreatedDayLoaded = false;
        this.chartCreatedCalendarWeeksLoaded = false;
        this.chartCreatedMonthLoaded = false;
        this.chartCreatedDayBouncedLoaded = false;
        this.chartCreatedDayOpenedLoaded = false;
        this.chartCreatedDayClickedLoaded = false;
        try {
            this.fetchBarCompletedDayChartData();
            this.fetchLineCompletedCalendarWeeksChartData();
            this.fetchLineCompletedMonthChartData();
            // this.fetchBarCreatedDayChartData();
            // this.fetchBarCreatedCalendarWeeksChartData()
            // this.fetchBarCreatedMonthChartData();
            // this.fetchBarCreatedDayBouncedChartData();
            // this.fetchBarCreatedDayOpenedChartData();
            // this.fetchBarCreatedDayClickedChartData();
        } catch (e) {
            console.log(e)
        }
    },
    created() {
        this.poolKey = this.$route.params.poolKey;
        this.fetchPoolConfigData();
        this.updateData();
    },
    methods: {
        setDates(reset) {
            // TODO same code as in SearchBarQuota
            this.today = new Date();
            if (this.$store.state.poolStatsFilterDateFrom === '' || reset) {
                this.today.setMonth(this.today.getMonth() - 1);
                this.dateFrom = this.today.toISOString().slice(0,10);
            }
            if (this.$store.state.poolStatsFilterDateTo === '' || reset) {
                this.dateTo = new Date().toISOString().slice(0,10)
            }
        },
        fetchData(dataPath, callback) {
            this.$http.get(this.$dashboardUrl + dataPath)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    if (callback) callback(data);
                });
        },
        fetchBarCompletedDayChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/overalldate/${party}/line/completed+created_date/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=abgeschlossen&replace[0]=gesamt`;
            this.fetchData(dataPath, (data) => {
                this.chartCompletedDayDataCollection = data;
                this.chartCompletedDayLoaded = true;
                this.chartCompletedDayDataOptions = data.options;


            })
        },
        fetchLineCompletedCalendarWeeksChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/${party}/bar/completed+created_calendar_week/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=abgeschlossen&replace[0]=offen`;
            this.fetchData(dataPath, (data) => {
                this.chartCompletedCalendarWeeksDataCollection = data;
                this.chartCompletedCalendarWeeksDataOptions = data.options;
                this.chartCompletedCalendarWeeksLoaded = true;
            })
        },
        fetchLineCompletedMonthChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/${party}/bar/completed+created_month/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=abgeschlossen&replace[0]=offen`;
            this.fetchData(dataPath, (data) => {
                this.chartCompletedMonthDataCollection = data;
                this.chartCompletedMonthDataOptions = data.options;
                this.chartCompletedMonthLoaded = true;
            })
        },
        fetchBarCreatedDayChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/${party}/line/created_date/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[created_date]=Anzahl`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedDayDataCollection = data;
                this.chartCreatedDayLoaded = true;
                this.chartCreatedDayDataOptions = data.options;
            })
        },
        fetchBarCreatedCalendarWeeksChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/${party}/bar/created_calendar_week/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[created_calendar_week]=Anzahl`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedCalendarWeeksDataCollection = data;
                this.chartCreatedCalendarWeeksLoaded = true;
                this.chartCreatedCalendarWeeksDataOptions = data.options;
            })
        },
        fetchPoolConfigData() {
            const poolKey = this.poolKey;

            this.$http
                .get("pool/" + poolKey, {
                    headers: {
                        Authorization: this.createHmacHash(
                            this.$store.state.user.adfAdminUser,
                            "-GET-/addressflow/v1/pool/" + poolKey
                        )
                    }
                })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    this.poolConfigData = data;
                });
        },
        fetchBarCreatedMonthChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `adxmetric/${party}/bar/created_month/created_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[created_month]=Anzahl`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedMonthDataCollection = data;
                this.chartCreatedMonthLoaded = true;
                this.chartCreatedMonthDataOptions = data.options;
            })
        },
        fetchBarCreatedDayBouncedChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `msgmetric/${party}/line/bounced+receive_date/receive_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=bounced&replace[0]=not\ bounced`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedDayBouncedDataCollection = data;
                this.chartCreatedDayBouncedLoaded = true;
                this.chartCreatedDayBouncedDataOptions = data.options;
            })
        },
        fetchBarCreatedDayOpenedChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `msgmetric/${party}/line/opened+receive_date/receive_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=opened&replace[0]=not\ opened`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedDayOpenedDataCollection = data;
                this.chartCreatedDayOpenedLoaded = true;
                this.chartCreatedDayOpenedDataOptions = data.options;
            })
        },
        fetchBarCreatedDayClickedChartData() {
            const party = this.$store.state.user.party;
            const poolKey = this.poolKey;

            const dataPath = `msgmetric/${party}/line/clicked+receive_date/receive_date/${this.dateFrom}+${this.dateTo}/pool_id/${poolKey}?replace[1]=clicked&replace[0]=not\ clicked`;
            this.fetchData(dataPath, (data) => {
                this.chartCreatedDayClickedDataCollection = data;
                this.chartCreatedDayClickedLoaded = true;
                this.chartCreatedDayClickedDataOptions = data.options;
            })
        },
        updateData() {
            this.isLoading = true;
            this.chartCompletedDayLoaded = false;
            this.chartCompletedCalendarWeeksLoaded = false;
            this.chartCompletedMonthLoaded = false;
            this.chartCreatedDayLoaded = false;
            this.chartCreatedCalendarWeeksLoaded = false;
            this.chartCreatedMonthLoaded = false;
            this.chartCreatedDayBouncedLoaded = false;
            this.chartCreatedDayOpenedLoaded = false;
            this.chartCreatedDayClickedLoaded = false;
            this.dateFrom = this.$store.state.poolStatsFilterDateFrom;
            this.dateTo = this.$store.state.poolStatsFilterDateTo;
            this.setDates(false)
            try {
                this.fetchBarCompletedDayChartData();
                this.fetchLineCompletedCalendarWeeksChartData();
                this.fetchLineCompletedMonthChartData();
                // this.fetchBarCreatedDayChartData();
                // this.fetchBarCreatedCalendarWeeksChartData()
                // this.fetchBarCreatedMonthChartData();
            } catch (e) {
                console.log(e)
            }
            this.isLoading = false;
        },
    }
}
</script>

<style>
.single-chart {
    max-width: 500px;
    max-height: 500px;
}
</style>
