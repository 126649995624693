<template>
  <div>
    <div class="row my-3">
      <b-button variant="info" v-b-modal.default_export_structure>Standard Export-Template anzeigen</b-button>
    </div>

    <div class="row">
      <b-form-checkbox
        id="customExportStructure"
        v-model="customExportStructure"
        @change="overrideExportStructure"
      >Standard-Export-Template überschreiben</b-form-checkbox>
    </div>

    <div v-if="customExportStructure">
      <div class="row">
        <MonacoEditor
          height="500"
          theme="vs-dark"
          language="twig"
          :options="editorOptions"
          :value="exportStructure"
          :editorMounted="onEditorMounted"
          @change="onChange"
        ></MonacoEditor>
      </div>

      <div class="row mt-3">
        <label>
          Template-Name*
          <b-form-input v-model="exportStructureName" required></b-form-input>
        </label>
        <div v-if="showInvalidNameMessage" class="invalid-feedback d-block" id="invalid_name">Bitte gib einen Namen ein.</div>
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >{{ exportStructureSuccessMessage }}</b-alert>
      </div>
    </div>

    <div class="row my-3">
      <b-button
        variant="primary"
        @click="saveExportStructure()"
        :disabled="saveButtonDisabled"
      >Speichern</b-button>
    </div>

    <b-modal size="xl" id="default_export_structure" title="Standard Export-Template">
      <pre id="export_structure_json">{{ JSON.stringify(structureFromExportRule, undefined, 2) }}</pre>
    </b-modal>
  </div>
</template>

<script>
import MonacoEditor from "monaco-editor-vue";
import 'monaco-editor/esm/vs/basic-languages/twig/twig.contribution';

export default {
  props: ["poolKey"],
  data() {
    return {
      editorOptions: {},
      exportStructure: null,
      exportStructureId: 0,
      customExportStructure: false,
      alertDismissCountDown: 0,
      saveButtonDisabled: false,
      structureFromExportRule: null,
      exportStructureSuccessMessage: "",
      showInvalidNameMessage: false,
      exportRuleTitle: "",
      exportStructureName: "",
      exportStructureType: ""
    };
  },
  components: {
    MonacoEditor
  },
  created() {
    this.fetchExportStructure();
  },
  methods: {
    onChange(value) {
      this.exportStructure = value;
    },
    onEditorMounted(editor, monaco) {
      let val = this.exportStructure.replace(/\\r\\n/g, "\r\n");
      val = val.replace(/\\t/g, "\t");
      editor.setValue(val);
    },
    fetchExportStructure() {
      this.$http
        .get("form/pool/exportstructure/" + this.poolKey, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/pool/exportstructure/" + this.poolKey
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (!data.success) {
            alert("error");
          }

          this.exportRuleTitle =
            data.exportRuleExportStructure.export_rule_title;
          this.exportStructureType = data.exportRuleExportStructure.type;

          if (data.exportStructure !== null) {
            this.exportRuleTitle = data.exportStructure.export_rule_title;
            this.exportStructureName = data.exportStructure.name;
            this.exportStructure = data.exportStructure.content.template;
            this.customExportStructure = true;
            this.exportStructureId = data.exportStructure.id;
          }

          this.structureFromExportRule =
            data.exportRuleExportStructure.content.template;
        });
    },
    onError() {
      console.log("error");
    },
    overrideExportStructure(checked) {
      if (true === checked) {
        if (null === this.exportStructure) {
          this.exportStructure = this.structureFromExportRule;
        }
      } else {
        this.customExportStructure = false;
      }
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.saveExportStructure();
      }
    },
    saveExportStructure() {
      if ('' === this.exportStructureName) {
        this.showInvalidNameMessage = true;
        return;
      }
      this.showInvalidNameMessage = false;

      this.saveButtonDisabled = true;

      if (true === this.customExportStructure) {
        this.createOrUpdateExportStructure();
      } else {
        this.detachExportStructureFromPool();
      }
    },
    createOrUpdateExportStructure() {
      if (this.exportStructureId) {
        this.updateExportStructure(this.exportStructureId);
      } else {
        this.createExportStructure();
      }
    },
    updateExportStructure(exportStructureId) {
      let template = this.exportStructure.replace(/\r\n/g, "\\r\\n");
      template = template.replace(/\t/g, "\\t");

      this.$http
        .put(
          "export_structure/" + exportStructureId,
          {
            name: this.exportStructureName,
            export_structure: {
              type: this.exportStructureType,
              content: {
                template: template
              }
            }
          },
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-PUT-/addressflow/v1/export_structure/" + exportStructureId
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportStructureSuccessMessage =
              "Export-Template erfolgreich gespeichert.";
            this.alertDismissCountDown = 2;
          }
        });
    },
    createExportStructure() {
      this.$http
        .post(
          "export_structure",
          {
            name: this.exportStructureName,
            export_rule: this.exportRuleTitle,
            export_structure: {
              type: this.exportStructureType,
              content: {
                template: this.exportStructure
              }
            }
          },
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-POST-/addressflow/v1/export_structure"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportStructureId = data.structure_id;
            this.attachExportStructureToPool(this.exportStructureId);
          }
        });
    },
    attachExportStructureToPool(exportStructureId) {
      this.$http
        .put(
          "pool/" +
            this.poolKey +
            "/exportstructure/" +
            exportStructureId +
            "/attach",
          {},
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-PUT-/addressflow/v1/pool/" +
                  this.poolKey +
                  "/exportstructure/" +
                  exportStructureId +
                  "/attach"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportStructureSuccessMessage =
              "Export-Template erfolgreich gespeichert.";
            this.alertDismissCountDown = 2;
          }
        });
    },
    detachExportStructureFromPool() {
      this.$http
        .put(
          "pool/" + this.poolKey + "/exportstructure/detach",
          {},
          {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-PUT-/addressflow/v1/pool/" + this.poolKey + "/exportstructure/detach"
              )
            }
          }
        )
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportStructureName = "";
            this.exportStructureType = "";
            this.exportStructureId = 0;
            this.exportStructure = null;
            this.customExportStructure = false;
            this.exportStructureSuccessMessage =
              "Export-Template auf Standard zurück gesetzt.";
            this.alertDismissCountDown = 2;
          }
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>
