<template>
  <div>
    <div class="row my-3">
      <b-button variant="info" v-b-modal.default_export_definition>Standard Export-Konfiguration anzeigen</b-button>
    </div>

    <div class="row">
      <b-form-checkbox
        id="customExportDefinition"
        v-model="customExportDefinition"
        @change="overrideExportDefinition"
      >Standard-Export-Konfiguration überschreiben</b-form-checkbox>
    </div>

    <div v-if="customExportDefinition">
      <div class="row">
        <v-jsoneditor v-model="exportDefinition" :plus="false" height="500px" @error="onError"></v-jsoneditor>
      </div>
    </div>

    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >{{ exportDefinitionSuccessMessage }}</b-alert>
      </div>
    </div>

    <div class="row my-3">
      <b-button
        variant="primary"
        @click="saveExportDefinition()"
        :disabled="saveButtonDisabled"
      >Speichern</b-button>
    </div>

    <b-modal size="xl" id="default_export_definition" title="Standard Export-Konfiguration">
      <pre id="export_definition_json">{{ JSON.stringify(definitionFromExportRule, undefined, 2) }}</pre>
    </b-modal>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  props: ['poolKey'],
  data() {
    return {
      exportDefinition: null,
      customExportDefinition: false,
      alertDismissCountDown: 0,
      saveButtonDisabled: false,
      definitionFromExportRule: null,
      exportDefinitionSuccessMessage: ''
    };
  },
  components: {
    VJsoneditor
  },
  created() {
    this.fetchExportDefinition();
  },
  methods: {
    fetchExportDefinition() {
      this.$http
        .get("form/pool/exportdefinition/" + this.poolKey, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/pool/exportdefinition/" + this.poolKey
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (!data.success) {
            alert("error");
          }

          if (data.exportDefinition !== null) {
            this.exportDefinition = data.exportDefinition;
            this.customExportDefinition = true;
          }

          this.definitionFromExportRule = data.exportRuleExportDefinition;
        });
    },
    onError() {
      console.log("error");
    },
    overrideExportDefinition(checked) {
      if (true === checked) {
        if (null === this.exportDefinition) {
          this.exportDefinition = this.definitionFromExportRule;
        }
      } else {
        this.customExportDefinition = false;
      }
    },
    keyboardSave(e) {
      if (
        (window.navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey) &&
        e.keyCode == 83
      ) {
        e.preventDefault();
        this.saveExportDefinition();
      }
    },
    saveExportDefinition() {
      this.saveButtonDisabled = true;

      if (true === this.customExportDefinition) {
        this.createOrUpdateExportDefinition();
      } else {
        this.removeExportDefinitionFromPool();
      }
    },
    createOrUpdateExportDefinition() {
      this.$http
        .post("pool/" + this.poolKey + "/exportdefinition", this.exportDefinition, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-POST-/addressflow/v1/pool/" + this.poolKey + "/exportdefinition"
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.exportDefinitionSuccessMessage = 'Export-Konfiguration erfolgreich gespeichert.';
            this.alertDismissCountDown = 2;
          }
        });
    },
    removeExportDefinitionFromPool() {
        this.$http
          .delete("pool/" + this.poolKey + "/exportdefinition", {
            headers: {
              Authorization: this.createHmacHash(
                this.$store.state.user.adfAdminUser,
                "-DELETE-/addressflow/v1/pool/" + this.poolKey + "/exportdefinition"
              )
            }
          })
          .then(response => {
            return response.json();
          })
          .then(data => {
            if (data.success === true) {
              this.exportDefinitionSuccessMessage = 'Export-Konfiguration auf Standard zurück gesetzt.';
              this.alertDismissCountDown = 2;
            }
          });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>
