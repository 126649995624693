<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
        <h3>Password vergessen?</h3>
        <p>Um Ihr Password zurückzusetzen, führen Sie bitte folgende Schritte durch:</p>
        <ol>
          <li>Fordern Sie eine PIN mit Hilfe Ihrer Email-Adresse an.</li>
          <li>Nutzen Sie die PIN, um ein neues Passwort zu vergeben.</li>
        </ol>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
        <h4>PIN anfordern</h4>
        <validation-observer ref="formRequestPin" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(requestPin)"
            class="justify-content-center"
          >
            <validation-provider
              name="Email-Adresse"
              :rules="{ required: true, email: true}"
              v-slot="validationContext"
            >
              <b-form-group
                id="email-group-1"
                label="Email-Adresse"
                label-for="emailAddress"
              >
                <b-form-input
                  id="emailAddress"
                  name="emailAddress"
                  v-model="formRequestPin.emailAddress"
                  :state="getValidationState(validationContext)"
                  aria-describedby="emailAddress-live-feedback"
                  :type="'text'"
                ></b-form-input>

                <b-form-invalid-feedback id="emailAddress-live-feedback">{{
                  validationContext.errors[0]
                }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-alert :show="showPinRequestSuccess" variant="success">
              Falls Ihre Email-Adresse in unserem System bekannt ist, haben wir Ihnen eine PIN zugesendet.
              Bitte überprüfen Sie Ihren Posteingang.
            </b-alert>

            <b-alert :show="showPinRequestError" variant="danger">
              Die PIN konnte nicht angefordert werden. Bitte probieren Sie es später noch einmal.
            </b-alert>

            <b-button type="submit" variant="primary" :disabled="loading">
              <b-spinner small v-if="loadingRequestPin"></b-spinner>
              PIN anfordern
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <div class="row" v-if="isPinRequested">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
        <h4>Neues Passwort vergeben</h4>

        <validation-observer ref="formResetPassword" v-slot="{ handleSubmit }">
          <b-form
              @submit.stop.prevent="handleSubmit(resetPassword)"
              class="justify-content-center"
          >
            <validation-provider
                name="PIN"
                :rules="{ required: true , numeric: true, min: 6}"
                v-slot="validationContext"
            >
              <b-form-group
                  id="pin-group-1"
                  label="PIN"
                  label-for="pin"
              >
                <b-form-input
                    id="pin"
                    name="pin"
                    v-model="formResetPassword.confirmationCode"
                    :state="getValidationState(validationContext)"
                    aria-describedby="pin-live-feedback"
                    :type="'text'"
                ></b-form-input>

                <b-form-invalid-feedback id="pin-live-feedback">{{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
                name="Neues Passwort"
                :rules="{ passwordchange: true, required: true, min: 8 }"
                v-slot="validationContext"
            >
              <b-form-group
                  id="new-password-group-1"
                  label="Neues Passwort"
                  label-for="newPassword"
              >
                <b-form-input
                    id="newPassword"
                    name="newPassword"
                    v-model="formResetPassword.newPassword"
                    :state="getValidationState(validationContext)"
                    aria-describedby="new-password-live-feedback"
                    :type="showPasswords ? 'text' : 'password'"
                ></b-form-input>

                <b-form-invalid-feedback id="new-password-live-feedback">{{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
                name="Passwort bestätigen"
                :rules="{ required: true, min: 8, password: '@Neues Passwort' }"
                v-slot="validationContext"
            >
              <b-form-group
                  id="confirm-password-group-1"
                  label="Passwort bestätigen"
                  label-for="confirmPassword"
              >
                <b-form-input
                    id="confirmPassword"
                    name="confirmPassword"
                    v-model="formResetPassword.confirmPassword"
                    :state="getValidationState(validationContext)"
                    aria-describedby="confirm-password-live-feedback"
                    :type="showPasswords ? 'text' : 'password'"
                ></b-form-input>

                <b-form-invalid-feedback id="confirm-live-feedback">{{
                    validationContext.errors[0]
                  }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group>
              <b-form-checkbox v-model="showPasswords" value="1">
                Passwörter anzeigen
              </b-form-checkbox>
            </b-form-group>

            <b-alert :show="showPasswordChangeError" variant="danger">
              Das Password konnte nicht gesetzt werden.
              Bitte kontrollieren Sie die PIN oder fordern Sie eine neue an.
            </b-alert>

            <b-alert :show="showPasswordChangeSuccess" variant="success">
              Ihr Password wurde erfolgreich geändert.
              Bitte melden Sie sich wie gewohnt an.
            </b-alert>

            <b-button type="submit" variant="primary" :disabled="loading">
              <b-spinner small v-if="loadingResetPassword"></b-spinner>
              Bestätigen
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 col-lg-6 col-md-8 col-sm-10 mx-auto form p-4">
        <div class="text-right">
          <b-link to="/login">Zurück zur Anmeldung</b-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { extend } from "vee-validate";
import Vue from "vue";

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Die Passwörter stimmen nicht überein",
});

export default {
  name: "ForgotPassword",
  data() {
    return {
      error: false,
      loading: false,
      loadingRequestPin: false,
      loadingResetPassword: false,
      formRequestPin: {
        emailAddress: "",
      },
      formResetPassword: {
        confirmationCode: "",
        newPassword: "",
        confirmPassword: ""
      },
      showPasswords: false,
      isPinRequested: false,
      showPinRequestSuccess: false,
      showPinRequestError: false,
      showPasswordChangeSuccess: false,
      showPasswordChangeError: false,
    };
  },
  created() {
    this.hideAlerts();
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    requestPin()  {
      console.log(this.formRequestPin.emailAddress)
      this.$refs.formRequestPin.validate().then((success) => {
        if (!success) {
          return;
        }

        this.hideAlerts();
        this.loadingRequestPin = true;

        var user = {
          userName: this.formRequestPin.emailAddress,
        };

        this.$http
            .post(Vue.prototype.$authUrl+"auth/forgotPassword",user)
            .then(response => {
              this.loadingRequestPin = false;
              this.showPinRequestSuccess = true;
              this.isPinRequested = true;
              console.log("PIN requested")
              console.log(response)
            }, error => {
              this.loadingRequestPin = false;
              this.showPinRequestError = true;
              console.log(error)
            })
      });
    },
    resetPassword()  {
      console.log(this.formResetPassword.emailAddress)
      this.$refs.formResetPassword.validate().then((success) => {
        if (!success) {
          return;
        }

        this.hideAlerts();
        this.loadingResetPassword = true;

        var userData = {
          userName: this.formRequestPin.emailAddress,
          confirmationCode: this.formResetPassword.confirmationCode,
          newPassword: this.formResetPassword.newPassword
        };

        this.$http
            .post(Vue.prototype.$authUrl+"auth/forgotPasswordConfirm",userData)
            .then(response => {
              this.loadingResetPassword = false;
              this.showPasswordChangeSuccess = true;
              console.log("Password changed")
              console.log(response)
            }, error => {
              this.loadingResetPassword = false;
              this.showPasswordChangeError = true;
              console.log("Password NOT changed")
              console.log(error)
            })
      });
    },
    hideAlerts() {
      this.showPinRequestSuccess = false;
      this.showPinRequestError = false;
      this.showPasswordChangeError = false;
      this.showPasswordChangeSuccess = false;
    },
  },
};
</script>
