<template>
  <div class="container">
    <div class="float-right">
      <b-button-close @click="$router.replace('/exportrules')">
        <h2 aria-hidden="true">&times;</h2>
      </b-button-close>
    </div>

    <div class="row align-items-center mt-3 mb-2">
      <h1>{{ exportRuleTitle }}</h1>
    </div>
    <div class="row">
      <v-jsoneditor v-model="exportRule" :plus="false" height="500px" @error="onError" :options="{mode: 'code'}"></v-jsoneditor>
    </div>
    <div class="row my-3">
      <div class="col">
        <b-alert
          variant="success"
          :show="alertDismissCountDown"
          fade
          dismissible
          @dismissed="alertDismissCountDown=0; saveButtonDisabled=false"
          @dismiss-count-down="countDownChanged"
        >Die ExportRule-Einstellungen wurden erfolgreich gespeichert.</b-alert>
        <b-alert :show="!allowEdit">Globale Exportregeln können nicht bearbeitet werden</b-alert>
      </div>
    </div>
    <div class="row my-3">
      <b-button variant="primary" @click="save()" :disabled="saveButtonDisabled || !allowEdit">Speichern</b-button>
    </div>
  </div>
</template>

<script>
import VJsoneditor from "v-jsoneditor";

export default {
  data() {
    return {
      exportRuleId: null,
      exportRuleTitle: '',
      exportRule: {},
      alertDismissCountDown: 0,
      saveButtonDisabled: false,
      allowEdit: false
    };
  },
  components: {
    VJsoneditor
  },
  created() {
    this.exportRuleId = this.$route.params.exportRuleId;
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http
        .get("form/export_rule/update/" + this.exportRuleId, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/form/export_rule/update/" + this.exportRuleId
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          this.exportRule = data.initialValues;
          this.exportRuleTitle = data.exportRuleTitle;
          this.allowEdit = data.allowEdit;
        });
    },
    onError() {
      console.log("error");
    },
    save() {
      this.saveButtonDisabled = true;
      this.$http
        .put("export_rule/" + this.exportRuleTitle, this.exportRule, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-PUT-/addressflow/v1/export_rule/" + this.exportRuleTitle
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success === true) {
            this.alertDismissCountDown = 2;
          }
        });
    },
    countDownChanged(dismissCountDown) {
      this.alertDismissCountDown = dismissCountDown;
    }
  }
};
</script>

