<template>
  <div>
    <b-modal
      id="preview_export_modal"
      :title="modalTitle"
      hide-footer
      @show="triggerPreviewExport"
      @hidden="closeMe()"
    >
      <p class="text-center" v-show="showSpinner">
        <b-spinner />
      </p>

      <p v-if="fileContent">

        <b-form-group label="Download Optionen">
          <b-form-radio v-model="fileType" name="file-type" :value="defaultFileType">Formatlos herunterladen</b-form-radio>
          <b-form-radio
            v-model="fileType"
            name="file-type"
            value="text/csv"
          >Als CSV herunterladen<span v-if="typeFromConfig === 'csv'"> (Standard)</span></b-form-radio>
          <b-form-radio
            v-model="fileType"
            name="file-type"
            value="text/xml"
          >Als XML herunterladen<span v-if="typeFromConfig === 'xml'"> (Standard)</span></b-form-radio>
        </b-form-group>

        <b-button variant="primary" @click.prevent="download">Datei herunterladen</b-button>
      </p>
    </b-modal>
  </div>
</template>

<script>
import FileSaver from "file-saver";

window.TextEncoder = window.TextDecoder = null;

export default {
  components: {
    FileSaver,
    TextEncoder
  },
  props: {
    showMe: false,
    poolKey: null
  },
  data() {
    return {
      modalTitle: "Vorschau-Export wird durchgeführt...",
      showSpinner: true,
      maxPreviewExportRetries: 50,
      previewExportRetries: 0,
      typeFromConfig: "",
      defaultFileType: "",
      fileType: "",
      fileContent: "",
      charSet: "utf-8"
    };
  },
  watch: {
    showMe(val) {
      if (val) {
        this.$bvModal.show("preview_export_modal");
      }
    }
  },
  methods: {
    closeMe() {
      this.modalTitle = "Vorschau-Export wird durchgeführt...";
      this.showSpinner = true;
      this.fileContent = "";
      this.$emit("close");
    },
    triggerPreviewExport() {
      this.$http
        .get("export/preview/" + this.poolKey, {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/export/preview/" + this.poolKey
            )
          }
        })
        .then(response => {
          return response.json();
        })
        .then(data => {
          if (data.success) {
            this.typeFromConfig = data.fileExportOptions.format;
            if (this.typeFromConfig === 'csv') {
              this.fileType = 'text/csv';
            }
            this.recGetPreviewExport();
          } else {
              this.modalTitle = 'Keine Daten zum Exportieren vorhanden.'
              this.showSpinner = false;
              this.fileContent = "";
          }
        });
    },
    recGetPreviewExport() {
      this.$http
        .get("export/preview/" + this.poolKey + "/file", {
          headers: {
            Authorization: this.createHmacHash(
              this.$store.state.user.adfAdminUser,
              "-GET-/addressflow/v1/export/preview/" + this.poolKey + "/file"
            )
          }
        })
        .then(response => {
          this.previewExportRetries++;
          if (
            response.status === 204 &&
            this.previewExportRetries < this.maxPreviewExportRetries
          ) {
            this.recGetPreviewExport();
          } else {
            this.modalTitle = "Datei herunterladen";
            this.showSpinner = false;
            this.previewExportRetries = 0;
            this.fileContent = response.body;
            this.defaultFileType = response.headers.get("content-type");
            this.charSet = this.defaultFileType.split(';charset=')[1];
            if (!this.fileType) {
              this.fileType = this.defaultFileType;
            }
          }
        });
    },
    download() {
      let string = this.fileContent.replace(/(\\r)?\\n/g, "\r\n");
      string = string.replace(/\\t/g, "\t");
      if (this.charSet !== 'utf-8') {
        string = new TextEncoder(this.charSet, { NONSTANDARD_allowLegacyEncoding: true }).encode(string);
      }

      let blob = new Blob([string], { type: this.fileType });
      let date = new Date();
      let dateString =
        date.getFullYear().toString() +
        date.getMonth().toString() +
        date.getDate().toString() +
        date.getHours().toString() +
        date.getMinutes().toString() +
        date.getSeconds().toString();

      let fileExtension = '';
      switch (this.fileType) {
        case 'text/csv':
          fileExtension = '.csv';
          break;
        case 'text/xml':
          fileExtension = '.xml';
          break;
      }
      FileSaver.saveAs(blob, "PREVIEW_" + this.poolKey + "_" + dateString + fileExtension);
    }
  }
};
</script>
