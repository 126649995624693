<template>
    <div class="container">
        <div class="float-right">
            <b-button-close @click="$router.replace('/uiusers')">
                <h2 aria-hidden="true">&times;</h2>
            </b-button-close>
        </div>

        <br /><br />
        <h1>Benutzer bearbeiten</h1>
        <br />

        <validation-observer ref="form" v-slot="{ handleSubmit }">
            <b-form
                @submit.stop.prevent="handleSubmit(onSubmit)"
                class="justify-content-center"
            >
                <validation-provider
                    name="Benutzername"
                    :rules="{ required: true, email: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="userName-group-1"
                        label="Benutzername"
                        label-for="userName"
                    >
                        <b-form-input
                            id="userName"
                            name="userName"
                            placeholder="Benutzername"
                            v-model="form.userName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="user-name-live-feedback"
                            type="email"
                            autofocus
                        ></b-form-input>

                        <b-form-invalid-feedback id="user-name-live-feedback">{{
                                validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="Vorname"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="firstName-group-1"
                        label="Vorname"
                        label-for="firstName"
                    >
                        <b-form-input
                            id="firstName"
                            name="firstName"
                            placeholder="Vorname"
                            v-model="form.firstName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="first-name-live-feedback"
                            type="text"
                        ></b-form-input>

                        <b-form-invalid-feedback id="first-name-live-feedback">{{
                                validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="Nachname"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group
                        id="lastName-group-1"
                        label="Nachname"
                        label-for="lastName"
                    >
                        <b-form-input
                            id="lastName"
                            name="lastName"
                            placeholder="Nachname"
                            v-model="form.lastName"
                            :state="getValidationState(validationContext)"
                            aria-describedby="last-name-live-feedback"
                            type="text"
                        ></b-form-input>

                        <b-form-invalid-feedback id="last-name-live-feedback">{{
                                validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <validation-provider
                    name="Rolle"
                    :rules="{ required: true }"
                    v-slot="validationContext"
                >
                    <b-form-group id="role-group-1" label="Rolle" label-for="role">
                        <b-form-select
                            id="role"
                            v-model="form.role"
                            :options="uiRoles"
                            :state="getValidationState(validationContext)"
                            aria-describedby="role-live-feedback"
                        ></b-form-select>

                        <b-form-invalid-feedback id="role-live-feedback">{{
                                validationContext.errors[0]
                            }}</b-form-invalid-feedback>
                    </b-form-group>
                </validation-provider>

                <div class="my-3">
                    <b-button
                        variant="primary"
                        :disabled="saveButtonDisabled"
                        type="submit"
                    >
                        <b-spinner small v-if="saveButtonDisabled"></b-spinner>
                        Speichern
                    </b-button>
                    <b-button variant="light" @click="$router.replace('/uiusers')">Abbrechen</b-button>
                </div>

                <div class="my-3">
                    <div class="col">
                        <b-alert
                            variant="success"
                            :show="alertDismissCountDown"
                            fade
                            dismissible
                            @dismissed="
                alertDismissCountDown = 0;
                saveButtonDisabled = false;
              "
                            @dismiss-count-down="countDownChanged"
                        >Der Benutzer wurde erfolgreich gespeichert.</b-alert
                        >
                    </div>
                </div>
            </b-form>
        </validation-observer>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                userName: "",
                firstName: "",
                lastName: "",
                role: "",
                adfAdminUser: "",
                adfAdminUserAuthToken: "",
            },
            uiRoles: [],
            saveButtonDisabled: false,
            alertDismissCountDown: 0,
        };
    },
    created() {
        this.form.userName = this.$route.params.userName;
        this.fetchData();
        this.fetchUiRoles();
    },
    methods: {
        fetchData() {
            this.$http
                .get(this.$authUrl + "user/" + this.form.userName, {
                    headers: {
                        Authorization: "Bearer " + this.$store.state.bearerToken.value,
                    },
                })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    this.form = data;
                });
        },
        fetchUiRoles() {
            // like this it is backwards compatible with RuV and Dev - there we just have one SYS-Cognito-Pool
            // and no separation between system and tenant user and therefore only user with system roles
            // for RSD and all other following customers it is like this:
            // SYSTEM-Pool-User only have SystemRoles
            // TENANT-Pool-User only have TenantRoles
            if ((this.$store.getters.userRole === 'AdfTenantAdmin') || (this.$store.getters.userRole === 'AdfTenantUser')) {
                this.uiRoles = [
                    { text: 'Bitte wählen...', value: '' },
                    { text: 'AdfTenantAdmin', value: 'AdfTenantAdmin' },
                    { text: 'AdfTenantUser', value: 'AdfTenantUser' },
                ];
            } else {
                this.uiRoles = [
                    { text: 'Bitte wählen...', value: '' },
                    { text: 'AdfSystemAdmin', value: 'AdfSystemAdmin' },
                    { text: 'AdfSystemUser', value: 'AdfSystemUser' },
                    { text: 'AdfSystemUserImport', value: 'AdfSystemUserImport' },
                ];
            }
        },
        getValidationState({ dirty, validated, valid = null }) {
            return dirty || validated ? valid : null;
        },
        onSubmit() {
            this.$refs.form.validate().then((success) => {
                if (!success) {
                    return;
                }

                this.saveButtonDisabled = true;
                var user = {
                    userName: this.form.userName,
                    firstName: this.form.firstName,
                    lastName: this.form.lastName,
                    role: this.form.role,
                    adfAdminUser: this.form.adfAdminUser,
                    adfAdminUserAuthToken: this.form.adfAdminUserAuthToken,
                };

                this.$http
                    .put(this.$authUrl + "user", user, {
                        headers: {
                            Authorization: "Bearer " + this.$store.state.bearerToken.value,
                        },
                    })
                    .then((resp) => {
                        this.saveButtonDisabled = false;
                        this.$router.push("/uiusers");
                    })
                    .catch((err) => {
                        this.saveButtonDisabled = false;
                        console.log("Error updating user: " + err.message);
                    });
            });
        },
        countDownChanged(dismissCountDown) {
            this.alertDismissCountDown = dismissCountDown;
        },
    },
};
</script>
